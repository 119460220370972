import {
  DETAIL,
  RECOMMENDED,
  LOADING,
  ADDITIONALINFO
} from "../actions/search";

export default function detail(state = {}, action) {
  const setState = obj => ({ ...state, ...obj });

  switch (action.type) {
    case DETAIL:
      return setState({
        loading: false,
        video: action.data
      });
    case LOADING:
      return setState({
        loading: true
      });
    case RECOMMENDED:
      return setState({
        recommended: action.data
      });
    case ADDITIONALINFO:
      return setState({
        additionalInfo: action.data
      });
    default:
      return state;
  }
}
