import { createAction, handleActions } from "redux-actions";

const setPlaylistView = createAction("PAGES/PLAYLIST/PLAYLIST_VIEW");

const defaultState = "grid";

const reducer = handleActions(
  {
    [setPlaylistView]: (state, action) => action.payload
  },
  defaultState
);

export { setPlaylistView, reducer };
