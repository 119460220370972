import Client from "shopify-buy";
import request from "superagent";
import { push } from "connected-react-router";

import { SET_SNACKBAR_STATUS } from "../actions/playlist";
import { resetCart } from "./user";
import selectRMuser from "../reselect/cart/selectRMuser";

const client = Client.buildClient({
  domain: process.env.REACT_APP_SHOPIFY_DOMAIN,
  storefrontAccessToken: process.env.REACT_APP_SHOPIFY_ACCESS_TOKEN,
});
export const actions = {
  SHOPIFY_ADD_TO_CART: "JMLP/SHOPIFY_ADD_TO_CART",
  SHOPIFY_REMOVE_FROM_CART: "JMLP/SHOPIFY_REMOVE_FROM_CART",
  SHOPIFY_PRODUCTS_FETCHED: "JMLP/SHOPIFY_PRODUCTS_FETCHED",
  SHOPIFY_PRODUCTS_FETCH_FAILED: "JMLP/SHOPIFY_PRODUCTS_FETCH_FAILED",
  SHOPIFY_CHECKOUT_IN_PROGRESS: "JMLP/SHOPIFY_CHECKOUT_IN_PROGRESS",
  SHOPIFY_CHECKOUT_COMPLETED: "JMLP/SHOPIFY_CHECKOUT_COMPLETED",
  SHOPIFY_SET_LOADING: "JMLP/SHOPIFY_SET_LOADING",
  SHOPIFY_SET_FETCHING_PLAYLIST_INFO: "JMLP/SHOPIFY_SET_FETCHING_PLAYLIST_INFO",
  SHOPIFY_SET_PLAYLIST_INFO: "JMLP/SHOPIFY_SET_PLAYLIST_INFO",
  SHOPIFY_RESET_SESSION: "JMLP/SHOPIFY_RESET_SESSION",
};

// This action should bootstrap with the application
export function getProducts() {
  return (dispatch) => {
    return client.product
      .fetchAll()
      .then((resp) => {
        dispatch({
          type: actions.SHOPIFY_PRODUCTS_FETCHED,
          data: resp
        });
      })
      .catch((err) => {
        dispatch({ type: actions.SHOPIFY_PRODUCTS_FETCH_FAILED, data: true });
      });
  };
}

export function addToCartShopify(product) {
  return {
    type: actions.SHOPIFY_ADD_TO_CART,
    data: product
  };
}

export function removeFromCartShopify(product) {
  return {
    type: actions.SHOPIFY_REMOVE_FROM_CART,
    data: product
  };
}

// Ideally, this will forward the user to the checkout, or pop up a new window
export function checkout(emailAddress, items) {
  let shopifyItems = items.map((item) => {
    let obj = {};
    if (item.type === "Gift Card") {
      obj = {
        variantId: item.variantId,
        quantity: item.quantity
      };
    } else {
      obj = {
        variantId: item.variantId,
        quantity: item.quantity,
        customAttributes: [
          {
            key: "ID",
            value: item.id
          },
          {
            key: "Title",
            value: item.title
          },
          {
            key: "Content Usage",
            value:
              item.contentUsage === undefined
                ? "Not Specified"
                : item.contentUsage,
          },
        ],
      };
    }
    return obj;
  });

  return (dispatch) => {
    return client.checkout
      .create({
        email: emailAddress,
        lineItems: shopifyItems
      })
      .then((checkout) => {
        dispatch({
          type: actions.SHOPIFY_CHECKOUT_IN_PROGRESS,
          data: checkout
        });
        localStorage.setItem("shopifyCheckoutId", checkout.id);
        let a = document.createElement("a");
        a.appendChild(document.createTextNode("shopify referrer"));
        a.title = "shopify referrer";
        a.href = checkout.webUrl;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
      });
  };
}

export function fetchingCheckout(checkoutId) {
  return (dispatch, getState) => {
    const isRMuser = selectRMuser(getState().auth);
    return client.checkout.fetch(checkoutId).then((checkout) => {
      if (checkout && checkout.completedAt !== null && !isRMuser) {
        localStorage.removeItem("shopifyCheckoutId");
        dispatch({
          type: actions.SHOPIFY_CHECKOUT_COMPLETED
        });
      } else {
        if (checkout && checkout.completedAt && isRMuser) {
          const { cartItems } = getState().user;
          const playlistUUID = checkout.customAttributes.find((item) => item.key === "playlistUUID");
          const resetCartItems =
            cartItems.length > 0
              ? cartItems.filter(
                  (item) => item.playlistUUID !== playlistUUID.value
                )
              : [];
          dispatch(resetCart(resetCartItems));
        }
        dispatch({
          type: actions.SHOPIFY_CHECKOUT_IN_PROGRESS,
          data: checkout
        });
        dispatch({
          type: actions.SHOPIFY_SET_FETCHING_PLAYLIST_INFO,
          payload: false
        });
      }
    });
  };
}

export function RMCheckout(form, items, playlistUUID) {
  let shopifyItems = items.map((item) => {
    var obj = {
      variantId: item.variantId,
      quantity: item.quantity,
      customAttributes: [
        { key: "ID", value: item.id },
        { key: "Title", value: item.title },
        {
          key: "Content Usage",
          value:
            item.contentUsage === undefined
              ? "Not Specified"
              : item.contentUsage,
        },
      ],
    };
    return obj;
  });

  return (dispatch, getState) => {
    dispatch({ type: actions.SHOPIFY_SET_LOADING, payload: true });

    const { shopify } = getState();
    const penaltyProduct = shopify.products.find(
      (item) => item.productType.toUpperCase() === "RM OTHER"
    );

    const customAttributes = [
      { key: "firstName", value: form.firstName },
      { key: "lastName", value: form.lastName },
      { key: "playlistUUID", value: playlistUUID }
    ];

    items.forEach((item) => {
      customAttributes.push({
        key: item.id,
        value: `${item.imgUrl}#-#${item.id}#-#${item.title}`
      });
    });

    if (form.penalty && penaltyProduct) {
      shopifyItems.push({
        variantId: penaltyProduct.variants[0].id,
        quantity: items.length,
        customAttributes: [
          {
            key: "Title",
            value: "Rights Management Fee"
          }
        ]
      });
    }
    return client.checkout
      .create({
        email: form.email,
        lineItems: shopifyItems,
        customAttributes,
        note: "Settlement"
      })
      .then(async (checkout) => {
        dispatch({ type: actions.SHOPIFY_CHECKOUT_IN_PROGRESS, data: checkout });

        const split_uri = checkout.webUrl.split("/");
        const getSecondKey = split_uri[5].split("?");
        const shopifyURL = `id=${split_uri[3]}&reference=${getSecondKey[0]}&${getSecondKey[1]}&subtotal=${checkout.subtotalPrice.amount}`;

        const shortenUrl = await RMShortenLink(shopifyURL, dispatch);
        await dispatch(updateRMPlaylist(playlistUUID, shortenUrl.link, checkout.id));

        const newPlaylistInfo = Object.assign({}, getState().shopify.playlistInfo, {
          cartUrl: shortenUrl.link,
          checkoutId: checkout.id
        });
        const newCartItem = getState().user.cartItems.filter(
          (cartItem) => cartItem.playlistUUID !== playlistUUID
        );

        dispatch({
          type: actions.SHOPIFY_SET_PLAYLIST_INFO,
          payload: newPlaylistInfo
        });
        dispatch(resetCart(newCartItem));

        return shortenUrl;
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actions.SHOPIFY_SET_LOADING, payload: false });
        return null;
      });
  };
}

export function RMShortenLink(link, dispatch) {
  return request
    .post(`${process.env.REACT_APP_SHORTENER_URL}/link`)
    .set("Content-Type", "application/json")
    .set("x-api-key", process.env.REACT_APP_SHORTENER_X_API_KEY)
    .send({
      target: `${process.env.REACT_APP_FUNK_URL}/licensing/checkout/terms?${link}`
    })
    .then((response, error) => (response.ok ? response.body.response : null))
    .catch((error) => {
      console.log(error);
      dispatch({ type: actions.SHOPIFY_SET_LOADING, payload: false });
      return null;
    });
}

export function getPlaylistInfo(uuid) {
  return (dispatch, getState) => {
    const { access_token } = getState().auth.user;
    dispatch({
      type: actions.SHOPIFY_SET_FETCHING_PLAYLIST_INFO,
      payload: true
    });
    return request
      .get(`/api/user/licensing/lists/${uuid}`)
      .set("Authorization", `Bearer ${access_token}`)
      .then((response) => {
        dispatch({
          type: actions.SHOPIFY_SET_PLAYLIST_INFO,
          payload: response.ok ? response.body : null
        });

        if (response.ok && !response.body.canModify) {
          const cleanCartItem = getState().user.cartItems.filter(
            (item) => item.playlistUUID && item.playlistUUID !== uuid
          );
          dispatch(resetCart(cleanCartItem));
          dispatch(push(`/licensing/account/lists/${uuid}`));
          return false;
        } else {
          if (response.ok && response.body.checkoutId) {
            dispatch(fetchingCheckout(response.body.checkoutId));
          } else {
            dispatch({
              type: actions.SHOPIFY_SET_FETCHING_PLAYLIST_INFO,
              payload: false
            });
            dispatch({ type: actions.SHOPIFY_RESET_SESSION });
          }
        }
        return response.ok ? true : false;
      })
      .catch((error) => {
        if (error.response && error.response.body) {
          error.response.body.messages.forEach((message) => {
            if (message.includes(`doesn't exists`)) {
              dispatch({
                type: SET_SNACKBAR_STATUS,
                payload: {
                  open: true,
                  message:
                    "The Playlist associated in this cart no longer exist",
                },
              });

              setTimeout(() => {
                dispatch({
                  type: SET_SNACKBAR_STATUS,
                  payload: { open: false, message: "" }
                });
                dispatch(push(`/licensing/account/lists`));
              }, 1500);
            }
          });
        }
        dispatch({
          type: actions.SHOPIFY_SET_FETCHING_PLAYLIST_INFO,
          payload: false
        });
        dispatch({ type: actions.SHOPIFY_SET_PLAYLIST_INFO, payload: null });

        return false;
      });
  };
}

export function copyClipboardNotification() {
  return (dispatch) => {
    dispatch({
      type: SET_SNACKBAR_STATUS,
      payload: {
        open: true,
        message: "Link copied to the clipboard!"
      }
    });
  };
}

export function updateRMPlaylist(uuid, cartUrl, checkoutID) {
  return (dispatch, getState) => {
    const { access_token } = getState().auth.user;
    request
      .patch(`/api/user/licensing/lists/${uuid}`)
      .set("Authorization", `Bearer ${access_token}`)
      .set("Content-Type", "application/json")
      .send({
        cartUrl: cartUrl,
        checkoutId: checkoutID
      })
      .then((response, error) => {
        dispatch({ type: actions.SHOPIFY_SET_LOADING, payload: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function unmountCart() {
  return (dispatch) => {
    dispatch({ type: actions.SHOPIFY_SET_PLAYLIST_INFO, payload: null });
    dispatch({ type: actions.SHOPIFY_RESET_SESSION });
  };
}
