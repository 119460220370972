import { useState, useEffect } from "react";

export default function useWindow(mobileWidth = 767) {
  const isClient = typeof window === "object";

  const getSize = () => ({
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
    isMobile: isClient ? window.innerWidth <= mobileWidth : false,
  });

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) return false;

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, []);

  return windowSize;
}
