import { createStore, applyMiddleware, combineReducers, compose } from "redux";

import { persistStore, persistReducer, createTransform, createMigrate } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import { createBrowserHistory, createMemoryHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";

import defaultReducer from "./reducers";

export const isServer = !(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

const migrations = {
  3: (state) => {
    return {
      ...state
    };
  }
};

export default (url = "/") => {
  const whiteList = createTransform(
    (inboundState, key) => {
      if (key === "pages") {
        return {
          search: inboundState.search
        };
      } else if (key === "auth" || key === "user" || key === "querySuggestion") {
        return inboundState;
      }
    },
    (outBoundState, key) => {
      return outBoundState;
    }
  );
  const persistConfig = {
    key: "root",
    version: 3,
    storage,
    transforms: [whiteList],
    migrate: createMigrate(migrations, { debug: false })
  };

  const history = isServer
    ? createMemoryHistory({
        initialEntries: [url]
      })
    : createBrowserHistory();
  const reducerMap = {
    router: connectRouter(history),
    ...defaultReducer
  };

  const enhancers = [applyMiddleware(thunkMiddleware), applyMiddleware(routerMiddleware(history))];

  const rootReducer = combineReducers(reducerMap);
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const enhancer = composeWithDevTools(...enhancers);
  const store = createStore(persistedReducer, enhancer);

  const persistor = persistStore(store);

  return { store, persistor, history };
};
