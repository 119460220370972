import styled from "styled-components";

const VideoListActionsContainer = styled.div`
  .video-list-action-icon {
    color: #a6a6a6;
    padding: 4px;
    &:hover {
      color: #000000;
    }
  }
  .video-list-actions {
    position: absolute;
    right: 10px;
    display: none;
    top: 7px;
  }
`;

export default VideoListActionsContainer;
