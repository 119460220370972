import styled from "styled-components";

const ArrowPopup = styled.div`
  .popup-container {
    position: relative;
    display: inline-block;
    z-index: 1000;
    width: 100%;
  }
  .popup-arrow {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 3px;
    background-color: #fff;
    -webkit-transform: translate(-50%) rotate(45deg);
    -ms-transform: translate(-50%) rotate(45deg);
    transform: translate(-50%) rotate(45deg);
    border-left: 1px solid #c4c4c4;
    border-top: 1px solid #c4c4c4;
  }
  .popup-border {
    position: absolute;
    background-color: #fff;
    border: 1px solid #c4c4c4;
    top: 10px;
    bottom: 0;
    width: 100%;
  }
  .popup-content {
    margin: 2px 2px 11px 2px;
    position: relative;
    background-color: #fff;
    top: 10px;
  }

  .popup-arrow.middle {
    left: 50%;
  }
  .popup-arrow.right {
    right: 10px;
  }
  .popup-arrow.right-5 {
    right: 5px;
  }
  .popup-arrow.right-30 {
    right: 30px;
  }
  .popup-arrow.right-83 {
    right: 83px;
  }
  .popup-arrow.right-48 {
    right: 48px;
  }
  .popup-arrow.left {
    left: 30px;
  }
  .popup-arrow.left-55 {
    left: 55px;
  }
  .popup-arrow.right-18 {
    right: 18px;
  }
  .popup-arrow.background-grey {
    background-color: #ededed;
  }
  .popup-border.background-grey {
    background-color: #ededed;
  }
  .popup-content.background-grey {
    background-color: #ededed;
  }
  .popup-arrow.border-grey {
    border-left: 1px solid #d5d5d5;
    border-top: 1px solid #d5d5d5;
  }
  .popup-border.border-grey {
    border: 1px solid #d5d5d5;
  }
  .popup-arrow.border-light-grey {
    border-left: 1px solid #bcbcbc;
    border-top: 1px solid #bcbcbc;
  }
  .popup-border.border-light-grey {
    border: 1px solid #bcbcbc;
  }
`;

export default ArrowPopup;
