import { combineReducers } from "redux";

import { reducer as activeTab } from "./state/activeTab";
import { reducer as playlistView } from "./state/playlistView";
import { reducer as pagination } from "./state/pagination";
import { reducer as order } from "./state/order";
import { reducer as dialogs } from "./state/dialogs";
import { reducer as watchlist } from "./state/watchlist";

export default combineReducers({
  activeTab,
  playlist: combineReducers({
    playlistView,
    pagination,
    order,
    dialogs
  }),
  watchlist
});
