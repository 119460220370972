export const LOAD_PLAYLIST = "PLAYLIST/LOAD_PLAYLIST";
export const RESET_PLAYLIST = "PLAYLIST/RESET_PLAYLIST";
export const PREPEND_PLAYLIST = "PLAYLIST/PREPEND_PLAYLIST";
export const APPEND_PLAYLIST = "PLAYLIST/APPEND_PLAYLIST";
export const REMOVE_PLAYLIST = "PLAYLIST/REMOVE_PLAYLIST";

export const SET_FETCHING = "PLAYLIST/SET_FETCHING";
export const SET_OFFSET = "PLAYLIST/SET_OFFSET";
export const SET_SELECTED_PLAYLIST = "PLAYLIST/SET_SELECTED_PLAYLIST";
export const SET_SNACKBAR_STATUS = "PLAYLIST/SET_SNACKBAR_STATUS";
export const SET_SHARING_LINK = "PLAYLIST/SET_SHARING_LINK";
export const SET_SHOW_CREATE_MODAL = "PLAYLIST/SET_SHOW_CREATE_MODAL";
export const SET_CREATE_MODAL_TYPE = "PLAYLIST/SET_CREATE_MODAL_TYPE";
export const SET_FORM_SUBMITTING = "PLAYLIST/SET_FORM_SUBMITTING";
export const SET_FORM_ERROR = "PLAYLIST/SET_FORM_ERROR";
export const SET_NO_MORE = "PLAYLIST/SET_NO_MORE";
export const SET_TOTAL_PLAYLIST = "PLAYLIST/SET_TOTAL_PLAYLIST";
export const SET_COMPONENT_UPDATED = "PLAYLIST/SET_COMPONENT_UPDATED";
export const SET_ENABLE_EDIT = "PLAYLIST/SET_ENABLE_EDIT";

export const SET_SELECTED_PLAYLIST_INFO = "PLAYLIST/SET_SELECTED_PLAYLIST_INFO";
export const SET_PLAYLIST_VIDEOS_OFFSET = "PLAYLIST/SET_PLAYLIST_VIDEOS_OFFSET";
export const LOAD_PLAYLIST_VIDEOS = "PLAYLIST/LOAD_PLAYLIST_VIDEOS";
export const REMOVE_FROM_PLAYLIST = "PLAYLIST/REMOVE_FROM_PLAYLIST";
export const SET_TOTAL_PLAYLIST_VIDEOS = "PLAYLIST/SET_TOTAL_PLAYLIST_VIDEOS";
export const SET_PLAYLIST_VIDEOS_FETCHING = "PLAYLIST/SET_PLAYLIST_VIDEOS_FETCHING";
export const SET_PLAYLIST_VIDEOS_NO_MORE = "PLAYLIST/SET_PLAYLIST_VIDEOS_NO_MORE";
export const RESET_PLAYLIST_VIDEOS = "PLAYLIST/RESET_PLAYLIST_VIDEOS";
export const SET_SHOW_DELETE_MODAL = "PLAYLIST/SET_SHOW_DELETE_MODAL";
export const SET_SHOW_ADD_TO_CART = "PLAYLIST/SET_SHOW_ADD_TO_CART";
export const SET_SHOW_ADD_TO_CART_MODAL = "PLAYLIST/SET_SHOW_ADD_TO_CART_MODAL";

export const LOAD_FOLDER = "PLAYLIST/LOAD_FOLDER";
export const LOAD_FOLDER_INFO = "PLAYLIST/LOAD_FOLDER_INFO";
export const SET_FOLDER_SHOW = "PLAYLIST/SET_FOLDER_SHOW";
export const PREPEND_FOLDER = "PLAYLIST/PREPEND_FOLDER";
export const SET_SHOW_DELETE_FOLDER_MODAL = "PLAYLIST/SET_SHOW_DELETE_FOLDER_MODAL";
export const SET_ENABLE_EDIT_DESCRIPTION = "PLAYLIST/SET_ENABLE_EDIT_DESCRIPTION";
export const LOAD_FOLDER_VIDEOS = "PLAYLIST/LOAD_FOLDER_VIDEOS";
export const SET_TOTAL_FOLDER_VIDEOS = "PLAYLIST/SET_TOTAL_FOLDER_VIDEOS";
export const APPEND_FOLDER_VIDEOS = "PLAYLIST/APPEND_FOLDER_VIDEOS";
export const SET_FOLDER_VIDEOS_NO_MORE = "PLAYLIST/SET_FOLDER_VIDEOS_NO_MORE";
export const SET_FOLDER_VIDEOS_OFFSET = "PLAYLIST/SET_FOLDER_VIDEOS_OFFSET";
export const SET_FOLDER_VIDEOS_FETCHING = "PLAYLIST/SET_FOLDER_VIDEOS_FETCHING";

export const LOAD_STARRED = "PLAYLIST/LOAD_STARRED";
export const SET_STARRED_VIDEOS_OFFSET = "PLAYLIST/SET_STARRED_VIDEOS_OFFSET";
export const SET_TOTAL_STARRED_VIDEOS = "PLAYLIST/SET_TOTAL_STARRED_VIDEOS";
export const SET_STARRED_VIDEOS_FETCHING = "PLAYLIST/SET_STARRED_VIDEOS_FETCHING";
export const APPEND_STARRED_VIDEOS = "PLAYLIST/APPEND_STARRED_VIDEOS";
export const PREPEND_STARRED_VIDEOS = "PLAYLIST/PREPEND_STARRED_VIDEOS";
export const SET_STARRED_VIDEOS_NO_MORE = "PLAYLIST/SET_STARRED_VIDEOS_NO_MORE";
export const SET_PLAYLIST_DROPPING = "PLAYLIST/SET_PLAYLIST_DROPPING";

export const SET_SEARCH_TERM = "PLAYLIST/SET_SEARCH_TERM";
export const SET_PLAYLIST_SORTING = "PLAYLIST/SET_PLAYLIST_SORTING";
export const SET_PLAYLIST_VIDEOS_SORTING = "PLAYLIST/SET_PLAYLIST_VIDEOS_SORTING";
export const APPEND_PLAYLIST_VIDEOS = "PLAYLIST/APPEND_PLAYLIST_VIDEOS";

export const LAST_ITEM_REPLACE_FROM_DROP = "PLAYLIST/LAST_ITEM_REPLACE_FROM_DROP";
export const SET_VIDEOS_MOVING = "PLAYLIST/SET_VIDEOS_MOVING";
export const SET_SORT_APPLIED = "PLAYLIST/SET_SORT_APPLIED";
export const SET_FOLDER_VIDEOS_SORTING = "PLAYLIST/SET_FOLDER_VIDEOS_SORTING";
export const STARRED_INPROGRESS = "PLAYLIST/STARRED_INPROGRESS";

export const SET_SHOW_COPY_PLAYLIST_MODAL = "PLAYLIST/SET_SHOW_COPY_PLAYLIST_MODAL";

export const SET_PLAYLISTS_OFFSET = "PLAYLIST/SET_PLAYLISTS_OFFSET";
export const SET_PLAYLISTS_MAX = "PLAYLIST/SET_PLAYLISTS_MAX";

export const REQUEST_DOWNLOAD_ALL_VIDEOS_FETCHING = "PLAYLIST/REQUEST_DOWNLOAD_ALL_VIDEOS_FETCHING";
export const REQUEST_DOWNLOAD_ALL_VIDEOS_FETCHED = "PLAYLIST/REQUEST_DOWNLOAD_ALL_VIDEOS_FETCHED";
export const REQUEST_DOWNLOAD_ALL_VIDEOS_FULFILLED =
  "PLAYLIST/REQUEST_DOWNLOAD_ALL_VIDEOS_FULFILLED";
export const REQUEST_DOWNLOAD_ALL_VIDEOS_FAILED = "PLAYLIST/REQUEST_DOWNLOAD_ALL_VIDEOS_FAILED";

export const DOWNLOAD_ALL_VIDEOS_PLAYLISTS_FETCHING =
  "PLAYLIST/DOWNLOAD_ALL_VIDEOS_PLAYLISTS_FETCHING";
export const DOWNLOAD_ALL_VIDEOS_PLAYLISTS_FULFILLED =
  "PLAYLIST/DOWNLOAD_ALL_VIDEOS_PLAYLISTS_FULFILLED";
export const DOWNLOAD_ALL_VIDEOS_PLAYLISTS_FAILED = "PLAYLIST/DOWNLOAD_ALL_VIDEOS_PLAYLISTS_FAILED";

export const DOWNLOAD_ALL_VIDEOS_PLAYLISTS_DETAILS_FETCHING =
  "PLAYLIST/DOWNLOAD_ALL_VIDEOS_PLAYLISTS_FETCHING";
export const DOWNLOAD_ALL_VIDEOS_PLAYLISTS_DETAILS_FULFILLED =
  "PLAYLIST/DOWNLOAD_ALL_VIDEOS_PLAYLISTS_DETAILS_FULFILLED";
export const DOWNLOAD_ALL_VIDEOS_PLAYLISTS_DETAILS_FAILED =
  "PLAYLIST/DOWNLOAD_ALL_VIDEOS_PLAYLISTS_DETAILS_FAILED";

export const CANCEL_DOWNLOAD_ALL_VIDEOS_FETCHING = "PLAYLIST/CANCEL_DOWNLOAD_ALL_VIDEOS_FETCHING";
export const CANCEL_DOWNLOAD_ALL_VIDEOS_FULFILLED = "PLAYLIST/CANCEL_DOWNLOAD_ALL_VIDEOS_FULFILLED";
export const CANCEL_DOWNLOAD_ALL_VIDEOS_FAILED = "PLAYLIST/CANCEL_DOWNLOAD_ALL_VIDEOS_FAILED";
