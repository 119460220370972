import { css } from "styled-components";
import checkIcon from "../images/check.png";

export const globalStyles = css`
  body {
    -webkit-font-smoothing: antialiased;
  }

  ul {
    margin: 0;
  }

  input[type="submit"] {
    cursor: pointer;
  }

  button {
    font-family: "Mulish", sans-serif;
    border-radius: 1px !important;
    &.MuiButton-containedPrimary {
      background-color: ${(props) => props.theme.colors.primary.main};
    }
    &.MuiButton-containedSecondary {
      background-color: #37daf0;
      color: #000;
      &:hover {
        background-color: #000;
        color: #fff;
      }
    }
  }

  p.searchError {
    color: #008183 !important;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-top: 5px;
    float: left;
    margin-left: 0;
  }

  .inputbox-error {
    border: 2px solid #008183 !important;
  }

  p.error-message {
    color: #008183 !important;
    font-size: 13px !important;
  }

  footer {
    padding: 15px 60px 30px !important;
    @media screen and (max-width: 767px) {
      padding: 15px 30px !important;
    }
    .copyright {
      top: 0 !important;
      svg {
        top: 35px !important;
      }
      p {
        padding-top: -25px;
      }
    }
    svg {
      &.jss172 {
        top: 18px !important;
      }
    }
    span {
      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
  .lp-tutorial {
    padding: 40px;
    h3 {
      font-weight: 700;
      text-transform: uppercase;
      margin-top: -20px;
      margin-bottom: 0;
      font-size: 20px;
    }
    p {
      font-size: 14px !important;
      word-wrap: normal;
      color: #000 !important;
    }
    a {
      margin-left: 0 !important;
      text-decoration: none !important;
      button {
        color: #fff;
        &:hover {
          color: #fff;
        }
      }
      &:hover {
        color: #fff;
      }
    }
    ul {
      li {
        list-style: square inside
          url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAMAAAC67D+PAAAAPFBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADQLyYwAAAAE3RSTlMAAQQIDQ4aJSYtNWJ1qq3P2uvt02R3rAAAAD1JREFUCB0FwQsCQgAAQLGHUsh3979rW9XrMw9VTQfutRovYK0NcA+dAHMPwLcd4N0COKofuKaqZX/Obaw/CFAFD3QtBc4AAAAASUVORK5CYII=");
        padding-left: 0;
        line-height: 150%;
        font-size: 14px !important;
      }
    }
    .jukin-bt {
      font-size: 14px;
      padding: 0;
      border-radius: 0;
      border: 0;
      min-width: 100px;
      cursor: pointer;
      transition: all 0.6s ease 0s;
      &:hover {
        background: #c22c0c;
      }
    }
  }
  #runonce {
    position: relative;
    margin-bottom: 30px;
    left: 0;
    margin-right: 0;
    cursor: pointer;
  }
  .powertour-activehook.search-box {
    border: 0 !important;
  }
  .connectorarrow-tr {
    position: absolute;
    top: -12px;
    right: 20px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #fff;
    border-top: none;
  }
  .connectorarrow-tl {
    position: absolute;
    top: -12px;
    left: 20px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #fff;
    border-top: none;
  }
  .connectorarrow-rt {
    position: absolute;
    top: 20px;
    right: -12px;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 12px solid #fff;
    border-right: none;
  }
  #search-two {
    p {
      text-align: left;
      margin-top: -30px;
    }
  }

  input[type="checkbox"] {
    border: 1px solid #bababa;
    margin-right: 10px;
    cursor: pointer;
    appearance: none;
    background: #fff;
    border-radius: 0;
    position: relative;
    box-sizing: content-box !important;
    width: 15px;
    height: 15px;
    transition: all 0.3s linear;
    vertical-align: top;
    margin-top: -2px;

    &:checked {
      background: url(${checkIcon}) 0 0 no-repeat;
    }
  }

  .ReactModalPortal {
    z-index: 99999;
    position: relative;
  }
  .packageAdded {
    .modal-content {
      border-radius: 0;
      border: 0;
      width: 335px;
      margin: auto;
      height: 60px;
    }
  }

  .search--datepicker,
  .date__field--picker {
    .MuiToolbar-root {
      background-color: ${(props) => props.theme.colors.primary.main} !important;
    }
    .MuiPickersModal-dialogRoot .MuiPickersDay-day:not(.MuiPickersDay-daySelected):hover {
      color: rgb(207, 59, 48) !important;
      border-radius: 50% !important;
      background-color: rgba(0, 0, 0, 0.08);
    }
    .MuiPickersModal-dialogRoot .MuiPickersDay-daySelected {
      background-color: #008183 !important;
      border-radius: 50% !important;
    }
    .MuiPickersModal-dialogRoot .MuiPickersDay-current:not(.MuiPickersDay-daySelected) {
      color: #008183 !important;
    }
    .MuiPickersModal-dialogRoot .MuiPickersYear-yearSelected,
    .MuiPickersModal-dialogRoot .MuiPickersYear-root:focus,
    .MuiPickersModal-dialogRoot .MuiDialogActions-root button {
      color: #008183 !important;
    }
  }

  .MuiTabs-indicator {
    background-color: #00C3CB;
  }

  .MuiInputLabel-asterisk {
    color: #f50057;
  }
  .snackbar--cart--view {
    color: RGB(207, 59, 48);
    font-size: 14px;
    letter-spacing: 1.9px;
  }
`;
