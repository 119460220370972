import request from "superagent";
import has from "lodash/has";

const mm_fields = [
  "publishingTitle^3",
  "publishingDescription^1.5",
  "tags^1.2",
  "privateTags^1.2",
  "category^1.5",
  "location",
  "title",
  "libraryName",
  "libraryShortName"
];

const boostBySignedDate = false;

async function buildSearchQuery(params) {
  let queryObj = {
    fields: [
      "jmId",
      "title",
      "publishingTitle",
      "publishingDescription",
      "description",
      "originalPublishingDate",
      "signedDate",
      "category",
      "tags",
      "isHD",
      "isVertical",
      "isTrending",
      "duration",
      "location",
      "popularity",
      "brightCoveVideoId",
      "originalSourceUrl",
      "infractionUrls",
      "publishedUrls",
      "claimedYouTubeIds",
      "platformId",
      "isPublicDownloadAvailable",
      "imgUrl",
      "clearanceStatus",
      "iar",
      "il",
      "bo",
      "og",
      "rs",
      "rsa",
      "aci",
      "libraryName",
      "libraryShortName"
    ],
    query: {
      filtered: {
        query: {
          bool: {
            must: [],
            should: [],
            must_not: []
          }
        },
        filter: {}
      }
    }
  };

  let { filtered } = queryObj.query;
  let { query } = filtered;
  let { categories, tone, footageType, clearanceStatus } = params;

  let jmregex = /^\d+(-\d+)?$/;
  let phraseregex = /^['|"].+['|"']$/;
  let urlregex = /^https?:\/\//;
  let browseAll = params.q === null || params.q === "";

  if (browseAll) {
    query = {
      match_all: {}
    };
  } else if (jmregex.exec(params.q)) {
    if (params.q.indexOf("-1") > -1) {
      query = addJmSearchTerm(query, params.q.substring(0, params.q.indexOf("-")));
    } else {
      query = addJmSearchTerm(query, params.q);
    }
  } else if (phraseregex.exec(params.q)) {
    query = addPhraseSearchTerm(query, params.q.replace(/['"]+/g, ""));
  } else if (urlregex.exec(params.q)) {
    query = await addUrlSearchTerm(query, params.q);
  } else {
    query = addTerms(query, params.q);
  }

  if (boostBySignedDate && !browseAll) {
    addSignedDateBooster(query.bool.should);
  }

  if (categories.length > 0) {
    filtered.filter = addCategoriesFilter(filtered.filter, categories);
  }

  if (tone.length > 0) {
    filtered.filter = addTonesFilter(filtered.filter, tone);
  }

  if (footageType.length > 0) {
    filtered.filter = addFootageTypeFilter(filtered.filter, footageType);
  }

  if (clearanceStatus.length > 0) {
    filtered.filter = addClearanceStatusFilter(filtered.filter, clearanceStatus);
  }

  if (params.resolution === "hd") {
    filtered.filter = addHdFilter(filtered.filter);
  }

  if (params.trending) {
    filtered.filter = addTrendingFilter(filtered.filter);
  }

  if (params.il) {
    filtered.filter = addIsLicensedFilter(filtered.filter, params);
  }

  if (params.bo) {
    filtered.filter = addBuyoutFilter(filtered.filter, params);
  }

  if (params.og) {
    filtered.filter = addOnGoingFilter(filtered.filter, params);
  }

  if (params.rs) {
    filtered.filter = addRevSplitFilter(filtered.filter, params);
  }

  if (params.rsa) {
    filtered.filter = addRevSplitAdvanceFilter(filtered.filter, params);
  }

  if (params.iar) {
    filtered.filter = addAdvanceRecoupedFilter(filtered.filter, params);
  }

  if (params.isVertical || params.isHorizontal) {
    filtered.filter = addOrientationFilter(filtered.filter, params);
  }

  if (has(params.more, "signed")) {
    filtered.filter = addSignedRange(filtered.filter, params.more);
  }

  if (has(params.more, "posted")) {
    filtered.filter = addPostedRange(filtered.filter, params.more);
  }

  if (has(params.more, "duration")) {
    filtered.filter = addDurationRange(filtered.filter, params.more);
  }

  queryObj.sort = addSorting(params.sort, params.display);
  queryObj.size = params.perPage ? params.perPage : 20;
  queryObj.from = (params.page - 1) * params.perPage;

  return queryObj;
}

function addJmSearchTerm(query, searchString) {
  if (has(query, "bool")) {
    query.bool.should = [
      {
        multi_match: {
          query: searchString,
          fields: mm_fields,
          type: "phrase"
        }
      },
      {
        match_phrase: {
          jmId: searchString
        }
      },
      {
        match_phrase: {
          publishingTitle: searchString
        }
      }
    ];
  } else {
    query.bool = {
      should: [
        {
          multi_match: {
            query: searchString,
            fields: mm_fields,
            type: "phrase"
          }
        },
        {
          match_phrase: {
            jmId: searchString
          }
        },
        {
          match_phrase: {
            publishingTitle: searchString
          }
        }
      ]
    };
  }

  return query;
}

function addPhraseSearchTerm(query, searchString) {
  if (has(query, "bool")) {
    query.bool.must = [
      {
        multi_match: {
          query: searchString,
          fields: [
            "publishingTitle^3",
            "publishingDescription^1.5",
            "tags^1.2",
            "privateTags^1.2",
            "category^1.5",
            "location",
            "title",
            "libraryName",
            "libraryShortName"
          ],
          type: "phrase"
        }
      }
    ];

    query.bool.should = [
      {
        match_phrase: {
          publishingTitle: searchString
        }
      }
    ];
  } else {
    query.bool = {
      must: [
        {
          multi_match: {
            query: searchString,
            fields: mm_fields,
            type: "phrase"
          }
        }
      ],
      should: [
        {
          match_phrase: {
            publishingTitle: searchString
          }
        }
      ]
    };
  }

  return query;
}

async function addUrlSearchTerm(query, searchString) {
  let youtubeRegex =
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;
  let youtubeMatch = searchString.match(youtubeRegex);
  let youtubeShould = [];

  if (has(query, "bool")) {
    query.bool.should = [];
  } else {
    query.bool = {
      should: []
    };
  }

  if (youtubeMatch) {
    youtubeShould = {
      match: {
        claimedYouTubeIds: youtubeMatch[1]
      }
    };

    query.bool.should.push(youtubeShould);
  }

  try {
    let newBool = await getCanonicalUrl(query, searchString);
    return newBool;
  } catch (err) {
    return query;
  }
}

async function getCanonicalUrl(query, searchString) {
  return new Promise((resolve, reject) => {
    request
      .post(`/api/public/canonicalUrl`)
      .send({
        inurl: searchString
      })
      .then((res, err) => {
        if (!res.ok) {
          reject(err);
        } else {
          query.bool.should.push({
            multi_match: {
              query: res.body.outurl,
              fields: ["infractionUrls", "publishedUrls", "originalSourceUrl"],
              type: "phrase"
            }
          });

          if (res.body.inurl !== res.body.outurl) {
            query.bool.should.push({
              multi_match: {
                query: res.body.inurl,
                fields: ["infractionUrls", "publishedUrls", "originalSourceUrl"],
                type: "phrase"
              }
            });
          }
          resolve(query);
        }
      });
  });
}

function addTerms(query, searchString) {
  if (has(query, "bool")) {
    query.bool.must = [
      {
        multi_match: {
          query: searchString,
          fields: mm_fields,
          type: "best_fields",
          operator: "and"
        }
      }
    ];

    query.bool.should = [
      {
        match_phrase: {
          publishingTitle: searchString
        }
      }
    ];
  } else {
    query.bool = {
      must: [
        {
          multi_match: {
            query: searchString,
            fields: mm_fields,
            type: "best_fields",
            operator: "and"
          }
        }
      ],
      should: [
        {
          match_phrase: {
            publishingTitle: searchString
          }
        }
      ]
    };
  }

  return query;
}

function addCategoriesFilter(filter, categories) {
  let catList = [];

  categories.map((c) => {
    if (c !== "Trending") {
      catList.push(c);
    }

    return catList;
  });

  if (catList.length === 0) {
    return filter;
  }

  if (has(filter, "bool")) {
    filter.bool.must.push({
      terms: {
        "category.raw": catList
      }
    });
  } else {
    filter.bool = {
      must: [
        {
          terms: {
            "category.raw": catList
          }
        }
      ]
    };
  }

  return filter;
}

function addTonesFilter(filter, tones) {
  if (has(filter, "bool")) {
    filter.bool.must.push({
      terms: {
        "tags.raw": tones
      }
    });
  } else {
    filter.bool = {
      must: [
        {
          terms: {
            "tags.raw": tones
          }
        }
      ]
    };
  }

  return filter;
}

function addFootageTypeFilter(filter, footageTypes) {
  if (has(filter, "bool")) {
    filter.bool.must.push({
      terms: {
        "tags.raw": footageTypes
      }
    });
  } else {
    filter.bool = {
      must: [
        {
          terms: {
            "tags.raw": footageTypes
          }
        }
      ]
    };
  }

  return filter;
}

function addClearanceStatusFilter(filter, clearanceStatus) {
  if (has(filter, "bool")) {
    filter.bool.must.push({
      terms: {
        clearanceStatus: clearanceStatus
      }
    });
  } else {
    filter.bool = {
      must: [
        {
          terms: {
            clearanceStatus: clearanceStatus
          }
        }
      ]
    };
  }

  return filter;
}

function addHdFilter(filter) {
  if (has(filter, "bool")) {
    filter.bool.must.push({
      term: {
        isHD: true
      }
    });
  } else {
    filter.bool = {
      must: [
        {
          term: {
            isHD: true
          }
        }
      ]
    };
  }

  return filter;
}

function addTrendingFilter(filter) {
  if (has(filter, "bool")) {
    filter.bool.must.push({
      term: {
        isTrending: true
      }
    });
  } else {
    filter.bool = {
      must: [
        {
          term: {
            isTrending: true
          }
        }
      ]
    };
  }

  return filter;
}

function addBuyoutFilter(filter, params) {
  let bo;
  if (params.bo) {
    bo = true;
  } else {
    bo = false;
  }

  if (has(filter, "bool")) {
    filter.bool.must.push({
      term: {
        bo: bo
      }
    });
  } else {
    filter.bool = {
      must: [
        {
          term: {
            bo: bo
          }
        }
      ]
    };
  }
  return filter;
}

function addIsLicensedFilter(filter, params) {
  let il;
  if (params.il) {
    il = false;
  } else {
    il = true;
  }

  if (has(filter, "bool")) {
    filter.bool.must.push({
      term: {
        il: il
      }
    });
  } else {
    filter.bool = {
      must: [
        {
          term: {
            il: il
          }
        }
      ]
    };
  }
  return filter;
}

function addAdvanceRecoupedFilter(filter, params) {
  let iar;
  if (params.iar) {
    iar = false;
  } else {
    iar = true;
  }

  if (has(filter, "bool")) {
    filter.bool.must.push({
      term: {
        iar: iar
      }
    });
  } else {
    filter.bool = {
      must: [
        {
          term: {
            iar: iar
          }
        }
      ],
      should: [
        {
          term: {
            rsa: true
          }
        }
      ]
    };
  }
  return filter;
}

function addOnGoingFilter(filter, params) {
  let og;
  if (params.og) {
    og = true;
  } else {
    og = false;
  }

  if (has(filter, "bool")) {
    filter.bool.must.push({
      term: {
        og: og
      }
    });
  } else {
    filter.bool = {
      must: [
        {
          term: {
            og: og
          }
        }
      ]
    };
  }
  return filter;
}

function addRevSplitFilter(filter, params) {
  let rs;
  if (params.rs) {
    rs = true;
  } else {
    rs = false;
  }

  if (has(filter, "bool")) {
    filter.bool.must.push({
      term: {
        rs: rs
      }
    });
  } else {
    filter.bool = {
      must: [
        {
          term: {
            rs: rs
          }
        }
      ]
    };
  }
  return filter;
}

function addRevSplitAdvanceFilter(filter, params) {
  let rsa;
  if (params.rsa) {
    rsa = true;
  } else {
    rsa = false;
  }

  if (has(filter, "bool")) {
    filter.bool.must.push({
      term: {
        rsa: rsa
      }
    });
  } else {
    filter.bool = {
      must: [
        {
          term: {
            rsa: rsa
          }
        }
      ]
    };
  }
  return filter;
}

function addSorting(sort, display) {
  return {
    [sort]: {
      order: display ? display : "desc"
    }
  };
}

function addOrientationFilter(filter, params) {
  let vert;

  if (params.isVertical && params.isHorizontal) {
    return filter;
  } else if (params.isVertical && !params.isHorizontal) {
    vert = true;
  } else if (params.isHorizontal && !params.isVertical) {
    vert = false;
  }

  if (has(filter, "bool")) {
    filter.bool.must.push({
      term: {
        isVertical: vert
      }
    });
  } else {
    filter.bool = {
      must: [
        {
          term: {
            isVertical: vert
          }
        }
      ]
    };
  }

  return filter;
}

function addSignedRange(filter, more) {
  let range = {
    signedDate: {}
  };

  let { signed } = more;

  if (signed.from && signed.to) {
    range.signedDate.from = signed.from;
    range.signedDate.to = signed.to;
  } else if (signed.from && !signed.to) {
    range.signedDate.gte = signed.from;
  } else if (signed.to && !signed.from) {
    range.signedDate.lte = signed.to;
  }

  range.signedDate.format = "yyyy-MM-dd";

  if (has(filter, "bool")) {
    filter.bool.must.push({ range });
  } else {
    filter.bool = {
      must: [{ range }]
    };
  }

  return filter;
}

function addPostedRange(filter, more) {
  let range = {
    originalPublishingDate: {}
  };

  let { posted } = more;

  if (posted.from && posted.to) {
    range.originalPublishingDate.from = posted.from;
    range.originalPublishingDate.to = posted.to;
  } else if (posted.from && !posted.to) {
    range.originalPublishingDate.gte = posted.from;
  } else if (posted.to && !posted.from) {
    range.originalPublishingDate.lte = posted.to;
  }

  range.originalPublishingDate.format = "yyyy-MM-dd";

  if (has(filter, "bool")) {
    filter.bool.must.push({ range });
  } else {
    filter.bool = {
      must: [{ range }]
    };
  }

  return filter;
}

function addDurationRange(filter, more) {
  let range = {
    duration: {}
  };

  let { duration } = more;

  range.duration.from = duration.from;
  range.duration.to = duration.to;

  if (has(filter, "bool")) {
    filter.bool.must.push({ range });
  } else {
    filter.bool = {
      must: [{ range }]
    };
  }

  return filter;
}

function addSignedDateBooster(should) {
  should.push({
    range: {
      signedDate: {
        gte: "now-7d",
        boost: 8
      }
    }
  });

  should.push({
    range: {
      signedDate: {
        gte: "now-30d"
      }
    }
  });

  return should;
}

// function findRange(m) {
//   return m.hasOwnProperty("range");
// }

export default buildSearchQuery;
