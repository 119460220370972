export const SET_SNACKBAR = "SYSTEM/SET_SNACKBAR";
export const SET_CLOSE_SNACKBAR = "SYSTEM/SET_CLOSE_SNACKBAR";

export const setSnackbar = (payload) => async (dispatch) => {
  dispatch({
    type: SET_SNACKBAR,
    payload: payload
  })
}

export const closeSnackbar = () => async (dispatch) => dispatch({ type: SET_CLOSE_SNACKBAR })
