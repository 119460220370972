import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ConnectedRouter } from "connected-react-router";

import configureStore from "./redux/store";

const SetupRedux = (props) => {
  const { store, persistor, history } = configureStore();

  const { children } = props;

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>{children}</ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

SetupRedux.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SetupRedux;
