export default function getVideoDetailUrl() {
  let es_url = process.env.REACT_APP_ELASTICSEARCH_HOST;
  let esIndex = process.env.REACT_APP_ELASTICSEARCH_INDEX;
  let esType = process.env.REACT_APP_ELASTICSEARCH_TYPE;

  let jmID = window.location.pathname.split("/")[3];
  let updatedID = jmID;

  if (jmID.split("-")[1] === "1") {
    updatedID = jmID.substring(0, jmID.indexOf("-"));
  } else {
    updatedID = jmID;
  }

  let videoDetailUrl = `${es_url}/${esIndex}/${esType}/${updatedID}`;

  return videoDetailUrl;
}

export function getVideoDetailAlgolia() {
  let jmID = window.location.pathname.split("/")[3];
  let updatedID = jmID;

  if (!jmID.includes("-")) {
    updatedID = `${jmID}-1`;
  }

  return updatedID;
}
