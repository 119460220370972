import styled from "styled-components";
const NewsletterWrapper = styled.div`
  background: #ececec;
  padding: 25px;
  .signup-container {
    text-align: center;
    width: 100%;
    max-width: 900px;
    margin: auto;
    p {
      font-size: 22px;
      line-height: 125%;
    }
    iframe {
      width: 100% !important;
    }
  }
`;

export default NewsletterWrapper;
