import styled from "styled-components";

const CancelDownloadContainer = styled.div`
  .cancel-download-container {
    cursor: default;
  }

  .cancel-download-close-icon {
    cursor: pointer;
  }

  .cancel-download-add-playlist-container {
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
`;

export default CancelDownloadContainer;
