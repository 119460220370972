import {
  LOAD_PLAYLIST,
  SET_FETCHING,
  RESET_PLAYLIST,
  PREPEND_PLAYLIST,
  SET_SELECTED_PLAYLIST,
  REMOVE_PLAYLIST,
  SET_SNACKBAR_STATUS,
  SET_SHARING_LINK,
  SET_SHOW_CREATE_MODAL,
  SET_CREATE_MODAL_TYPE,
  SET_FORM_SUBMITTING,
  SET_FORM_ERROR,
  SET_OFFSET,
  SET_NO_MORE,
  SET_TOTAL_PLAYLIST,
  SET_COMPONENT_UPDATED,
  SET_SELECTED_PLAYLIST_INFO,
  SET_PLAYLIST_VIDEOS_OFFSET,
  LOAD_PLAYLIST_VIDEOS,
  SET_TOTAL_PLAYLIST_VIDEOS,
  REMOVE_FROM_PLAYLIST,
  SET_PLAYLIST_VIDEOS_FETCHING,
  SET_PLAYLIST_VIDEOS_NO_MORE,
  RESET_PLAYLIST_VIDEOS,
  SET_SHOW_DELETE_MODAL,
  SET_SHOW_ADD_TO_CART,
  SET_SHOW_ADD_TO_CART_MODAL,
  SET_ENABLE_EDIT,
  APPEND_PLAYLIST,
  LOAD_STARRED,
  SET_TOTAL_STARRED_VIDEOS,
  SET_STARRED_VIDEOS_OFFSET,
  SET_STARRED_VIDEOS_FETCHING,
  APPEND_STARRED_VIDEOS,
  PREPEND_STARRED_VIDEOS,
  SET_STARRED_VIDEOS_NO_MORE,
  LOAD_FOLDER,
  LOAD_FOLDER_INFO,
  SET_FOLDER_SHOW,
  PREPEND_FOLDER,
  SET_SHOW_DELETE_FOLDER_MODAL,
  LOAD_FOLDER_VIDEOS,
  SET_TOTAL_FOLDER_VIDEOS,
  APPEND_FOLDER_VIDEOS,
  SET_FOLDER_VIDEOS_NO_MORE,
  SET_FOLDER_VIDEOS_OFFSET,
  SET_FOLDER_VIDEOS_FETCHING,
  SET_ENABLE_EDIT_DESCRIPTION,
  SET_PLAYLIST_DROPPING,
  SET_SEARCH_TERM,
  SET_PLAYLIST_SORTING,
  SET_PLAYLIST_VIDEOS_SORTING,
  APPEND_PLAYLIST_VIDEOS,
  LAST_ITEM_REPLACE_FROM_DROP,
  SET_VIDEOS_MOVING,
  SET_SORT_APPLIED,
  SET_FOLDER_VIDEOS_SORTING,
  STARRED_INPROGRESS,
  SET_SHOW_COPY_PLAYLIST_MODAL,
  SET_PLAYLISTS_OFFSET,
  SET_PLAYLISTS_MAX,
  DOWNLOAD_ALL_VIDEOS_PLAYLISTS_FULFILLED,
  DOWNLOAD_ALL_VIDEOS_PLAYLISTS_DETAILS_FULFILLED,
  CANCEL_DOWNLOAD_ALL_VIDEOS_FULFILLED,
  REQUEST_DOWNLOAD_ALL_VIDEOS_FETCHING,
  REQUEST_DOWNLOAD_ALL_VIDEOS_FETCHED
} from "../actions/playlist";

import { downloadAllVideosPollingClean } from "pages/licensing/account/userPanel/components/playlist/services/downloadAllVideosPlaylistsPolling";

const defaultState = {
  playlist: [],
  videoList: [],
  sorts: {
    columnKey: [],
    order: ""
  },
  downloadAllVideos: [],
  downloadAllVideosFetching: [],
  selectedPlaylist: {},
  snackbar: { open: false, message: "" },
  fetching: false,
  formSubmitting: false,
  showCreateModal: false,
  noMore: false,
  formError: "",
  createModalType: "Playlist", // playlist or folder
  sharing: "",
  max: 1000,
  offset: 0,
  total: 0,
  page: 1,
  playlistTotal: 0,
  componentUpdated: false,
  playlistInfo: null,
  enableEdit: false,

  playlistVideos: {
    fetching: false,
    noMore: false,
    showDeleteModal: false,
    showAddToCart: false,
    showAddToCartModal: false,
    offset: 0,
    max: 20,
    total: 0,
    videos: [],
    sorts: {
      columnKey: ["title"],
      order: "asc",
      custom: false
    }
  },

  starred: {
    fetching: false,
    noMore: false,
    offset: 0,
    max: 20,
    total: 0,
    videos: [],
    sorts: {
      columnKey: [],
      order: ""
    }
  },

  folders: [],
  folderInfo: null,
  folderVideos: {
    fetching: false,
    noMore: false,
    showDeleteModal: false,
    offset: 0,
    max: 20,
    total: 0,
    videos: [],
    sorts: {
      columnKey: ["name"],
      order: "asc",
      custom: false
    }
  },
  isFolderVisible: true,
  enableEditDescription: false,
  isDropping: true,
  searchTerm: "",
  lastItemReplaceFromDrop: null,
  movingVideo: false,
  isSortApplied: false,
  starredInProgress: {
    type: "",
    uuid: []
  },
  showCopyPlaylist: false
};

export default function thewire(state = defaultState, action) {
  const setState = (obj) => Object.assign({}, state, obj);

  switch (action.type) {
    case LOAD_PLAYLIST:
      return setState({ playlist: action.payload });
    case APPEND_PLAYLIST:
      return setState({ playlist: [...state.playlist, ...action.payload] });
    case RESET_PLAYLIST:
      return setState({ playlist: [] });
    case PREPEND_PLAYLIST:
      return setState({ playlist: [...action.payload, ...state.playlist] });
    case DOWNLOAD_ALL_VIDEOS_PLAYLISTS_FULFILLED:
      return setState({ downloadAllVideos: action.payload });
    case DOWNLOAD_ALL_VIDEOS_PLAYLISTS_DETAILS_FULFILLED:
      return setState({ downloadAllVideos: action.payload });
    case REQUEST_DOWNLOAD_ALL_VIDEOS_FETCHING:
      return setState({
        downloadAllVideosFetching: [...state.downloadAllVideosFetching, action.payload]
      });
    case REQUEST_DOWNLOAD_ALL_VIDEOS_FETCHED:
      return setState({
        downloadAllVideosFetching: action.payload
      });
    case CANCEL_DOWNLOAD_ALL_VIDEOS_FULFILLED:
      return setState({ downloadAllVideos: action.payload });
    case SET_FETCHING:
      return setState({ fetching: action.payload });
    case SET_SELECTED_PLAYLIST:
      return setState({ selectedPlaylist: action.payload });
    case REMOVE_PLAYLIST:
      return setState({ playlist: action.payload });
    case SET_SNACKBAR_STATUS:
      return setState({ snackbar: action.payload });
    case SET_SHARING_LINK:
      return setState({ sharing: action.payload });
    case SET_SHOW_CREATE_MODAL:
      return setState({ showCreateModal: action.payload });
    case SET_CREATE_MODAL_TYPE:
      return setState({ createModalType: action.payload });
    case SET_FORM_SUBMITTING:
      return setState({ formSubmitting: action.payload });
    case SET_FORM_ERROR:
      return setState({ formError: action.payload });
    case SET_OFFSET:
      return setState({ offset: action.payload });
    case SET_NO_MORE:
      return setState({ noMore: action.payload });
    case SET_TOTAL_PLAYLIST:
      downloadAllVideosPollingClean(action.payload);
      return setState({ playlistTotal: action.payload });
    case SET_COMPONENT_UPDATED:
      return setState({ componentUpdated: action.payload });
    case SET_ENABLE_EDIT:
      return setState({ enableEdit: action.payload });

    case SET_SELECTED_PLAYLIST_INFO:
      return setState({ playlistInfo: action.payload });
    case SET_PLAYLIST_VIDEOS_OFFSET:
      return setState({
        playlistVideos: Object.assign({}, state.playlistVideos, { offset: action.payload })
      });
    case LOAD_PLAYLIST_VIDEOS:
      return setState({
        playlistVideos: Object.assign({}, state.playlistVideos, { videos: action.payload })
      });
    case APPEND_PLAYLIST_VIDEOS:
      return setState({
        playlistVideos: Object.assign({}, state.playlistVideos, {
          videos: [...state.playlistVideos.videos, ...action.payload]
        })
      });
    case REMOVE_FROM_PLAYLIST:
      return setState({
        playlistVideos: Object.assign({}, state.playlistVideos, { videos: action.payload })
      });
    case SET_TOTAL_PLAYLIST_VIDEOS:
      return setState({
        playlistVideos: Object.assign({}, state.playlistVideos, { total: action.payload })
      });
    case SET_PLAYLIST_VIDEOS_FETCHING:
      return setState({
        playlistVideos: Object.assign({}, state.playlistVideos, { fetching: action.payload })
      });
    case SET_PLAYLIST_VIDEOS_NO_MORE:
      return setState({
        playlistVideos: Object.assign({}, state.playlistVideos, { noMore: action.payload })
      });
    case RESET_PLAYLIST_VIDEOS:
      return setState({ playlistVideos: Object.assign({}, state.playlistVideos, { videos: [] }) });
    case SET_SHOW_DELETE_MODAL:
      return setState({
        playlistVideos: Object.assign({}, state.playlistVideos, { showDeleteModal: action.payload })
      });
    case SET_SHOW_ADD_TO_CART:
      return setState({
        playlistVideos: Object.assign({}, state.playlistVideos, { showAddToCart: action.payload })
      });
    case SET_SHOW_ADD_TO_CART_MODAL:
      return setState({
        playlistVideos: Object.assign({}, state.playlistVideos, {
          showAddToCartModal: action.payload
        })
      });

    case LOAD_STARRED:
      return setState({ starred: Object.assign({}, state.starred, { videos: action.payload }) });
    case SET_TOTAL_STARRED_VIDEOS:
      return setState({ starred: Object.assign({}, state.starred, { total: action.payload }) });
    case SET_STARRED_VIDEOS_OFFSET:
      return setState({ starred: Object.assign({}, state.starred, { offset: action.payload }) });
    case SET_STARRED_VIDEOS_FETCHING:
      return setState({ starred: Object.assign({}, state.starred, { fetching: action.payload }) });
    case APPEND_STARRED_VIDEOS:
      return setState({
        starred: Object.assign({}, state.starred, {
          videos: [...state.starred.videos, ...action.payload]
        })
      });
    case PREPEND_STARRED_VIDEOS:
      return setState({
        starred: Object.assign({}, state.starred, {
          videos: [action.payload, ...state.starred.videos]
        })
      });
    case SET_STARRED_VIDEOS_NO_MORE:
      return setState({ starred: Object.assign({}, state.starred, { noMore: action.payload }) });
    case LOAD_FOLDER:
      return setState({ folders: action.payload });
    case SET_FOLDER_SHOW:
      return setState({ isFolderVisible: action.payload });
    case PREPEND_FOLDER:
      return setState({ folders: [action.payload, ...state.folders] });
    case LOAD_FOLDER_INFO:
      return setState({ folderInfo: action.payload });
    case SET_SHOW_DELETE_FOLDER_MODAL:
      return setState({
        folderVideos: Object.assign({}, state.folderVideos, { showDeleteModal: action.payload })
      });
    case LOAD_FOLDER_VIDEOS:
      return setState({
        folderVideos: Object.assign({}, state.folderVideos, { videos: action.payload })
      });
    case SET_TOTAL_FOLDER_VIDEOS:
      downloadAllVideosPollingClean(action.payload);
      return setState({
        folderVideos: Object.assign({}, state.folderVideos, { total: action.payload })
      });
    case APPEND_FOLDER_VIDEOS:
      return setState({
        folderVideos: Object.assign({}, state.folderVideos, {
          videos: [...state.folderVideos.videos, ...action.payload]
        })
      });
    case SET_FOLDER_VIDEOS_NO_MORE:
      return setState({
        folderVideos: Object.assign({}, state.folderVideos, { noMore: action.payload })
      });
    case SET_FOLDER_VIDEOS_OFFSET:
      return setState({
        folderVideos: Object.assign({}, state.folderVideos, { offset: action.payload })
      });
    case SET_FOLDER_VIDEOS_FETCHING:
      return setState({
        folderVideos: Object.assign({}, state.folderVideos, { fetching: action.payload })
      });
    case SET_ENABLE_EDIT_DESCRIPTION:
      return setState({ enableEditDescription: action.payload });
    case SET_PLAYLIST_DROPPING:
      return setState({ isDropping: action.payload });

    case SET_SEARCH_TERM:
      return setState({ searchTerm: action.payload });
    case SET_PLAYLIST_SORTING:
      return setState({ sorts: action.payload });
    case SET_PLAYLIST_VIDEOS_SORTING:
      return setState({
        playlistVideos: Object.assign({}, state.playlistVideos, { sorts: action.payload })
      });
    case LAST_ITEM_REPLACE_FROM_DROP:
      return setState({ lastItemReplaceFromDrop: action.payload });
    case SET_VIDEOS_MOVING:
      return setState({ movingVideo: action.payload });
    case SET_SORT_APPLIED:
      return setState({ isSortApplied: action.payload });
    case SET_FOLDER_VIDEOS_SORTING:
      return setState({
        folderVideos: Object.assign({}, state.folderVideos, { sorts: action.payload })
      });
    case STARRED_INPROGRESS:
      return setState({ starredInProgress: action.payload });
    case SET_SHOW_COPY_PLAYLIST_MODAL:
      return setState({ showCopyPlaylist: action.payload });
    case SET_PLAYLISTS_OFFSET:
      return setState({ offset: action.payload });
    case SET_PLAYLISTS_MAX:
      return setState({ max: action.payload });
    default:
      return state;
  }
}
