import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import googleTagManager from "@analytics/google-tag-manager";
import hubSpotPlugin from "@analytics/hubspot";

const gtmConfig = {
  containerId: process.env.REACT_APP_GA_TAG_MANAGER,
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_PREVIEW
};

const analytics = Analytics({
  app: "jukin-lp",
  plugins: [
    googleTagManager(gtmConfig),
    hubSpotPlugin({
      portalId: "8869423",
      enabled: false
    })
  ]
});

export default analytics;
