import request from "superagent";

import { SET_FETCHING, LOAD_PLAYLIST, SET_NO_MORE, SET_OFFSET, SET_TOTAL_PLAYLIST } from '../';

import forUser from "../../../../helpers/forUserQueryHelper"

export default () => async (dispatch, getState) => {

  const { loggedIn } = getState().auth;
  
  if (loggedIn) {

    dispatch({type: SET_FETCHING, payload: true})
    const { max, offset, playlist, sorts } = getState().playlists;
    const { access_token } = getState().auth.user;

    let query = "";

    if (sorts.order !== "" && sorts.columnKey.length > 0) {
      sorts.columnKey.forEach((item, index) => {
        query += `sortBy=${item}.${sorts.order}${index === (sorts.columnKey.length - 1) ? "" : "&"}`
      });
    }

    request
      .get(`/api/user/licensing/lists${forUser()}${query}`)
      .set("Authorization", `Bearer ${access_token}`)
      .query({
        max,
        offset: 0,
        includeThumbs: true
      })
      .then(response => {

        if (response.ok) {
          
          const playlistNotInFolder = response.body.data.filter(playlist => !playlist.folderUuid)

          if ((response.body.data.length + playlist.length) >= response.body.total) {
            dispatch({ type: SET_NO_MORE, payload: true });
          }
          
          dispatch({ type: SET_OFFSET, payload: offset + max });
          dispatch({ type: LOAD_PLAYLIST, payload: response.body.data });
          dispatch({ type: SET_TOTAL_PLAYLIST, payload: playlistNotInFolder.length });
        }
        dispatch({ type: SET_FETCHING, payload: false });

      })
      .catch(error => {
        console.log(error);
        dispatch({ type: SET_FETCHING, payload: false });
      })
  } else {
    dispatch({ type: SET_NO_MORE, payload: true });
  }

}