import { combineReducers } from "redux";

import auth from "./auth";
import search from "./search";
import user from "./user";
import detail from "./detail";
import usage from "./usage";
import shopify from "./shopify";
import thewire from "./thewire";
import playlists from "./playlists";
import onboarding from "./onboarding";
import merchandising from "./merchandising";
import publisher from "./publisher";
import snackbar from "./snackbar";
import recentView from "./recentViews";

import searchMvp from "../../pages/licensing/mvp/reducer";
import userPanel from "../../pages/licensing/account/userPanel/reducer";
import components from "components/reducers";

import { reducer as querySuggestion } from "components/shared/SearchInput/state/querySuggestion";

export default {
  pages: combineReducers({
    search: searchMvp,
    userPanel
  }),
  components,
  auth,
  user,
  search,
  detail,
  usage,
  shopify,
  thewire,
  playlists,
  onboarding,
  merchandising,
  publisher,
  snackbar,
  recentView,
  querySuggestion
};
