import request from "superagent";
import moment from "moment";
import forUser from "../../helpers/forUserQueryHelper";
import authActions from "./auth";

export const VIDEOS = "LOAD_VIDEOS";
export const MONTHS = "LOAD_MONTHS";
export const SAVE_VIDEOS = "SAVE_VIDEOS";
export const SUBMIT_VIDEOS = "SUBMIT_VIDEOS";

export const CHANGE_USED_SELECTED = "USED_SELECTED_CHANGED";
export const CHANGE_VIDEO_SELECTED = "CHANGE_VIDEO_SELECTED";
export const CHANGE_YEAR = "CHANGE_YEAR";
export const CHANGE_MONTH = "CHANGE_MONTH";

export const VIDEO_LOADING = "VIDEO_LOADING";
export const SUBMIT_PROCESSING = "SUBMIT_PROCESSING";

const rootPath = "/api/user/licensing/download";

const { tokenExpired } = authActions;

//region util functions
/**
 * get selected month for selected year
 * @param {object} selectedYear [instance of year]
 * @returns {object}            [last month of current year]
 */
function getSelectedMonth(selectedYear) {
  return selectedYear
    ? selectedYear.months[selectedYear.months.length - 1]
    : null;
}

/**
 * get selected month for selected year by month id
 * @param {object} selectedYear [instance of year]
 * @param {string} monthId      [month id in format mm (ex. 08, 12)]
 * @returns {object}            [month instance or null]
 */
function getSelectedMonthById(selectedYear, monthId) {
  if (!monthId || !selectedYear) {
    return null;
  }
  var index = selectedYear.months.findIndex(
    // eslint-disable-next-line
    monthItem => monthItem.id === parseInt(monthId)
  );
  return index > -1 ? selectedYear.months[index] : null;
}

/**
 * get selected year by year id
 * @param {Array} years     [years collection]
 * @param {string} yearId   [year id in format yyyy]
 * @returns {object}        [year instance or null]
 */
function getSelectedYearById(years, yearId) {
  if (!yearId) {
    return null;
  }
  var index = years.findIndex(yearItem => yearItem.id === yearId);
  return index > -1 ? years[index] : null;
}

/**
 * Convert response data into years collection
 * @param {Array} years [response data]
 * @returns {Array}     [year collection]
 */
function convertYears(years) {
  return years
    ? years.map(yearInst => {
        return {
          id: yearInst.year,
          label: yearInst.year,
          months: convertMonths(yearInst.months)
        };
      })
    : [];
}

/**
 * Convert response data into month collection
 * @param {Array} months [response data]
 * @return {Array}       [month collection]
 */
function convertMonths(months) {
  const monthLabels = moment.months();
  return months.map(month => {
    return {
      // eslint-disable-next-line
      id: parseInt(month),
      idStr: month,
      // eslint-disable-next-line
      label: monthLabels[parseInt(month) - 1]
    };
  });
}
//endregion

/**
 * get years and call videos loading
 * @param {Array} years     [converted years collection from API]
 * @param {string} yearId   [selected year id in format yyyy (ex. 2017)]
 * @param {string} monthId  [selected month id in format mm (ex. 08) ]
 * @param {function} cb     [error callback]
 * @return {function}       [dispatch to reducers]
 */
function getYears(years, yearId, monthId, cb) {
  let selectedYear = years
    ? getSelectedYearById(years, yearId) || years[years.length - 1]
    : null;
  let selectedMonth = selectedYear
    ? getSelectedMonthById(selectedYear, monthId) ||
      getSelectedMonth(selectedYear)
    : null;

  return dispatch => {
    dispatch({
      type: MONTHS,
      data: {
        years: years,
        currentYear: selectedYear,
        currentMonth: selectedMonth
      }
    });

    dispatch(loadVideos(cb));
  };
}

/**
 * videos
 * @param {object} videos [downloaded videos response data]
 * @return {object}       [object taken by redux and handled by the reducer]
 */
function getVideos(videos) {
  let downloads = videos.downloads || [];
  downloads.forEach(video => (video.tempUsed = video.used));
  const usedSelected = downloads.every(video => video.used);
  return {
    type: VIDEOS,
    data: {
      videos: downloads,
      usedSelected: usedSelected,
      note: videos.notes || "",
      declareDate: videos.declareDate || null,
      loading: false
    }
  };
}

/**
 * change selected year and reload videos for new year
 * @param {object} year [new selected year]
 * @return {function}   [dispatch to reducers]
 */
export function changeYear(year) {
  const month = getSelectedMonth(year);
  return dispatch => {
    dispatch({
      type: CHANGE_YEAR,
      data: {
        currentYear: year,
        currentMonth: month
      }
    });

    dispatch(loadVideos());
  };
}

/**
 * change selected month and reload videos for new year
 * @param {object} month [new selected month]
 * @return {function}    [dispatch to reducers]
 */
export function changeMonth(month) {
  return dispatch => {
    dispatch({
      type: CHANGE_MONTH,
      data: { currentMonth: month }
    });

    dispatch(loadVideos());
  };
}

/**
 * change "used" value to new one for all videos
 * @param  {boolean} value ["used" new value]
 * @return {object}        [object taken by redux and handled by the reducer]
 */
export function changeUsedSelected(value) {
  return {
    type: CHANGE_USED_SELECTED,
    data: { usedSelected: value }
  };
}

/**
 * change "used" value to new for one video
 * @param {object} item [video item]
 * @param {boolean} value [new "used" value]
 * @return {object}      [object taken by redux and handled by the reducer]
 */
export function changeCell(item, value) {
  return {
    type: CHANGE_VIDEO_SELECTED,
    data: { item: item, value: value }
  };
}

// getToken
function getToken(state) {
  return state.auth.user.access_token;
}

/**
 * Load month filter
 * @param {string} year     [year id in yyyy format]
 * @param {string} month    [month id in mm format]
 * @param {function} cb     [error callback]
 * @return {function}       [dispatch to reducers]
 */
export function loadMonths(year, month, cb) {
  return (dispatch, getState) => {
    dispatch({ type: VIDEO_LOADING });
    return request
      .get(`${rootPath}/months${forUser()}`)
      .set("Authorization", `Bearer ${getToken(getState())}`)
      .then(res => {
        if (res.status === 401) {
          tokenExpired();
        }

        res.ok && dispatch(getYears(convertYears(res.body), year, month, cb));
      })
      .catch(err => {
        dispatch(getYears([], year, month, cb));
      });
  };
}

/**
 * Load videos
 * @param {function} cb     [error callback]
 * @return {function} [dispatch to reducers]
 */
export function loadVideos(cb) {
  return (dispatch, getState) => {
    dispatch({ type: VIDEO_LOADING });

    const year = getState().usage.currentYear;
    const month = getState().usage.currentMonth;
    if (year && month) {
      return request
        .get(`${rootPath}/${year.id}/${month.id}${forUser()}`)
        .set("Authorization", `Bearer ${getToken(getState())}`)
        .then(res => {
          if (res.status === 401) {
            tokenExpired();
          }

          res.ok && dispatch(getVideos(res.body));
        })
        .catch(err => {
          console.log(err);
          cb && cb("Error", err.response ? err.response.text : "Error");
          dispatch(getVideos({ downloads: [] }));
        });
    } else {
      return dispatch(getVideos({ downloads: [] }));
    }
  };
}

/**
 * save video usage
 * @param {Array} videoIds  [changed videos id]
 * @param {object} year     [selected year instance]
 * @param {object} month    [selected month instance]
 * @param {function} cb     [error callback]
 * @return {function}       [dispatch to reducers]
 */
export function saveVideos(videoIds, year, month, cb) {
  return (dispatch, getState) => {
    return request
      .post(`${rootPath}/${year.id}/${month.id}/usage`)
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${getToken(getState())}`)
      .send({ videoIds: videoIds })
      .then(res => {
        if (res.status === 401) {
          tokenExpired();
        }

        res.ok && dispatch({ type: SAVE_VIDEOS });
      })
      .catch(err => {
        console.log(err.response.text);
        cb && cb("Error", err.response.text);
      });
  };
}

/**
 * Save and Submit video usage
 * @param {Array} videos    [changed videos id]
 * @param {string} notes    [submitting description]
 * @param {function} cb     [error callback]
 * @return {function}       [dispatch to reducers]
 */
export function submitVideos(videos, notes, errorCb, resultCb) {
  return (dispatch, getState) => {
    dispatch({ type: SUBMIT_PROCESSING, data: { processing: true } });

    const year = getState().usage.currentYear;
    const month = getState().usage.currentMonth;

    if (!videos.length) {
      // submit video usage
      return dispatch(_submitVideos(notes, year, month, errorCb, resultCb));
    } else {
      // save and submit video usage
      return dispatch(saveVideos(videos, year, month, errorCb))
        .then(() => {
          dispatch(_submitVideos(notes, year, month, errorCb, resultCb));
        })
        .catch(err => {
          console.log(err);
          errorCb && errorCb("Error", err.response.text);
          dispatch({ type: SUBMIT_PROCESSING, data: { processing: false } });
        });
    }
  };
}

/**
 *
 * @param {string} notes    [submitting description ]
 * @param {object} year     [selected year instance]
 * @param {object} month    [selected month instance]
 * @param {function} cb     [error callback]
 * @return {function}       [dispatch to reducers]
 * @private
 */
function _submitVideos(notes, year, month, errorCb, resultCb) {
  return (dispatch, getState) => {
    const duNotes = notes || null;
    return request
      .put(`${rootPath}/${year.id}/${month.id}/declare`)
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${getToken(getState())}`)
      .send({ notes: duNotes })
      .then(res => {
        if (res.status === 401) {
          tokenExpired();
        }

        res.ok &&
          dispatch({
            type: SUBMIT_VIDEOS,
            data: { note: notes, declareDate: new Date() }
          });
        resultCb && resultCb();
      })
      .catch(err => {
        var errorMessage =
          [405, 409].indexOf(err.response.statusCode) > -1
            ? err.response.text
            : "Server error.";
        errorCb && errorCb("Error", errorMessage);
        if (err.response.statusCode === 409) {
          dispatch(loadVideos());
        }
        dispatch({ type: SUBMIT_PROCESSING, data: { processing: false } });
      });
  };
}
