import styled from "styled-components";

const AddToListModalWrapper = styled.div`
  max-width: 460px;
  .seperator {
    display: block;
    width: 100%;
    border-bottom: 1px solid rgb(214, 213, 213);
    margin-top: 10px;
  }
  .wrapper {
    padding: 20px 20px 10px;
  }
  .header-list {
    .search-input {
      svg {
        fill: rgba(0, 0, 0, 0.5);
      }
      input {
        font-size: 12px;
      }
    }
  }
  .playlists-container {
    padding: 20px;
    h5 {
      color: rgba(0, 0, 0, 0.8);
      font-size: 12px;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-weight: 400;
    }
    p {
      &.small {
        font-size: 11px;
        color: rgba(0, 0, 0, 0.5);
      }
    }
    .filters-container {
      margin: 15px 0;
      button {
        border: 1px solid ${(props) => props.theme.colors.primary.main};
        text-transform: none;
        box-shadow: none;
        font-size: 12px;
        border-radius: 5px !important;
        margin-right: 10px;
        &.active {
          background-color: ${(props) => props.theme.colors.primary.shades12p};
          border: 1px solid ${(props) => props.theme.colors.primary.main};
        }
      }
    }
    .video-lists-list {
      max-height: 240px;
      width: 100%;
      padding: 0 10\px;
      height: auto;
      overflow-y: auto;
      .video-list-popup-item {
        border-bottom: 1px solid #e3e3e3;
        .list-item {
          cursor: pointer;
          &:hover {
            background: rgba(0, 0, 0, 0.1);
            p {
              color: ${(props) => props.theme.colors.primary.main};
            }
          }
        }
        .list-item-disable {
          cursor: not-allowed;
        }
        .flex {
          display: flex;
          justify-content: space-between;
          p {
            width: 70%;
            padding: 15px 0 0 10px;
          }
          span {
            &.video-count {
              color: rgba(0, 0, 0, 0.5);
              font-size: 12px;
              line-height: 47px;
            }
          }
          input[type="checkbox"] {
            display: none;
          }
          .MuiCheckbox-colorPrimary.Mui-checked {
            color: #45a2b7;
          }
          .MuiCircularProgress-root {
            margin: 10px 5px 0 10px;
          }
        }
        .popup-video-list-tip {
          position: fixed;
          background-color: #000;
          opacity: 0.8;
          color: #fff;
          text-align: center;
          padding: 8px 20px;
          border-radius: 3px;
          z-index: 10;
          top: 50%;
          width: 320px;
          left: calc((100% / 2) - (320px / 2));
        }
      }
    }
  }
  .add-to-list-popup-create-list {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
  }
  .no-lists {
    text-align: center;
    p {
      text-align: left;
    }
    svg {
      width: 80px;
      height: 80px;
      fill: rgb(0, 163, 184);
    }
  }
`;

export default AddToListModalWrapper;
