import React from "react";
import PropTypes from "prop-types";
import { StylesProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { ThemeProvider } from "@material-ui/styles";
import { ThemeProvider as SCThemeProvider } from "styled-components";

import theme from "theme/overrides";

function SetupTheme(props) {
  const { children } = props;

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <CssBaseline />
        <SCThemeProvider theme={theme}>
          <>{children}</>
        </SCThemeProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

SetupTheme.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SetupTheme;
