import styled from "styled-components";

const DeleteComponentContainer = styled.div`
  .deleteDialogContainer {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    align-items: center;
    padding: 10px;
    div {
      width: 100%;
      span {
        display: block;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 5px;
      }
      .deleteDescription {
        font-size: 10px !important;
        line-height: 10px;
        padding-bottom: 5px;
      }
    }
    button {
      color: ${(props) => props.theme.colors.primary.main};
      background-color: #fff;
      padding: 10px;
      border: 1px solid #b6b6b6;
      font-weight: 600;
      margin-left: 10px;
      outline-style: none;
      line-height: 12px;
      min-width: 100px;
    }
  }
`;

export default DeleteComponentContainer;
