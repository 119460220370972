import { actions } from "../actions/shopify";
import _ from "lodash";

const {
  SHOPIFY_ADD_TO_CART,
  SHOPIFY_REMOVE_FROM_CART,
  SHOPIFY_PRODUCTS_FETCHED,
  SHOPIFY_CHECKOUT_IN_PROGRESS,
  SHOPIFY_PRODUCTS_FETCH_FAILED,
  SHOPIFY_SET_LOADING,
  SHOPIFY_SET_PLAYLIST_INFO,
  SHOPIFY_SET_FETCHING_PLAYLIST_INFO,
  SHOPIFY_RESET_SESSION
} = actions;

const initialState = {
  products: [],
  cart: [],
  errors: false,
  loading: false,
  fetching: false,
  playlistInfo: null,
  session: null
};

export default (state = initialState, action) => {
  const setState = obj => ({ ...state, ...obj });

  switch (action.type) {
    case SHOPIFY_ADD_TO_CART:
      return setState({ cart: _.union(state.cart, [action.data]) });

    case SHOPIFY_REMOVE_FROM_CART:
      let filteredCart = _.differenceWith(
        state.cart,
        [action.data],
        (cartItem, removeItem) => {
          return (
            cartItem.productId === removeItem.productId &&
            cartItem.attributes.jvId === removeItem.attributes.jvId
          );
        }
      );
      return setState({ cart: filteredCart });

    case SHOPIFY_PRODUCTS_FETCHED:
      return setState({ products: action.data });

    case SHOPIFY_CHECKOUT_IN_PROGRESS:
      return setState({ cart: [], session: action.data });

    case SHOPIFY_PRODUCTS_FETCH_FAILED:
      return setState({ errors: action.data });
    
    case SHOPIFY_SET_LOADING:
      return setState({ loading: action.payload });
    
    case SHOPIFY_SET_FETCHING_PLAYLIST_INFO:
      return setState({ fetching: action.payload });

    case SHOPIFY_SET_PLAYLIST_INFO:
      return setState({ playlistInfo: action.payload });
    case SHOPIFY_RESET_SESSION:
      return setState({ session: null })
    default:
      return state;
  }
};
