import styled from "styled-components";

const ShareComponentContainer = styled.div`
  .shareUrlContainer {
    .share-link-loading-container {
      min-height: 46px;
      display: flex;
    }
    .share-link-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 8px;
    }
    input {
      height: 30px;
      padding-left: 10px;
      width: 100%;
      border: 1px solid #ccc;
      border-right: 0;
    }
    button {
      height: 30px;
      width: 30px;
      display: inline-block;
      position: relative;
      right: 0;
      outline-style: none;
      padding: 0;
    }
    img {
      background-color: transparent;
      width: 17px;
      margin: auto;
    }
  }
`;

export default ShareComponentContainer;
