import { createAction, handleActions } from "redux-actions";

const setSearching = createAction("PAGES/SEARCH/SET_SEARCHING");

const defaultState = false;

const reducer = handleActions(
  {
    [setSearching]: (_, action) => action.payload
  },
  defaultState
);

export { setSearching, reducer };
