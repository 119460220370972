import styled from "styled-components";

export default styled.div`
  margin-top: 1px;
  z-index: 1000;

  min-width: 200px;
  max-width: 200px;
  color: #212529;
  font-size: 14px;
  background-color: #fff;
  border-radius: 4px;
  list-style: none;
  background-clip: padding-box;
  padding: 12px;

  h4 {
    font-size: 21px;
    letter-spacing: 0.25px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    font-size: 12px;
    letter-spacing: 0.38px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.87);
    padding: 10px 0;
  }
  .cta--wrapper {
    display: flex;
    justify-content: space-around;
    button {
      max-width: 80px;
    }
    .cta--cancel {
      color: ${(props) => props.theme.colors.primary.main}
    }
    .cta--ok {
      background-color: ${(props) => props.theme.colors.primary.main}
      color: #fff;
      width: 96px;
      &:disabled {
        background-color: rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.26);
      }
    }
  }
  .circular--progress {
    color: ${(props) => props.theme.colors.primary.main}
    font-size: 24px;
  }
`;
