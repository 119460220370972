import { createAction, handleActions } from "redux-actions";

const setPlaylistPagination = createAction("PAGES/ACCOUNT/USER_PANEL/PLAYLIST/SET_PAGINATION");
const setFolderPagination = createAction("PAGES/ACCOUNT/USER_PANEL/FOLDER/SET_PAGINATION");
const setVideosPagination = createAction("PAGES/ACCOUNT/USER_PANEL/VIDEOS/SET_PAGINATION");

const defaultState = {
  playlist: {
    totalPages: 0,
    sizePerPage: 20,
    currentPage: 1,
    totalResults: 0
  },
  folder: {
    totalPages: 0,
    sizePerPage: 20,
    currentPage: 1,
    totalResults: 0
  },
  videos: {
    totalPages: 0,
    sizePerPage: 20,
    currentPage: 1,
    totalResults: 0
  }
};

const reducer = handleActions(
  {
    [setPlaylistPagination]: (state, action) =>
      Object.assign({}, state, { playlist: action.payload }),
    [setFolderPagination]: (state, action) => Object.assign({}, state, { folder: action.payload }),
    [setVideosPagination]: (state, action) => Object.assign({}, state, { videos: action.payload })
  },
  defaultState
);

export { setPlaylistPagination, setFolderPagination, setVideosPagination, reducer };
