import styled from "styled-components";

const VideoCheckBoxContainer = styled.div`
    .root {
        &:hover {
            background-color: transparent;
        }
    }
    .icon {
        border-radius: 3px;
        width: 16px;
        height: 16px;
        box-shadow: inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1);
        background-color: #f5f8fa;
        background-image: linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0));
        $root.Mui-focusVisible & {
            outline: 2px auto rgba(19,124,189,.6);
            outline-offset: 2;
        }
        input:hover ~ & {
            background-color: #ebf1f5;
        }
        input:disabled ~ & {
            box-shadow: none;
            background: rgba(206,217,224,.5);
        }
    }
    .checkedIcon: {
        background-color: #f5f8fa;
        background-image: linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0));
        &:before {
            display: block;
            width: 16px;
            height: 16px;
            background-image: url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23000'/%3E%3C/svg%3E\");
            content: "";
        }
        input:hover ~ & {
            background-color: #106ba3;
        }
    }
`;

export default VideoCheckBoxContainer;
