import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Tooltip from "@material-ui/core/Tooltip";

import { Display } from "../../helpers";
import { videoBoxDate } from "../../helpers/convertDate";
import { Mixpanel } from "./Mixpanel";
import Analytics from "../../helpers/analytics";

import { VideoBoxContainer } from "./styles";

class VideoBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: `${window.location.protocol}//players.brightcove.net`,
      src:
        this.props.index === 0
          ? `//players.brightcove.net/${this.BRIGHTCOVE_ACCOUNT_ID}/${this.BRIGHTCOVE_PLAYER_ID}_default/index.html?videoId=${this.props.brightCoveVideoId}&muted=true`
          : null,
      ePopoverOpen: false,
      aPopoverOpen: false,
      tooltipPopoverOpen: false
    };
  }
  BRIGHTCOVE_ACCOUNT_ID = process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID;
  BRIGHTCOVE_PLAYER_ID = process.env.REACT_APP_BRIGHTCOVE_PLAYER_ID;

  _play = () => {
    let { props } = this;
    let { brightcoveVideo } = this.refs;

    if (!this.state.src) {
      this.setState({
        src: `//players.brightcove.net/${this.BRIGHTCOVE_ACCOUNT_ID}/${this.BRIGHTCOVE_PLAYER_ID}_default/index.html?videoId=${props.brightCoveVideoId}&muted=true`
      });
    }

    setTimeout(() => {
      try {
        // eslint-disable-next-line
        brightcoveVideo.contentWindow.origin;
      } catch (e) {
        if (brightcoveVideo.contentWindow) {
          brightcoveVideo.contentWindow.postMessage("playVideo", this.state.url);
        }
      }
    }, 200);
  };
  _pause = () => {
    let { brightcoveVideo } = this.refs;

    try {
      // eslint-disable-next-line
      brightcoveVideo.contentWindow.origin;
    } catch (e) {
      if (brightcoveVideo.contentWindow) {
        brightcoveVideo.contentWindow.postMessage("pauseVideo", this.state.url);
      }
    }
  };
  _addFav = () => {
    this.props.addFavorite(this.props.jmId, this.props.user.access_token);
    if (this.props.pageName === "savedVideos") {
      Analytics.track("Save for Later", {
        category: "SavedVideo",
        label: this.props.jmId
      });
    } else if (this.props.pageName === "search") {
      Analytics.track("Save for Later", {
        category: "LP-Search",
        label: this.props.jmId
      });
    } else if (this.props.pageName === "InquiryThankyou") {
      Analytics.track("Save for Later", {
        category: "LP-Inquiry",
        label: this.props.jmId
      });
    } else {
      Analytics.track("Recommended Save for Later", {
        category: "LP-VideoDetail",
        label: this.props.jmId
      });
    }
  };

  _removeFav = () => {
    this.props.removeFavorite(this.props.jmId, this.props.user.access_token);
    if (this.props.pageName === "savedVideos") {
      Analytics.track("Remove Saved Video", {
        category: "SavedVideo",
        label: this.props.jmId
      });
    } else if (this.props.pageName === "search") {
      Analytics.track("Remove Saved Video", {
        category: "LP-Search",
        label: this.props.jmId
      });
    } else if (this.props.pageName === "InquiryThankyou") {
      Analytics.track("Remove Saved Video", {
        category: "LP-Inquiry",
        label: this.props.jmId
      });
    } else {
      Analytics.track("Recommended Remove Saved Video", {
        category: "LP-VideoDetail",
        label: this.props.jmId
      });
    }
  };

  render() {
    const { pageName, location } = this.props;
    let { props } = this;
    let canModify = props.currentList ? props.currentList.canModify : false;
    let eTooltipText = "Digital/clip show use";
    let aTooltipText = "";
    let aTooltipText2 = "";
    let iconColor = "";

    let riffUser = props.loggedIn && props.user.roles.includes("ROLE_RIFF_USER");

    if (props.aci === "YELLOW") {
      aTooltipText = "Inquire for Advertising Use";
      iconColor = "yellow";
    } else if (props.aci === "GREEN") {
      aTooltipText = "Advertising Use";
      aTooltipText2 =
        "Talent releases on file for all on-camera participants; inquire regarding trademark and other releases.";
      iconColor = "green";
    } else if (props.aci === "RED") {
      aTooltipText = "Not for Advertising Use";
      iconColor = "red";
    }

    return (
      <VideoBoxContainer id={"video-box-" + props.jmId} className={`result-item ${pageName}`}>
        <div
          className="imgthumb img-responsive"
          onMouseOver={!props.mobile && this._play}
          onMouseOut={!props.mobile && this._pause}
        >
          <Display if={!props.mobile}>
            <div className="hovereffect image-container">
              <Link
                onClick={() => {
                  Analytics.track("select_item", {
                    ecommerce: {
                      items: [
                        {
                          item_name: props.publishingTitle,
                          item_id: props.jmId,
                          item_list_name: this.props.pageName
                        }
                      ]
                    }
                  });

                  if (pageName === "savedVideos") {
                    Analytics.track("GoToVideoDetail", {
                      category: "SavedVideo",
                      label: props.jmId
                    });
                    Mixpanel.track("Click Video from Saved Videos", {
                      JVID: props.jmId
                    });
                  } else if (pageName === "video-detail-recommendations") {
                    Analytics.track("GoToVideoDetail", {
                      category: "LP-VideoDetail",
                      label: props.jmId
                    });
                    Mixpanel.track("Click Video from Bottom Recommended", {
                      JVID: props.jmId
                    });
                  } else if (pageName === "home") {
                    Analytics.track("TrendingVideoDetail", {
                      category: "LP-LandingPage",
                      label: props.jmId
                    });
                  } else if (pageName === "recommendations") {
                    Analytics.track("GoToVideoDetail", {
                      category: "LP-VideoDetail-Recommended",
                      label: props.jmId
                    });
                    Mixpanel.track("Click Video from Player Recommended", {
                      JVID: props.jmId
                    });
                  } else if (pageName === "collections") {
                    Analytics.track("GoToVideoDetail", {
                      category: "LP-Collections",
                      label: props.jmId
                    });
                    Mixpanel.track("Click Video from Collections", {
                      JVID: props.jmId
                    });
                  } else {
                    Analytics.track("GoToVideoDetail", {
                      category: "LP-Search",
                      label: props.jmId
                    });
                    Mixpanel.track("Click Video from Search", {
                      JVID: props.jmId
                    });
                  }
                }}
                to={{
                  pathname: `/licensing/view/${props.jmId}`,
                  state: { prevPath: this.props.location.pathname }
                }}
                className="click-intercept"
              />
              <div className="video-thumbnail-container">
                <img className="thumb-bg" src={props.imgUrl} alt={props.title} />
                <div className="thumb-overlay">
                  <img className="thumb-vid" src={props.imgUrl} alt={props.title} />
                </div>
              </div>
              <div className="itemBottom itemoverlay">
                <span className="jv">JV#: {props.jmId ? props.jmId : "N/A"}</span>
                <Display if={props.loggedIn}>
                  {props.pageName === "savedVideos" && props.isFav && canModify ? (
                    <Tooltip title="Remove from List" placement="bottom" arrow>
                      <button className="remove-from-fav fav" onClick={this._removeFav}>
                        <span>save</span>
                      </button>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Add to List" placement="bottom" arrow>
                      <button className="add-to-fav" onClick={this._addFav}>
                        <span>save</span>
                      </button>
                    </Tooltip>
                  )}
                </Display>
                <Display if={!props.loggedIn}>
                  <Link
                    to="/licensing/login"
                    onClick={() => {
                      this._addFav();
                      localStorage.setItem("addTolist", true);
                      localStorage.setItem("prevPath", location.pathname);
                      localStorage.setItem("onBoardingAddToList", props.jmId);
                    }}
                  >
                    <Tooltip title="Add to List" placement="bottom" arrow>
                      <button className="add-to-fav modal_trigger">
                        <span>save</span>
                      </button>
                    </Tooltip>
                  </Link>
                </Display>
              </div>
              <div className="hover-play">
                {props.brightCoveVideoId ? (
                  <iframe
                    title={`bc-${props.brightCoveVideoId}`}
                    id={`bc-${props.brightCoveVideoId}`}
                    ref="brightcoveVideo"
                    src={this.state.src}
                  />
                ) : (
                  <div>
                    <h4 className="align-center black addtop bold">No Video Found!</h4>
                  </div>
                )}
              </div>
            </div>
          </Display>
          <Display if={props.mobile}>
            <div className="image-container">
              <Link
                to={{
                  pathname: `/licensing/view/${props.jmId}`,
                  state: { prevPath: this.props.location.pathname }
                }}
              >
                <img src={props.imgUrl} alt={props.title} />
              </Link>
            </div>
          </Display>
        </div>

        <div className="itemTitle">
          <Display if={riffUser}>
            {(!props.iar && props.rsa) || (!props.iar && props.bo) ? <div className="iar" /> : null}
          </Display>
          <Link
            onClick={() => {
              Analytics.track("select_item", {
                ecommerce: {
                  items: [
                    {
                      item_name: props.publishingTitle,
                      item_id: props.jmId,
                      item_list_name: this.props.pageName
                    }
                  ]
                }
              });

              if (pageName === "savedVideos") {
                Analytics.track("GoToVideoDetail", {
                  category: "SavedVideo",
                  label: props.jmId
                });
                Mixpanel.track("Click Video from Saved Videos", {
                  JVID: props.jmId
                });
              } else if (pageName === "video-detail-recommendations") {
                Analytics.track("GoToVideoDetail", {
                  category: "LP-VideoDetail",
                  label: props.jmId
                });
                Mixpanel.track("Click Video from Bottom Recommended", {
                  JVID: props.jmId
                });
              } else if (pageName === "home") {
                Analytics.track("TrendingVideoDetail", {
                  category: "LP-LandingPage",
                  label: props.jmId
                });
              } else if (pageName === "recommendations") {
                Analytics.track("GoToVideoDetail", {
                  category: "LP-VideoDetail-Recommended",
                  label: props.jmId
                });
                Mixpanel.track("Click Video from Player Recommended", {
                  JVID: props.jmId
                });
              } else if (pageName === "collections") {
                Analytics.track("GoToVideoDetail", {
                  category: "LP-Collections",
                  label: props.jmId
                });
                Mixpanel.track("Click Video from Collections", {
                  JVID: props.jmId
                });
              } else {
                Analytics.track("GoToVideoDetail", {
                  category: "LP-Search",
                  label: props.jmId
                });
                Mixpanel.track("Click Video from Search", {
                  JVID: props.jmId
                });
              }
            }}
            to={{
              pathname: `/licensing/view/${props.jmId}`,
              state: { prevPath: this.props.location.pathname }
            }}
          >
            <h3>
              {props.publishingTitle !== undefined ? (
                props.publishingTitle.length > 55 ? (
                  <Tooltip title={props.publishingTitle}>
                    <div>{props.publishingTitle}</div>
                  </Tooltip>
                ) : (
                  props.publishingTitle
                )
              ) : props.title.length > 55 ? (
                <Tooltip title={props.title}>
                  <div>{props.title}</div>
                </Tooltip>
              ) : (
                props.title
              )}
            </h3>
          </Link>
          <div className="date__clearance">
            <span>Date Added: </span> {videoBoxDate(props.signedDate)} {/* Prev Posted Date */}
            <span className="float-right">
              <Tooltip title={eTooltipText} placement="bottom" arrow>
                <i className="use-icon etp">D</i>
              </Tooltip>

              <Tooltip
                title={
                  <React.Fragment>
                    {aTooltipText} <br /> {aTooltipText2}
                  </React.Fragment>
                }
                placement="bottom"
                arrow
              >
                <i className={`use-icon ${iconColor}`}>A</i>
              </Tooltip>
            </span>
          </div>
        </div>
      </VideoBoxContainer>
    );
  }
}

VideoBox.propTypes = {
  location: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
};

export default withRouter(VideoBox);
