import styled from "styled-components";
import { media } from "../../common/media";
const CreateListContainer = styled.div`
  padding: 20px;
  .create-list-error-container {
    display: flex;
    flex-direction: column;
  }
  .create-list-error {
    color: #ff0000;
    font-size: 12px;
    padding-top: 5px;
    font-weight: normal !important;
    line-height: normal !important;
  }
  .create-list-container {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    .create-input-wrapper {
      svg {
        fill: rgba(0, 0, 0, 0.5);
      }
    }
    input {
      width: 100%;
      font-size: 12px;
      min-width: 235px;
      ${media.tablet`
        min-width: 200px;
      `};
    }
    button {
      color: ${(props) => props.theme.colors.primary.main};
      letter-spacing: 1px;
      margin-left: 15px;
    }
    a[disabled] {
      cursor: not-allowed;
    }

    .create-new:not(:disabled) {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
      transform: scale(1);
      animation: pulse 1.5s infinite;
      &:hover {
        animation: none;
      }
    }
  }
  span.create-list-error {
    padding-top: 8px !important;
  }
  .jukin-orange {
    color: #008183 !important;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;

export default CreateListContainer;
