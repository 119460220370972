
import {
  SET_FETCHING,
  LOAD_SEARCH
} from '../actions/recentViews';

const defaultState = {
  fetching: true,
  history: []
}

export default function thewire(state = defaultState, action) {
  const setState = (obj)=> Object.assign({}, state, obj);

  switch (action.type) {
    case SET_FETCHING:
      return setState ({ fetching: action.payload });
    case LOAD_SEARCH:
      return setState ({ history: action.payload });
    default:
      return state;
  }
}