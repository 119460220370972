import mixpanel from "mixpanel-browser";
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

let isProd = true;
let getUser,
  userAuth,
  isLoggedIn,
  isJukinUser = "";
let hostname = window.location.hostname;

if (hostname.indexOf("localhost") >= 0) {
  isProd = false;
}

if (JSON.parse(localStorage.getItem("persist:root")) !== null) {
  getUser = JSON.parse(localStorage.getItem("persist:root"));
  userAuth = JSON.parse(getUser.auth);
  isLoggedIn = userAuth.loggedIn;
  isJukinUser = isLoggedIn
    ? userAuth.user.username.indexOf("jukinmedia") >= 0
    : "";
}

let actions = {
  identify: id => {
    if (isProd && isLoggedIn && !isJukinUser) mixpanel.identify(id);
  },
  alias: id => {
    if (isProd && isLoggedIn && !isJukinUser) mixpanel.alias(id);
  },
  track: (name, props, transport={}, callback = null) => {
    if (isProd && isLoggedIn && !isJukinUser) mixpanel.track(name, props, transport, callback);
  },
  people: {
    set: props => {
      if (isProd && isLoggedIn && !isJukinUser) mixpanel.people.set(props);
    }
  }
};

export let Mixpanel = actions;
