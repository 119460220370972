const $ = window.jQuery;

function checkMobile() {
  if ($(window).width() <= 992) {
    return true;
  } else {
    return false;
  }
}

export default function(id) {
  let el = $(".dropdown ul." + id);
  let openDropdown = document.querySelector(".open");
  if (openDropdown) {
    openDropdown.classList.remove("open");
  }

  if (checkMobile()) {
    if (el.css("display") === "block") {
      el.css("display", "none");
    } else {
      $(".stay-open ul").css("display", "none");
      el.css("display", "block");
      el.css("z-index", "9999");
    }
  } else {
    $(".dropdown.stay-open").on("mouseleave", () => {
      $(el).css("display", "none");
    });
    el.css("display", "block");
    el.css("z-index", "9999");
  }
}
