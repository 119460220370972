import request from "superagent";
import { buildSearchQuery } from "../../helpers";
import getVideoDetailUrl, { getVideoDetailAlgolia } from "../../helpers/getVideoDetailUrl";
import getVideoDetailInfoUrl from "../../helpers/getVideoDetailInfoUrl";
import authActions from "./auth";
import { mainIndex } from "httpRequests/algolia";
// import algoliarecommend from "@algolia/recommend";
export const RESULTS = "RESULTS";
export const DETAIL = "DETAIL";
export const RECOMMENDED = "RECOMMENDED";
export const ADDITIONALINFO = "ADDITIONALINFO";
export const TRENDING = "TRENDING";
export const PARAMS = "PARAMS";
export const CATEGORIES = "CATEGORIES";
export const LOADING = "LOADING";
export const ERROR = "ERROR";
export const CLEAR_RESULT = "CLEAR_RESULT";

let es_url = process.env.REACT_APP_ELASTICSEARCH_HOST;
let esIndex = process.env.REACT_APP_ELASTICSEARCH_INDEX;
let esType = process.env.REACT_APP_ELASTICSEARCH_TYPE;
let funkURL = process.env.REACT_APP_FUNK_URL;

let searchUrl = `${es_url}/${esIndex}/${esType}/_search`;
const { tokenExpired } = authActions;
// const recommendClient = algoliarecommend(
//   process.env.REACT_APP_ALGOLIA_APP_ID,
//   process.env.REACT_APP_ALGOLIA_API_KEY
// );

var searchFields = [
  "jmId",
  "title",
  "publishingTitle",
  "publishingDescription",
  "description",
  "originalPublishingDate",
  "signedDate",
  "category",
  "tags",
  "isHD",
  "isVertical",
  "isTrending",
  "duration",
  "location",
  "popularity",
  "brightCoveVideoId",
  "originalSourceUrl",
  "infractionUrls",
  "publishedUrls",
  "claimedYouTubeIds",
  "platformId",
  "isPublicDownloadAvailable",
  "imgUrl",
  "clearanceStatus",
  "iar",
  "il",
  "bo",
  "og",
  "rs",
  "rsa",
  "aci",
  "libraryName",
  "libraryShortName"
];

let trendingQuery = {
  fields: searchFields,
  query: {
    filtered: {
      query: {
        bool: {
          must: [
            {
              multi_match: {
                query: "LP Homepage Trending",
                fields: ["privateTags^1.2"]
              }
            }
          ]
        }
      }
    }
  },
  size: 4,
  sort: [
    {
      signedDate: {
        order: "desc"
      }
    }
  ]
};

/**
 * video search
 * @param  {object} data [object returned from api]
 * @return {object}      [object taken by redux and handled by the reducer]
 */
function videoSearch(data) {
  return {
    type: RESULTS,
    data: data
  };
}

/**
 * detail
 * @param  {object} data [object returned from api]
 * @return {object}      [object taken by redux and handled by the reducer]
 */
function detail(data) {
  return {
    type: DETAIL,
    data: data
  };
}

/**
 * recommended
 * @param  {object} data [object returned from api]
 * @return {object}      [object taken by redux and handled by the reducer]
 */
function recommended(data) {
  return {
    type: RECOMMENDED,
    data: data
  };
}

function additionalInfo(data) {
  return {
    type: ADDITIONALINFO,
    data: data
  };
}

/**
 * trending vids
 * @param  {object} data [object returned from api]
 * @return {object}      [object taken by redux and handled by the reducer]
 */
function trendingVids(data) {
  return {
    type: TRENDING,
    data: data
  };
}

/**
 * updateSearchParams
 * @param  {object} params [searchParams object]
 * @return {object}      [object taken by redux and handled by the reducer]
 */
export function updateSearchParams(params) {
  return {
    type: PARAMS,
    data: {
      searchParams: params
    }
  };
}

/**
 * categories
 * @param  {object} data [object returned from api]
 * @return {object}      [object taken by redux and handled by the reducer]
 */
function categories(data) {
  return {
    type: CATEGORIES,
    data: data
  };
}

/**
 * get search results
 * @param  {object} params   [all of the parameters given by the user for search]
 * @return {function}        [dispatch to reducers]
 */
export function getSearchResults(params) {
  return async (dispatch) => {
    dispatch({ type: LOADING });
    let searchQuery = await buildSearchQuery(params);
    getCategories(dispatch);

    request
      .post(searchUrl)
      .set("Content-Type", "application/json")
      .send(searchQuery)
      .then((res, err) => {
        if (!res.ok) {
          dispatch({ type: ERROR });
        } else {
          if (res.body.hits.total === 0) {
            request
              .post(searchUrl)
              .send(trendingQuery)
              .then((response, error) => {
                if (!res.ok) {
                  console.log(error);
                } else {
                  dispatch(
                    videoSearch({
                      hits: res.body.hits.hits,
                      total: res.body.hits.total,
                      trending: response.body.hits.hits,
                      params: params
                    })
                  );
                }
              });
          } else {
            if (document.querySelector("#page")) {
              document.querySelector("#page").value = params.page;
            }
            res.body.hits.hits.forEach((result) => {
              Object.keys(result.fields).forEach((key) => {
                if (key !== "tags") {
                  result.fields[key] = result.fields[key] ? result.fields[key][0] : null;
                }
              });
            });
            dispatch(
              videoSearch({
                hits: res.body.hits.hits,
                total: res.body.hits.total,
                params: params
              })
            );
          }
        }
      });
  };
}

/**
 * get video detail
 * @return {function} [dispatch to reducers]
 */
export function getVideoDetail(token, riffUser) {
  const videoDetailUrl = getVideoDetailUrl();

  return (dispatch) => {
    dispatch({ type: LOADING });

    request
      .get(`${videoDetailUrl}?fields=${searchFields.join(",")}`)
      .ok((res) => res.status < 500)
      .then((res, err) => {
        if (res.status === 401) {
          tokenExpired();
        }
        if (!res.ok) {
          //Render video is not available component when video status changed from Processed to others. Since the api cached for 10 mins, the page shows as blank.
          console.log("error", err);
          dispatch(detail({ fields: { error: true, errorMessage: err.message } }));
        } else {
          Object.keys(res.body.fields).forEach((key) => {
            if (key !== "tags") {
              res.body.fields[key] = res.body.fields[key] ? res.body.fields[key][0] : null;
            }
          });
          dispatch(detail({ fields: res.body.fields }));
          if (token !== undefined && riffUser) {
            getVideoDetailInfo(dispatch, token);
          }
          getRecommended(dispatch);
        }
      });
  };
}

export function getVideoDeailMVP(token, riffUser) {
  const objectId = getVideoDetailAlgolia();
  let clientIndex = mainIndex;
  return async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const response = await clientIndex.getObject(objectId);
      dispatch(detail({ fields: response }));
      if (token !== undefined && riffUser) {
        getVideoDetailInfo(dispatch, token);
      }

      const filter = {
        facetFilters: [`category:${response.category}`]
      };
      const recommed = await clientIndex.search("", filter);

      dispatch(recommended(recommed.hits.filter((r) => r.objectID !== response.objectID)));

      // To get Algolia recomendations once we pay
      // const recomendation = await recommendClient.getRecommendations([
      //   {
      //     indexName: clientIndex,
      //     objectID: objectId
      //   }
      // ]);
    } catch (error) {
      console.log("error", error);
      dispatch(detail({ fields: { error: true, errorMessage: error.message } }));
    }
  };
}

/**
 * get trending videos
 * @return {function} [dispatch to reducers]
 */
export function getTrendingVideos() {
  return (dispatch) => {
    dispatch({ type: LOADING });

    request
      .post(searchUrl)
      .set("Content-Type", "application/json")
      .send(trendingQuery)
      .then((res, err) => {
        if (!res.ok) {
        } else {
          res.body.hits.hits.forEach((result) => {
            Object.keys(result.fields).forEach((key) => {
              if (key !== "tags") {
                result.fields[key] = result.fields[key] ? result.fields[key][0] : null;
              }
            });
          });
          dispatch(trendingVids(res.body.hits.hits));
        }
      });
  };
}

function getVideoDetailInfo(dispatch, token) {
  const videoDetailInfo = getVideoDetailInfoUrl();
  request
    .get(videoDetailInfo)
    .set("Authorization", `Bearer ${token}`)
    .then((res, err) => {
      if (res.status === 401) {
        tokenExpired();
      }

      if (!res.ok) {
        console.log(err);
      } else {
        dispatch(additionalInfo(res.body));
      }
    });
}

function getRecommended(dispatch) {
  const videoDetailUrl = getVideoDetailUrl();
  let esRecUrl = `${videoDetailUrl}/_mlt?mlt_fields=tags,privateTags,publishingTitle,publishingDescription,category&min_doc_freq=15&min_term_freq=1&search_size=16`;

  request.get(esRecUrl).then((res, err) => {
    if (!res.ok) {
      console.log(err);
    } else {
      dispatch(recommended(res.body.hits.hits));
    }
  });
}

function getCategories(dispatch) {
  let catList = [];
  request.get(`/api/public/cats?max=26`).then((res, err) => {
    if (!res.ok) {
    } else {
      res.body.result.map((r) => {
        if (r.name !== "RecordSetter" && r.name !== "Nitro Circus") catList.push(r.name);

        return catList;
      });

      catList.sort();

      dispatch(
        categories({
          categoryList: catList
        })
      );
    }
  });
}

export function getCategoriesList() {
  return (dispatch) => {
    dispatch({ type: LOADING });
    getCategories(dispatch);
  };
}

export function clearResults() {
  return (dispatch) => {
    dispatch({ type: CLEAR_RESULT });
  };
}
