import { combineReducers } from "redux";

import { reducer as isMounted } from "./state/isMounted";
import { reducer as searching } from "./state/searching";
import { reducer as downloading } from "./state/downloading";
import { reducer as showFilter } from "./state/showFilter";
import { reducer as searchTerms } from "./state/searchTerms";
import { reducer as results } from "./state/results";
import { reducer as params } from "./state/params";
import { reducer as pagination } from "./state/pagination";

export default combineReducers({
  isMounted,
  searching,
  downloading,
  showFilter,
  searchTerms,
  results,
  params,
  pagination
});
