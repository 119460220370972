import {
  SET_SNACKBAR,
  SET_CLOSE_SNACKBAR,
} from "../actions/snackbar";

const defaultState = {
  open: false,
  message: "",
  severity: "default",
  reload: false,
};

export default function merchandising(state = defaultState, action) {
  switch (action.type) {
    case SET_SNACKBAR:
      return Object.assign({}, defaultState, action.payload);
    case SET_CLOSE_SNACKBAR:
      return defaultState;
    default:
      return state;
  }
}
