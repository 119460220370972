import { createAction, handleActions } from "redux-actions";

const loadWatchList = createAction("PAGES/WATCHLIST/LOAD_WATCH_LIST");
const setViewType = createAction("PAGES/WATCHLIST/SET_VIEW_TYPE");
const setFetching = createAction("PAGES/WATCHLIST/SET_FETCHING");
const setTotal = createAction("PAGES/WATCHLIST/SET_TOTAL");
const setPagination = createAction("PAGES/WATCHLIST/SET_PAGINATION");
const setNoMore = createAction("PAGES/WATCHLIST/SET_NO_MORE");

const defaultState = {
  videos: [],
  viewType: "list",
  fetching: true,
  total: 0,
  noMore: false,
  pagination: {
    totalPages: 0,
    sizePerPage: 20,
    currentPage: 1,
    totalResults: 0,
    offset: 0
  }
};

const reducer = handleActions(
  {
    [loadWatchList]: (state, action) => Object.assign({}, state, { videos: action.payload }),
    [setViewType]: (state, action) => Object.assign({}, state, { viewType: action.payload }),
    [setFetching]: (state, action) => Object.assign({}, state, { fetching: action.payload }),
    [setTotal]: (state, action) => Object.assign({}, state, { total: action.payload }),
    [setNoMore]: (state, action) => Object.assign({}, state, { noMore: action.payload }),
    [setPagination]: (state, action) => Object.assign({}, state, { pagination: action.payload })
  },
  defaultState
);

export { loadWatchList, setViewType, setFetching, setTotal, setNoMore, setPagination, reducer };
