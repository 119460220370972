import moment from "moment";

export const videoDetailDates = (dateStr) => moment(dateStr).format("DD MMM YY");

export const videoBoxDate = (dateStr) => moment(dateStr).format("DD MMM YY");

export const changeFormat = (dateStr) => moment(dateStr, "MM/DD/YYYY").format("YYYY-M-DD");

export const twoDigitYear = (dateStr) => {
  if (!moment(dateStr, "YYYY-M-DD", true).isValid()) {
    return "";
  }

  return moment(dateStr, "YYYY-M-DD").format("DD MMM YY");
};

export const fourDigitYear = (dateStr) => {
  if (!moment(dateStr, "YYYY-M-DD", true).isValid()) {
    return "";
  }

  return moment(dateStr, "YYYY-M-DD").format("MM/DD/YYYY");
};
