import React, { useState } from "react";
import Analytics from "../../helpers/analytics";
import PropTypes from "prop-types";
import Display from "../../helpers/Display";
import { Mixpanel } from "./Mixpanel";
import { CreateListContainer } from "./styles";
import { Button, InputAdornment, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const CreateList = React.forwardRef((props, ref) => {
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [playlistName, setPlaylistName] = useState("");

  const validateListName = (name) => {
    if (!name || name.length < 3) {
      return "Your title is too short. Please use at least 3 characters.";
    }
    if (name && name.length > 255) {
      return "Your title is too long. Please limit it to 255 characters.";
    }
    return "";
  };

  const getCleanName = () => {
    let name = playlistName;
    return name ? name.trim().replace(/\s{2,}/g, " ") : "";
  };

  const createList = () => {
    if (disabled) {
      return;
    }
    const name = getCleanName();
    const nameError = validateListName(name);
    if (!nameError) {
      setDisabled(true);
      props.createVideoList(name, createListSuccessCallback, createListFailureCallback);
    } else {
      Analytics.track("List Name Error", {
        category: "LP-Playlist",
        label: nameError
      });
      setError(nameError);
    }
  };

  const createListSuccessCallback = (uuid) => {
    props.successCallback && props.successCallback(uuid);
    Analytics.track("List Created", {
      category: "LP-Playlist",
      label: uuid
    });
    Mixpanel.track("Playlist Created", {
      UUID: uuid
    });
    setPlaylistName("");
    resetErrorState();
  };

  const createListFailureCallback = (message) => {
    setDisabled(false);
    setError(message);
    Analytics.track("List Name Error", {
      category: "LP-Playlist",
      label: message
    });
  };

  const resetErrorState = () => {
    setError(null);
    setDisabled(false);
  };

  return (
    <CreateListContainer>
      <div className="create-list-error-container">
        <div className="create-list-container">
          <div className="create-input-wrapper">
            <TextField
              onChange={(e) => {
                setPlaylistName(e.target.value);
              }}
              disabled={disabled}
              placeholder="Create new playlist"
              inputProps={{ maxLength: 255 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AddIcon />
                  </InputAdornment>
                )
              }}
            />
          </div>

          <Button
            className={props.isEmpty ? "create-new" : ""}
            disabled={disabled}
            onClick={createList}
          >
            Create New
          </Button>
        </div>
        <Display if={error}>
          <span className="create-list-error">Error: {error}</span>
        </Display>
      </div>
    </CreateListContainer>
  );
});

CreateList.propTypes = {
  createVideoList: PropTypes.func.isRequired,
  successCallback: PropTypes.func
};

export default CreateList;
