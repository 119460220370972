import styled from "styled-components";

const DownloadPlaylist = styled.div`
  .downloadCompletedArea {
    display: block;
  }

  .downloadText {
    color: #00c3cb;
    font-weight: 500;
  }

  .downloadFootage {
    margin-top: 10px;
  }

  .preparing {
    color: #00c3cb;
    font-weight: 400;
    width: ${(props) => (props.widthContainer ? props.widthContainer : "100%")};
    display: block !important;
  }

  .buttonDownload {
    height: 20px;
    width: 20px;
    background: #b4ca00;
    color: #fff;
    font-family: Mulish;
    margin-left: 10px;
    margin-top: 10px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14),
      0px 1px 10px rgba(0, 0, 0, 0.12);
    border: 1px;
  }

  .buttonText {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .download-playlist-container {
    display: flex;
    align-items: baseline;
  }
`;

export default DownloadPlaylist;
