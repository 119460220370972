import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import { media } from "../../common/media";

const TermsModalContainer = styled(Dialog)`
  .dialog--title {
    font-weight: 400;
    font-size: 21px;
    color: rgba(0, 0, 0, 0.87);
    padding-top: unset;
  }
  .dialog--close {
    position: absolute;
    top: 8px;
    right: 8px;
    color: #9e9e9e;
    border-radius: 50% !important;
    margin-top: 10px;
  }
  .heading {
    font-size: 16px;
  }
  .secondaryHeading {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
  }
  .MuiDialogTitle-root h2 {
    padding-top: 10px;
  }
  h6 {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.8);
    ${media.desktop`
      padding: 5px 0 0 0;
    `}
    span {
      font-weight: 700;
    }
  }

  .termsModal {
    padding: 20px 0;
    margin-left: 0;
    margin-right: 0;
    h3 {
      color: #000;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    p {
      font-size: 14px;
      line-height: 100%;
      b {
        font-weight: 700;
      }
      span {
        font-size: 12px;
      }
    }
    .middle {
      background: #f1f1f1;
    }
    .table {
      border-spacing: 30px 0;
      border-collapse: separate;
      th,
      td {
        border: 0;
        text-align: center;
        vertical-align: middle;
        padding: 5px 10px;
      }
      th:first-child {
        text-align: left;
      }
      thead {
        th {
          border: 0;
          padding-bottom: 10px;
          font-size: 16px;
          width: 20%;
          font-weight: 400;
          svg {
            font-size: 14px;
            margin-left: 5px;
            cursor: pointer;
          }
        }
      }
      tbody {
        tr {
          th {
            font-weight: 400;
            height: 15px;
            &.personal {
              background: ${(props) => props.theme.colors.secondary.main};
              border: 1px solid ${(props) => props.theme.colors.secondary.main};
            }
            &.basic {
              background: ${(props) => props.theme.colors.info.main};
              border: 1px solid ${(props) => props.theme.colors.info.main};
            }
            &.super {
              background: ${(props) => props.theme.colors.warning.main};
              border: 1px solid ${(props) => props.theme.colors.warning.main};
            }
            &.custom {
              background: ${(props) => props.theme.colors.primary.main};
              border: 1px solid ${(props) => props.theme.colors.primary.main};
            }
          }
          td {
            &:nth-child(2) {
              border: 1px solid ${(props) => props.theme.colors.secondary.main};
              border-top: 0;
              border-bottom: 0;
            }
            &:nth-child(3) {
              border: 1px solid ${(props) => props.theme.colors.info.main};
              border-top: 0;
              border-bottom: 0;
            }
            &:nth-child(4) {
              border: 1px solid ${(props) => props.theme.colors.warning.main};
              border-top: 0;
              border-bottom: 0;
            }
            &:nth-child(5) {
              border: 1px solid ${(props) => props.theme.colors.primary.main};
              border-top: 0;
              border-bottom: 0;
            }
          }

          &.last {
            td:nth-child(2) {
              border-bottom: 1px solid ${(props) => props.theme.colors.secondary.main};
            }
            td:nth-child(3) {
              border-bottom: 1px solid ${(props) => props.theme.colors.info.main};
            }
            td:nth-child(4) {
              border-bottom: 1px solid ${(props) => props.theme.colors.warning.main};
            }
            td:nth-child(5) {
              border-bottom: 1px solid ${(props) => props.theme.colors.primary.main};
            }
          }
        }
      }
    }
  }
  .align-center {
    text-align: center;
  }
  .terms-bottom {
    padding-bottom: 26px;
    a {
      color: #000;
      -webkit-transition: all 0.6s ease 0s;
      transition: all 0.6s ease 0s;
      &:hover {
        color: ${(props) => props.theme.colors.primary.main}
      }
    }
    button {
      font-size: 14px;
      color: #fff;
      border: none;
      color: ${(props) => props.theme.colors.primary.main};
      min-width: 125px;
      cursor: pointer;
      margin-right: 15px;
      &.faq {
        background: ${(props) => props.theme.colors.primary.main};
        color: #fff;
      }
    }
  }

  .terms-mobile {
    padding: 20px 0 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    .MuiPaper-elevation1 {
      box-shadow: none;
      border: 0;
    }
    .MuiAccordionSummary-content {
      display: block;
    }

    .terms--collapse {
      padding: unset;
    }
    .MuiAccordionDetails-root {
      padding-left: 0;
      padding-right: 0;
    }
    table {
      font-size: 13px;
      &.personal,
      &.standard {
        th {
          background: #edaa3e;
          border: 1px solid #edaa3e;
          height: 15px;
        }
        td:nth-child(2) {
          border: 1px solid #edaa3e;
          border-top: 0;
          border-bottom: 0;
        }
        tr.last {
          td:nth-child(2) {
            border-bottom: 1px solid #edaa3e;
          }
        }
      }
      &.basic,
      &.enhanced {
        th {
          background: #ed723e;
          border: 1px solid #ed723e;
          height: 15px;
        }
        td:nth-child(2) {
          border: 1px solid #ed723e;
          border-top: 0;
          border-bottom: 0;
        }
        tr.last {
          td:nth-child(2) {
            border-bottom: 1px solid #ed723e;
          }
        }
      }
      &.super,
      &.ultra {
        th {
          background: #ef3737;
          border: 1px solid #ef3737;
          height: 15px;
        }
        td:nth-child(2) {
          border: 1px solid #ef3737;
          border-top: 0;
          border-bottom: 0;
        }
        tr.last {
          td:nth-child(2) {
            border-bottom: 1px solid #ef3737;
          }
        }
      }
      &.custom {
        th {
          background: #00a3b8;
          border: 1px solid #00a3b8;
          height: 15px;
        }
        td:nth-child(2) {
          border: 1px solid #00a3b8;
          border-top: 0;
          border-bottom: 0;
        }
        tr.last {
          td:nth-child(2) {
            border-bottom: 1px solid #00a3b8;
          }
        }
      }

      span {
        font-size: 12px;
      }
      td {
        padding-left: 0;
        border-top: 0;
        width: 50%;
        padding: 10px 2px;
        font-size: 11px;
        vertical-align: middle;
      }
      tr td:last-child {
        padding-left: 10px;
        padding-right: 10px;
      }
      td:nth-child(2) {
        text-align: center;
      }
    }
    h3 {
      color: #000;
      padding-top: 0;
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 700;
    }
  }

  @media screen and (max-width: 831.95px) {
    .dialog--title {
      width: 80%;
    }
    .MuiDialog-paper {
      margin: auto;
    }
    .MuiDialog-paperWidthSm.MuiDialog-paperScrollBody {
      max-width: calc(100% - 32px);
    }
    .MuiDialog-paperFullWidth {
      width: 100%;
    }
  }
`;

export default TermsModalContainer;
