import { createAction, handleActions } from "redux-actions";

const setActiveTab = createAction("PAGES/PROFILE/SET_ACTIVE_TABS");

const defaultState = "";

const reducer = handleActions(
  {
    [setActiveTab]: (_, action) => action.payload
  },
  defaultState
);

export { setActiveTab, reducer };
