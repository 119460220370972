import React from "react";

const FavDropdownItem = props => (
  <li id={"saved-item-" + props.jmId}>
    <div className="row">
      <div className="col-sm-5">
        <a href={"/licensing/view/" + props.jmId}>
          <img src={props.imgUrl} alt={props.title} />
        </a>
      </div>
      <div className="col-sm-7">
        <button
          type="button"
          className="remove-videos pull-right"
          onClick={() => {
            props.removeFavorite(props.jmId, props.userToken);
          }}
        >
          <span>X</span>
        </button>
        <h4>
          <a href={"/licensing/view/" + props.jmId}>{props.title}</a>
        </h4>
        <p>JV# {props.jmId}</p>
        {props.inCart ? (
          <button
            type="button"
            className="add-cart-dropdown remove-from-cart"
            style={{ fontSize: "9px" }}
            onClick={() => {
              props.removeFromCart(props.jmId, props.title, props.imgUrl);
            }}
          >
            <span>added</span> Remove from Cart
          </button>
        ) : (
          <button
            type="button"
            className="add-cart-dropdown add-to-cart"
            onClick={() => {
              props.addToCart(props.jmId, props.title, props.imgUrl);
            }}
          >
            <span>cart</span> Add to Cart
          </button>
        )}
      </div>
    </div>
  </li>
);

export default FavDropdownItem;
