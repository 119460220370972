import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import React from "react";
import { render, hydrate } from "react-dom";
import Loadable from "react-loadable";
import { Frontload } from "react-frontload";
import { injectGlobal } from "styled-components";
import App from "./App";
import SetupRedux from "./SetupRedux";
import SetupTheme from "./SetupTheme";
import { globalStyles } from "./styles/common";
import "./index.css";

injectGlobal`${globalStyles}`;

// Let's also let React Frontload explicitly know we're not rendering on the server here
const Application = (
  <SetupRedux>
    <Frontload noServerRender>
      <SetupTheme>
        <App />
      </SetupTheme>
    </Frontload>
  </SetupRedux>
);

const root = document.querySelector("#root");

if (process.env.NODE_ENV === "production") {
  // If we're running in production, we use hydrate to get fast page loads by just
  // attaching event listeners after the initial render
  Loadable.preloadReady().then(() => {
    hydrate(Application, root);
  });
} else {
  // If we're not running on the server, just render like normal
  render(Application, root);
}
