import React, { useEffect, useState } from "react";
import Display from "../../helpers/Display";
import PropTypes from "prop-types";
import { CircularProgress, Checkbox } from "@material-ui/core/";
import { useSelector } from "react-redux";
import CancelDownloadPopper from "pages/licensing/account/userPanel/components/playlist/components/CancelDownloadPopper";

function ModalListItemMultipleVideos(props) {
  const list = props.list;
  const changeHandler = props.changeHandler;
  const downloadAllVideos = useSelector((state) => state.playlists.downloadAllVideos);
  const downloadDetails = downloadAllVideos?.find((item) => item?.playlistUuid === list?.uuid);
  const downloadInProgress = downloadAllVideos?.find(
    (item) =>
      item?.playlistUuid === list?.uuid && item?.message == "Download is currently in progress."
  )
    ? true
    : false;

  const downloadCompleted = downloadAllVideos?.find(
    (item) => item?.playlistUuid === list?.uuid && item?.status == "COMPLETED"
  )
    ? true
    : false;

  useEffect(() => {
    if (props.list.changed) {
      setTimeout(props.resetSavedState, 1500, props.list);
    }
  }, [props.list.changed, downloadAllVideos]);

  const [enableConfirmation, setEnableConfirmation] = useState(false);

  const handleCancelDownloadRequest = () => {
    setEnableConfirmation(!enableConfirmation);
  };

  const handleCancelDownload = () => {
    setEnableConfirmation(false);
  };

  const handleVideoToPlaylist = () => {
    changeHandler(list);
  };

  return (
    <div
      className="video-list-popup-item"
      disabled={list?.disabled}
      id={"video-list-item-" + list?.uuid}
      onClick={() => {
        if (!downloadInProgress && !downloadCompleted) {
          changeHandler(list);
        }
      }}
    >
      <div className={`list-item flex ${list?.disableCheckBox && "list-item-disable"}`}>
        <p>{list?.name.length > 35 ? `${list?.name.substring(0, 34)} ...` : list?.name}</p>
        <div className="flex">
          <span className="video-count">
            {list?.videoCount} video
            {list?.videoCount > 1 ? "s" : ""}
          </span>
          <Display if={!list?.disabled}>
            {downloadInProgress | downloadCompleted ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px"
                }}
              >
                <Checkbox
                  readOnly
                  checked={list?.containsVideo}
                  color="primary"
                  disabled={list?.disabled}
                  className={`${list?.disableCheckBox && "list-item-disable"}`}
                  onClick={handleCancelDownloadRequest}
                />
                {enableConfirmation && (
                  <CancelDownloadPopper
                    label=""
                    description={
                      downloadInProgress
                        ? `Videos in this playlist are currently downloading. ${
                            list?.containsVideo ? "Removing" : "Adding"
                          } a video to this playlist will cancel the download.`
                        : `All videos in this playlist were previously downloaded. ${
                            list?.containsVideo ? "Removing" : "Adding"
                          } videos to this playlist will remove the download link associated to your playlist.`
                    }
                    successLabel={list?.containsVideo ? "Remove" : "Add"}
                    cancelLabel="Cancel"
                    onCancel={() => handleCancelDownload}
                    uuid={downloadDetails?.playlistUuid}
                    rightPosition
                    playlistDetails={downloadDetails}
                    handleVideoToPlaylist={handleVideoToPlaylist}
                  />
                )}
              </div>
            ) : (
              <Checkbox
                readOnly
                checked={list?.containsVideo}
                color="primary"
                disabled={list?.disabled}
                className={`${list?.disableCheckBox && "list-item-disable"}`}
              />
            )}
          </Display>

          <Display if={list?.disabled}>
            <div className="loading-playlist">
              <CircularProgress size={20} />
            </div>
          </Display>
        </div>
      </div>
    </div>
  );
}

ModalListItemMultipleVideos.propTypes = {
  list: PropTypes.object.isRequired,
  changeHandler: PropTypes.func.isRequired,
  resetSavedState: PropTypes.func.isRequired
};

export default ModalListItemMultipleVideos;
