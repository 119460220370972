import { createAction, handleActions } from "redux-actions";

const setIsMounted = createAction("PAGES/SEARCH/SET_IS_MOUNTED");

const defaultState = false;

const reducer = handleActions(
  {
    [setIsMounted]: (_, action) => action.payload
  },
  defaultState
);

export { setIsMounted, reducer };
