/**
 * @param {string} key
 * @returns {string} query parameter by key
 */
const _getQueryParameter = (key) => {
    const url = window.location.href;
    const regex = new RegExp("[?&]" + key + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    // decode first to avoid double encoding
    return encodeURIComponent(decodeURIComponent(results[2]));
};

/**
 * @returns {string} url query parameter with forUser key
 */
export default function forUser(separator = '?') {
    const forUserEmail = _getQueryParameter('forUser');
    return forUserEmail ? `${separator}forUser=${forUserEmail}` : '';
};

export const getQueryParameter = _getQueryParameter;