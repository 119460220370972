import { createAction, handleActions } from "redux-actions";

const setDownloading = createAction("PAGES/SEARCH/SET_DOWNLOADING");

const defaultState = null;

const reducer = handleActions(
  {
    [setDownloading]: (_, action) => action.payload
  },
  defaultState
);

export { setDownloading, reducer };
