import { createTheme } from "@material-ui/core/styles";

import siteDefault from "./default";

const theme = createTheme({
  typography: {
    fontFamily: ["Mulish", "sans-serif"].join(",")
  },
  breakpoints: {
    values: {
      xs: 0, // 0 - 767
      sm: 768, // 768 - 991
      md: 992, // 992 - 1279
      lg: 1280, // 1280 - 1919
      xl: 1920 // 1920 - up
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 1,
        fontSize: 14
      },
      contained: {
        backgroundColor: `#00C3CB !important`,
        color: "#FFFFFF !important",
        "&:hover": {
          backgroundColor: "#00959A !important"
        },
        "&:disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.12) !important",
          color: "rgba(0, 0, 0, 0.26) !important"
        }
      },
      outlined: {
        border: "1px solid rgba(0, 195, 203, 0.5) !important",
        color: "#00C3CB !important",
        "&:hover": {
          backgroundColor: "rgba(0, 195, 203, 0.04) !important",
          border: "1px solid rgba(0, 195, 203, 0.5)"
        },
        "&:disabled": {
          color: "rgba(0, 0, 0, 0.26) !important",
          border: "1px solid rgba(0, 0, 0, 0.12) !important"
        }
      },
      text: {
        color: "#00C3CB",
        "&:hover": {
          backgroundColor: "rgba(0, 195, 203, 0.04)"
        },
        "&:disabled": {
          color: "rgba(0, 0, 0, 0.26) !important"
        }
      }
    },
    MuiChip: {
      root: {
        borderRadius: "100px !important",
        backgroundColor: "#00C3CB !important",
        color: "#FFFFFF !important",
        "&:hover": {
          backgroundColor: "#00959A !important"
        },
        "&:active": {
          backgroundColor: "#00959A !important"
        },
        "&:focus": {
          backgroundColor: "#00959A !important"
        },
        "&:disabled": {
          backgroundColor: "#00C3CB !important",
          color: "#FFFFFF !important!important"
        }
      },
      outlined: {
        borderRadius: "100px !important",
        border: "1px solid #00C3CB  !important",
        backgroundColor: "#FFFFFF !important",
        color: "#00C3CB !important",
        "&:hover": {
          backgroundColor: "rgba(0, 195, 203, 0.04) !important",
          border: "1px solid #00C3CB !important"
        },
        "&:active": {
          backgroundColor: "rgba(0, 195, 203, 0.12) !important",
          border: "1px solid #00C3CB !important"
        },
        "&:focus": {
          backgroundColor: "rgba(0, 195, 203, 0.12) !important",
          border: "1px solid #00C3CB !important"
        },
        "&:target": {
          backgroundColor: "rgba(0, 195, 203, 0.12) !important",
          border: "1px solid #00C3CB !important"
        },
        "&:disabled": {
          backgroundColor: "#FFFFFF !important",
          border: "1px solid #00C3CB !important",
          color: "#00C3CB !important"
        }
      }
    },
    MuiSwitch: {
      colorPrimary: {
        "&$checked": {
          color: "rgb(0, 163, 184)"
        },
        "&$checked + $track": {
          backgroundColor: "#87dce2"
        }
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        "&$checked": {
          color: "rgba(0, 0, 0, 0.6)"
        }
      },
      colorPrimary: {
        "&$checked": {
          color: "rgba(0, 0, 0, 0.6)"
        }
      }
    },
    MuiInput: {
      underline: {
        "&::after": {
          borderBottomColor: "rgba(0,0,0,0.87)"
        },
        "&:hover:not(.Mui-disabled)::before": {
          borderBottomColor: "rgba(0,0,0,0.87)"
        }
      }
    },
    MuiTabs: {
      root: {
        color: "rgba(0, 0, 0, 0.6) !important",
        "&:active": {
          color: "#00C3CB !important"
        },
        "& .Mui-selected": {
          color: "#00C3CB !important"
        },
        "& .MuiTabs-indicator": {
          backgroundColor: "#00C3CB !important"
        }
      }
    },
    MuiTab: {
      root: {
        color: "rgba(0, 0, 0, 0.6) !important",
        "&:active": {
          color: "#00C3CB !important"
        }
      }
    }
  },
  site: siteDefault,
  colorFlamingo: "#00959A",
  colorAlizarinCrimson: "#E0331A",
  colors: {
    primary: {
      main: "#00C3CB",
      dark: "#00959A",
      light: "#7CDDDE",
      contrast: "#FFFFFF",
      shades4p: "rgba(0, 195, 203, 0.04)",
      shades8p: "rgba(0, 195, 203, 0.08)",
      shades12p: "rgba(0, 195, 203, 0.12)",
      shades30p: "rgba(0, 195, 203, 0.3)",
      shades30pRipple:
        "radial-gradient(36.59% 100.8% at 50% 50%, rgba(0, 195, 203, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)",
      shades50p: "rgba(0, 195, 203, 0.5)",
      darkShadeOfGray: "#222",
      black: "#000000",
      lightGray: "#f1f4f6",
      darkBlue: "#193047",
      darkGray: "#191919",
      spanishGray: "#939393",
      electricBlue: "#7CEEFF",
      charcoal: "#4a4a4a",
      shinbashi: "#59bbc6",
      rainyGrey: "#a4a4a4"
    },
    secondary: {
      main: "#CFF405",
      dark: "#B4CA00",
      light: "#E8FB98",
      contrast: "#FFFFFF",
      shades4p: "rgba(207, 244, 5, 0.04)",
      shades8p: "rgba(207, 244, 5, 0.08)",
      shades12p: "rgba(207, 244, 5, 0.12)",
      shades30p: "rgba(207, 244, 5, 0.3)",
      shades30pRipple:
        "radial-gradient(36.59% 100.8% at 50% 50%, rgba(207, 244, 5, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)",
      shades50p: "rgba(207, 244, 5, 0.5)"
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)"
    },
    error: {
      main: "#D32F2F",
      dark: "#C62828",
      light: "#EF5350",
      contrast: "#FFFFFF",
      shades4p: "rgba(211, 47, 47, 0.04)",
      shades12p: "rgba(211, 47, 47, 0.12)",
      shades30p: "rgba(211, 47, 47, 0.3)",
      shades30pRipple:
        "radial-gradient(36.59% 100.8% at 50% 50%, rgba(211, 47, 47, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)",
      shades50p: "rgba(211, 47, 47, 0.5)",
      shades160p: "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #D32F2F",
      shades190p:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #D32F2F"
    },
    warning: {
      main: "#FF8A42",
      dark: "#C65B11",
      light: "#FFBB70",
      contrast: "#FFFFFF",
      shades4p: "rgba(255, 140, 66, 0.04)",
      shades12p: "rgba(255, 140, 66, 0.12)",
      shades30p: "rgba(255, 140, 66, 0.3)",
      shades30pRipple:
        "radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 140, 66, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)",
      shades50p: "rgba(255, 140, 66, 0.5)",
      shades160p: "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #FF8C4",
      shades190p:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FF8C42"
    },
    info: {
      main: "#24578F",
      dark: "#002F61",
      light: "#5A83C0",
      contrast: "#FFFFFF",
      shades4p: "rgba(36, 87, 143, 0.04)",
      shades12p: "rgba(36, 87, 143, 0.12)",
      shades30p: "rgba(36, 87, 143, 0.3)",
      shades30pRipple:
        "radial-gradient(36.59% 100.8% at 50% 50%, rgba(36, 87, 143, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)",
      shades50p: "rgba(36, 87, 143, 0.5)",
      shades160p: "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #24578F",
      shades190p:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #24578F"
    },
    success: {
      main: "#DFFF00",
      dark: "#A9CC00",
      light: "#FFFF59",
      contrast: "#FFFFFF",
      shades4p: "rgba(223, 255, 0, 0.04)",
      shades12p: "rgba(223, 255, 0, 0.12)",
      shades30p: "rgba(223, 255, 0, 0.3)",
      shades30pRipple:
        "radial-gradient(36.59% 100.8% at 50% 50%, rgba(223, 255, 0, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)",
      shades50p: "rgba(223, 255, 0, 0.5)",
      shades160p: "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #1E4620",
      shades190p:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #DFFF00"
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.04)",
      selected: "rgba(0, 0, 0, 0.08)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      focus: "rgba(0, 0, 0, 0.12)"
    }
  }
});

export default theme;
