import {
  LOAD_VIDEOS,
  SET_DISPLAY_FILTER,
  SET_DISPLAY_MODE,
  SET_OFFSET,
  SET_TRENDING,
  SET_FETCHING,
  SET_ACQUISITIONS,
  SET_DAY_FILTER,
  SET_NO_MORE,
  RESET_VIDEOS,
  SET_HAS_NEW_VIDEO
} from "../actions/thewire";

const defaultState = {
  filter: "All Videos",
  mode: "grid",
  dayFilter: "",
  offset: 0,
  trending: false,
  fetching: false,
  noMore: false,
  hasNewVideo: false,
  acquisitions: {},
  videos: []
};

export default function thewire(state = defaultState, action) {
  const setState = (obj) => Object.assign({}, state, obj);

  switch (action.type) {
    case LOAD_VIDEOS:
      return setState({ videos: [...state.videos, ...action.payload] });
    case SET_DISPLAY_FILTER:
      return setState({ filter: action.payload });
    case SET_DISPLAY_MODE:
      return setState({ mode: action.payload });
    case SET_OFFSET:
      return setState({ offset: action.payload });
    case SET_TRENDING:
      return setState({ trending: action.payload });
    case SET_FETCHING:
      return setState({ fetching: action.payload });
    case SET_ACQUISITIONS:
      return setState({ acquisitions: action.payload });
    case SET_DAY_FILTER:
      return setState({ dayFilter: action.payload });
    case SET_NO_MORE:
      return setState({ noMore: action.payload });
    case SET_HAS_NEW_VIDEO:
      return setState({ hasNewVideo: action.payload });
    case RESET_VIDEOS:
      return setState({ videos: [] });
    default:
      return state;
  }
}
