import React from "react";
import { VideoBox } from "../components/shared";
import filter from "lodash/filter";

export default function(items, pageName) {
  let { methods, user, auth } = items;

  return items.videos.map((video, index) => {
    video.inCart = false;
    video.title = video.publishingTitle
      ? video.publishingTitle
      : video.title
        ? video.title
        : "No Title";
    video.isFav = false;

    if (items.user && pageName !== "savedVideos") {
      filter(items.user.favorites, fav => {
        if (
          fav.video.jmId === video.jmId ||
          fav.video.jmId.split("-")[0] === video.jmId
        ) {
          video.isFav = true;
        }
      });
    } else {
      video.isFav = true;
    }

    filter(items.user.cartItems, item => {
      if (item.id === video.jmId) {
        video.inCart = true;
      }
    });

    return (
      <VideoBox
        key={String(index)}
        {...user}
        {...methods}
        {...auth}
        {...video}
        pageName={pageName}
        index={index}
      />
    );
  });
}
