import { createAction, handleActions } from "redux-actions";

const setSearchTerms = createAction("PAGES/SEARCH/SET_SEARCH_TERM");

const defaultState = "";

const reducer = handleActions(
  {
    [setSearchTerms]: (_, action) => action.payload
  },
  defaultState
);

export { setSearchTerms, reducer };
