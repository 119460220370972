import {
  DOWNLOAD_ALL_VIDEOS_PLAYLISTS_FETCHING,
  DOWNLOAD_ALL_VIDEOS_PLAYLISTS_FULFILLED,
  DOWNLOAD_ALL_VIDEOS_PLAYLISTS_FAILED
} from "redux/actions/playlist";
import { downloadAllVideosPlaylistsPolling } from "./downloadAllVideosPlaylistsPolling";
import request from "superagent";
import downloadAllVideosPlaylists from "./downloadAllVideosPlaylists";

export default () => async (dispatch, getState) => {
  const { access_token } = getState().auth.user;
  const baseUrl = process.env.REACT_APP_PLAYLIST_DOWNLOAD_HOST;

  if (access_token) {

  dispatch({
    type: DOWNLOAD_ALL_VIDEOS_PLAYLISTS_FETCHING,
    payload: {
      status: "Fetching"
    }
  });

  request
    .get(`${baseUrl}/api/user/playlist/download`)
    .set("Authorization", `Bearer ${access_token}`)
    .then((response) => {
      if (response.ok) {
        dispatch({
          type: DOWNLOAD_ALL_VIDEOS_PLAYLISTS_FULFILLED,
          payload: response.body
        });

        const pollingTime = 15000;
        const condition = "Download is currently in progress.";
        dispatch(
          downloadAllVideosPlaylistsPolling(
            response.body,
            condition,
            pollingTime,
            downloadAllVideosPlaylists
          )
        );
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: DOWNLOAD_ALL_VIDEOS_PLAYLISTS_FAILED,
        payload: error
      });
    });
  }
};
