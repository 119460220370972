import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { Button, CircularProgress } from "@material-ui/core";
import onClickCancelDownloadAllVideos from "../handlers/onClickCancelDownloadAllVideos";
import Wrapper from "../styles/Popper.style";
import CancelDownloadPopperContainer from "./styles/CancelDownloadPopper.styles";

function CancelDownloadPopper(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    onCancel,
    onSuccess,
    uuid,
    label,
    description,
    successLabel,
    cancelLabel,
    rightPosition,
    playlistDetails,
    handleVideoToPlaylist
  } = props;

  const onClickSuccess = async () => {
    setLoading(true);
    await dispatch(onClickCancelDownloadAllVideos(uuid, onSuccess(), playlistDetails, true));
    if (handleVideoToPlaylist) {
      handleVideoToPlaylist();
    }
  };

  return (
    <CancelDownloadPopperContainer>
      <Wrapper
        style={{
          position: "absolute",
          top: '150px',
          ...(rightPosition && { right: 0 })
        }}
        className="MuiPaper-elevation1"
      >
        <h4>{label}</h4>
        <p id="cancel-download-popper-description">{description}</p>
        <div className="cta--wrapper">
          <Button className="cta--cancel" onClick={onCancel()} disabled={loading}>
            {cancelLabel}
          </Button>
          <Button
            variant="contained"
            className="cta--ok"
            onClick={onClickSuccess}
            disabled={loading}
          >
            {!loading ? (
              successLabel
            ) : (
              <CircularProgress className="circular--progress" size={24} />
            )}
          </Button>
        </div>
      </Wrapper>
    </CancelDownloadPopperContainer>
  );
}

CancelDownloadPopper.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  successLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onCancel: PropTypes.func,
  uuid: PropTypes.string.isRequired
};

export default CancelDownloadPopper;
