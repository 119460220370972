import downloadAllVideosPlaylists from "../services/downloadAllVideosPlaylists";
import downloadAllVideosPlaylistsDetails from "../services/downloadAllVideosPlaylistsDetails";

import {
  CANCEL_DOWNLOAD_ALL_VIDEOS_FETCHING,
  CANCEL_DOWNLOAD_ALL_VIDEOS_FULFILLED,
  REQUEST_DOWNLOAD_ALL_VIDEOS_FETCHED
} from "redux/actions/playlist";
import { setSnackbar } from "redux/actions/snackbar";

import request from "superagent";

export default (uuid, onClose, playlistDetails, disableNotification = false) =>
  async (dispatch, getState) => {
    const baseUrl = process.env.REACT_APP_PLAYLIST_DOWNLOAD_HOST;
    const { access_token } = getState().auth.user;

    onClose();

    dispatch({
      type: CANCEL_DOWNLOAD_ALL_VIDEOS_FETCHING,
      payload: {
        status: "Fetching",
        uuid
      }
    });

    request
      .delete(`${baseUrl}/api/playlist/${uuid}/download/cancel`)
      .set("Authorization", `Bearer ${access_token}`)
      .then((response) => {
        if (response.ok) {
          const downloadAllVideosFetching = getState().playlists?.downloadAllVideosFetching;
          const payload = downloadAllVideosFetching.filter((item) => item.playlistUuid !== uuid);
          dispatch({
            type: REQUEST_DOWNLOAD_ALL_VIDEOS_FETCHED,
            payload
          });

          dispatch({
            type: CANCEL_DOWNLOAD_ALL_VIDEOS_FULFILLED,
            payload: [response.body]
          });


          if (disableNotification === false) {
            dispatch(
              setSnackbar({
                open: true,
                message: "Download canceled successfully!"
              })
            );
          }

          if (playlistDetails) {
            dispatch(downloadAllVideosPlaylistsDetails(uuid));
          } else {
            dispatch(downloadAllVideosPlaylists());
          }
        }
      })
      .catch((error) => {
        console.log(error);
        const downloadAllVideosFetching = getState().playlists?.downloadAllVideosFetching;
        const payload = downloadAllVideosFetching.filter((item) => item.playlistUuid !== uuid);
        dispatch({
          type: REQUEST_DOWNLOAD_ALL_VIDEOS_FETCHED,
          payload
        });
        const errorMessage = error?.response?.text;
        if (errorMessage) {
          dispatch(
            setSnackbar({
              open: true,
              message: JSON.parse(errorMessage)?.errorMessage
            })
          );
        }
      });
  };
