import { createAction, handleActions } from "redux-actions";

const setQuerySuggestion = createAction("PAGES/SEARCH/SET_QUERY_SUGGESTION");
const appendToHistory = createAction("PAGES/SEARCH/APPEND_TO_HISTORY");

const defaultState = {
  searching: true,
  active: false,
  query: "",
  result: [],
  recentSearch: []
};

const reducer = handleActions(
  {
    [setQuerySuggestion]: (state, action) => Object.assign({}, state, action.payload),
    [appendToHistory]: (state, action) => {
      let recentSearch = state.recentSearch;
      if (!state.recentSearch.includes(action.payload) && action.payload.trim() !== "") {
        recentSearch = [action.payload, ...state.recentSearch];
      }

      return Object.assign({}, state, { recentSearch });
    }
  },
  defaultState
);

export { setQuerySuggestion, appendToHistory, reducer };
