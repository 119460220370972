import { createAction, handleActions } from "redux-actions";
import { mapValues } from "lodash";

import TONES from "misc/tones";

const setParams = createAction("PAGES/SEARCH/SET_PARAMS");

const setCategories = createAction("PAGES/SEARCH/SET_CATEGORIES");
const setTones = createAction("PAGES/SEARCH/SET_TONES");
const setClearance = createAction("PAGES/SEARCH/SET_CLEARANCE");
const setFootageType = createAction("PAGES/SEARCH/SET_FOOTAGE_TYPE");
const setIsHD = createAction("PAGES/SEARCH/SET_IS_HD");
const setOrientation = createAction("PAGES/SEARCH/SET_ORIENTATION");
const setDates = createAction("PAGES/SEARCH/SET_DATES");
const setDuration = createAction("PAGES/SEARCH/SET_DURATION");
const setSort = createAction("PAGES/SEARCH/SET_SORT");
const setDisplay = createAction("PAGES/SEARCH/SET_DISPLAY");
const setPopularity = createAction("PAGES/SEARCH/SET_POPULARITY");
const setCustomTag = createAction("PAGES/SEARCH/SET_CUSTOM_TAG");

const resetParams = createAction("PAGES/SEARCH/RESET_PARAMS");

const tones = () =>
  TONES.map((item) => ({
    name: item,
    checked: false
  }));
const footageType = () => [
  {
    name: "Dashcam",
    checked: false
  },
  {
    name: "Drone",
    checked: false
  },
  { name: "Pov", checked: false },
  { name: "Security", checked: false }
];

const defaultState = {
  categories: [],
  tones: tones(),
  popularity: [
    { name: "Viral", checked: false },
    { name: "Popular", checked: false },
    { name: "Topical", checked: false }
  ],
  clearance: [
    {
      name: "Advertising Use",
      checked: false,
      value: "CLEARED"
    },
    {
      name: "Inquire for Advertising Use",
      checked: false,
      value: "LIMITATIONS"
    },
    {
      name: "No Advertising Use",
      checked: false,
      value: "NO_AR_EDITORIAL_ONLY"
    }
  ],
  footageType: footageType(),
  customTag: [],
  isHD: null,
  orientation: [
    { name: "Vertical", checked: false },
    { name: "Horizontal", checked: false }
  ],
  dates: {
    acquiredFrom: null,
    acquiredTo: null,
    postedFrom: null,
    postedTo: null
  },
  sort: {
    signedDate: false,
    originalPublishingDate: false,
    duration: false
  },
  display: {
    asc: false,
    desc: false
  },
  duration: [0, 600]
};

const reducer = handleActions(
  {
    [setParams]: (state, action) => Object.assign({}, state, action.payload),
    [setCategories]: (state, action) => Object.assign({}, state, { categories: action.payload }),
    [setTones]: (state, action) => Object.assign({}, state, { tones: action.payload }),
    [setPopularity]: (state, action) => Object.assign({}, state, { popularity: action.payload }),
    [setClearance]: (state, action) => Object.assign({}, state, { clearance: action.payload }),
    [setFootageType]: (state, action) => Object.assign({}, state, { footageType: action.payload }),
    [setIsHD]: (state) => Object.assign({}, state, { isHD: !state.isHD }),
    [setOrientation]: (state, action) => Object.assign({}, state, { orientation: action.payload }),
    [setDates]: (state, action) => Object.assign({}, state, { dates: action.payload }),
    [setDuration]: (state, action) => Object.assign({}, state, { duration: action.payload }),
    [setCustomTag]: (state, action) => Object.assign({}, state, { customTag: action.payload }),
    [setSort]: (state, action) => {
      const change = {
        ...mapValues(defaultState.sort, () => false),
        [action.payload]: true
      };
      return Object.assign({}, state, {
        sort: change
      });
    },
    [setDisplay]: (state, action) => {
      const change = {
        ...mapValues(defaultState.display, () => false),
        [action.payload]: true
      };
      return Object.assign({}, state, {
        display: change
      });
    },
    [resetParams]: () => {
      return {
        ...defaultState,
        tones: tones(),
        footageType: footageType()
      };
    }
  },
  defaultState
);

export {
  setParams,
  setCategories,
  setTones,
  setPopularity,
  setClearance,
  setFootageType,
  setIsHD,
  setOrientation,
  setDates,
  setDuration,
  setCustomTag,
  setSort,
  setDisplay,
  resetParams,
  reducer
};
