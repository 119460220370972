import React, { Suspense, lazy, useEffect } from "react";
import { Switch, Route } from "react-router";
import ComponentLoader from "./ComponentLoader";
import { useSelector } from "react-redux";
import Analytics from "helpers/analytics";
import analytics from "../src/helpers/analytics";

const LicensingHome = lazy(() => import("./pages/licensing/home/homepage"));
const LicensingRegister = lazy(() => import("./pages/licensing/account/licenseAccount/entry"));
const LicensingView = lazy(() => import("./pages/licensing/view"));
const LicensingSearch = lazy(() => import("./pages/licensing/search"));
const LicensingCollections = lazy(() => import("./pages/licensing/collections"));
const PublishedCollections = lazy(() => import("./pages/licensing/collections/components/PublishedCollections"));
const LicensingInquiry = lazy(() => import("./pages/licensing/inquiry"));
const LicensingCheckout = lazy(() => import("./pages/licensing/checkout"));
const LicensingAccount = lazy(() => import("./pages/licensing/account/licenseAccount"));
const AccountUsage = lazy(() => import("./pages/licensing/account/videoUsage"));
const Error404 = lazy(() => import("./pages/licensing/errors/404"));
const LicensingPricing = lazy(() => import("./pages/licensing/pricing"));
const TheWire = lazy(() => import("./pages/licensing/thewire"));
const WorkWithJukin = lazy(() => import("./pages/licensing/workwithjukin"));
const YoutubeBlog = lazy(() => import("./pages/licensing/youtubeblog"));
const PrivacyPolicy = lazy(() => import("./pages/licensing/privacypolicy"));
const PrivacyPolicyCA = lazy(() => import("./pages/licensing/privacypolicyca"));
const PrivacyPolicyVA = lazy(() => import("./pages/licensing/privacypolicyva"));
const VideoSubmission = lazy(() => import("./pages/licensing/videosubmissions"));
const CookiePolicy = lazy(() => import("./pages/licensing/cookiepolicy"));
const TermsEU = lazy(() => import("./pages/licensing/termseu"));
const Terms = lazy(() => import("./pages/licensing/terms"));
const Infringement = lazy(() => import("./pages/licensing/infringement"));
const LicensingResetPassword = lazy(() => import("./pages/licensing/account/licenseAccount/ResetPassword"));
const Onboarding = lazy(() => import("./pages/licensing/onboarding"));
const FAQ = lazy(() => import("./pages/licensing/faq"));
const Env = lazy(() => import("./pages/licensing/account/admin/Env"));
const SearchMvp = lazy(() => import("./pages/licensing/mvp"));
const UserPanel = lazy(() => import("./pages/licensing/account/userPanel"));
const ViewMvp = lazy(() => import('./pages/licensing/viewmvp'))
const MakeMoneyOnYoutube = lazy(() => import('./pages/licensing/makeMoneyOnYoutube'))

const PublicRoutes = () => {
  const isProduction = process.env.REACT_APP_FUNK_URL === "https://www.jukinmedia.com";
  const searchPage = window.location.pathname.startsWith("/licensing/search");
  const searchTerms = useSelector((state) => state.pages.search.searchTerms);
  const search = useSelector((state) => state.pages.search);
  const isAlgoliaSearch = (localStorage.getItem('algoliaSearch') === 'true')
  const siteSearchLabel = isAlgoliaSearch ? 'keyword search' : 'AI-semantic search';

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden && searchPage) {
        Analytics.track("search_exit", {
          category: "LP_search",
          label: "LP_search_exits",
          search_type: searchTerms === "" ? "null search" : "keyword search",
          search_keyword: searchTerms,
          total_number_of_search_results: search?.pagination?.totalResults
        });
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [searchPage, searchTerms, search]);


  useEffect(() => {
  analytics.on("*", ({ payload }) => {
    if (!payload.properties) {
      payload.properties = { site_search: siteSearchLabel};
    }

    payload.properties.site_search = siteSearchLabel;
  });
});

  return (
    <Suspense fallback={<ComponentLoader />}>
      <Switch>
        <Route path="/licensing" component={LicensingHome} exact />
        <Route path="/licensing/register" component={LicensingRegister} />
        <Route path="/licensing/login" component={LicensingRegister} />
        <Route path="/licensing/reset-password" component={LicensingResetPassword} exact />
        <Route path="/licensing/reset-password/:id" component={LicensingResetPassword} />
        <Route path="/licensing/view/:videoId" component={process.env.REACT_APP_ALGOLIA_ENABLE === 'true' ? ViewMvp: LicensingView} />
        <Route path="/licensing/search" component={process.env.REACT_APP_ALGOLIA_ENABLE === 'true' ? SearchMvp : LicensingSearch} />
        <Route path="/licensing/inquiry" component={LicensingInquiry} />
        <Route path="/licensing/checkout/:playlistUUID?" component={LicensingCheckout} />
        <Route path="/licensing/pricing" component={LicensingPricing} />
        <Route path="/licensing/collections/:collections" component={LicensingCollections} />
        <Route path="/licensing/collections" component={PublishedCollections} />
        <Route path="/licensing/account" component={LicensingAccount} exact/>
        
        <Route path="/licensing/account/lists/:playlistId/:folderId?" component={UserPanel} />
        <Route path="/licensing/account/lists" component={UserPanel} exact/>
        <Route path="/licensing/account/usage" component={AccountUsage} />
        <Route path="/licensing/thewire" component={TheWire} />
        <Route path="/licensing/privacy-policy" component={PrivacyPolicy} />
        <Route path="/licensing/privacy-policy-ca" component={PrivacyPolicyCA} />
        <Route path="/licensing/privacy-policy-va" component={PrivacyPolicyVA} />
        <Route path="/licensing/video-submissions" component={VideoSubmission} />
        <Route path="/licensing/terms-eu" component={TermsEU} />
        <Route path="/licensing/terms" component={Terms} />
        <Route path="/licensing/onboarding" component={Onboarding} exact/>
        <Route path="/licensing/reported-infringement" component={Infringement} />
        <Route path="/licensing/faq/:faqType?" component={FAQ} />
        <Route path="/licensing/make-money-on-youtube" component={MakeMoneyOnYoutube} />
        <Route path="/licensing/account/history" component={UserPanel} />
        <Route path="/licensing/account/profile" component={UserPanel} />
        <Route path="/licensing/account/watchlist" component={UserPanel} />

        <Route path="/why-work-with-jukin" component={WorkWithJukin} />
        <Route path="/licensing/why-work-with-jukin" component={WorkWithJukin} />

        <Route path="/licensing/the-truth-about-how-much-money-youtube-videos-really-make-" component={YoutubeBlog} />
    
        {!isProduction && <Route path="/licensing/env" component={Env} />}

        <Route path="*" component={Error404} />
      </Switch>
    </Suspense>
  );
};

export default PublicRoutes;