import { createAction, handleActions } from "redux-actions";

const setPagination = createAction("PAGES/SEARCH/SET_PAGINATION");

const defaultState = {
  totalPages: 0,
  sizePerPage: 40,
  currentPage: 1,
  totalResults: 0
};

const reducer = handleActions(
  {
    [setPagination]: (state, action) => Object.assign({}, state, action.payload)
  },
  defaultState
);

export { setPagination, reducer };
