import request from "superagent";
import { Mixpanel } from "../../components/shared/Mixpanel";
import Analytics from "../../helpers/analytics"
export const ONBOARDING_STEP = "JMLP/ONBOARDING_STEP";
export const ONBOARDING_CREATE_PLAYLIST = "JMLP/ONBOARDING_CREATE_PLAYLIST";
export const ONBOARDING_UPDATE_PROJECT_TYPE =
  "JMLP/ONBOARDING_UPDATE_PROJECT_TYPE";
export const ONBOARDING_ERROR = "JMLP/ONBOARDING_ERROR";
export const ONBOARDING_CREATED = "JMLP/ONBOARDING_CREATED";

let token = null;

function getToken(state) {
  return state.auth.user.access_token;
}

function getUserProfile(state) {
  return state.auth.userProfile.profile;
}

function getUserCartItem(state) {
  return state.user.cartItems;
}

if (localStorage.getItem("persist:root") !== null) {
  let currentState = JSON.parse(localStorage.getItem("persist:root"));
  let auth = JSON.parse(currentState.auth);
  token = auth.user.access_token ? auth.user.access_token : null;
}

export function onboardingStep(step) {
  return (dispatch, getState) => {
    dispatch({
      type: ONBOARDING_STEP,
      data: {
        onboardingStep: step,
        profile: getUserProfile(getState()),
      },
    });
  };
}

export function addingCollections(collections) {
  return (dispatch, getState) => {
    let profile = getUserProfile(getState());
    Mixpanel.people.set({
      "Collections List": collections.toString(),
    });
    updateUserData({
      userId: profile.id,
      onboardingCollections: collections.toString(),
      onboardingStepCompleted: 2,
    });
  };
}

export function createOnboardingPlaylist(videos) {
  const addToListVideo = localStorage.getItem("onBoardingAddToList");
  const downloadVideo = localStorage.getItem("onBoardingDownload");
  if (addToListVideo !== null && !videos.includes(addToListVideo)) {
    videos.push(addToListVideo);
  }
  if (downloadVideo !== null && !videos.includes(downloadVideo)) {
    videos.push(downloadVideo);
  }

  return (dispatch, getState) => {
    dispatch({
      type: ONBOARDING_CREATE_PLAYLIST,
      data: {
        onboardingLoading: true,
      },
    });
    token = getToken(getState());
    let profile = getUserProfile(getState());
    const cartItems = getUserCartItem(getState());
    if (cartItems.length > 0) {
      // eslint-disable-next-line
      cartItems.map((item) => {
        if (!videos.includes(item.id)) {
          videos.push(item.id);
        }
      });
    }
    updateUserData({
      userId: profile.id,
      onboardingVideo: videos.toString(),
      onboardingStepCompleted: 3,
    });
    request
      .post(`/api/user/licensing/lists`)
      .set("Authorization", `Bearer ${token}`)
      .set("Content-Type", "application/json")
      .send({
        name: "My First Playlist",
      })
      .then((res, err) => {
        let uuid = res.body.uuid;
        Mixpanel.track("Onboarding Playlist Created", {
          $email: profile.email,
        });
        Analytics.track("Onboarding Playlist Created", {
          category: "LP-Onboarding",
          label: profile.email,
        });
        // eslint-disable-next-line
        let response = videos.map((item) => {
          return new Promise((resolve) => {
            request
              .post(`/api/user/licensing/lists/${uuid}/videos/${item}`)
              .set("Authorization", `Bearer ${token}`)
              .then((res, err) => {
                resolve();
              })
              .catch((error) => {
                dispatch({
                  type: ONBOARDING_ERROR,
                  data: {
                    onboardingLoading: false,
                    onbordingError: true,
                  },
                });
              });
          });
        });
        Promise.all(response).then(() => {
          dispatch({
            type: ONBOARDING_CREATE_PLAYLIST,
            data: {
              onboardingUuid: uuid,
              onboardingLoading: false,
            },
          });
          updateUserData({
            userId: profile.id,
            onboardingIsFinish: true,
            onboardingStepCompleted: 4,
          });
          Mixpanel.track("Onboarding Videos Added to Playlist", {
            $email: profile.email,
            "List of Videos": videos.toString(),
          });
          Analytics.track("Onboarding Videos Added to Playlist", {
            category: "LP-Onboarding",
            label: profile.email,
          });
        });
      })
      .catch((error) => {
        dispatch({
          type: ONBOARDING_ERROR,
          data: {
            onboardingLoading: false,
            onbordingError: true,
          },
        });
      });
  };
}

export function createUserData() {
  return (dispatch, getState) => {
    token = getToken(getState());
    let profile = getUserProfile(getState());
    const { onboardingCreated } = getState().onboarding
    if (!onboardingCreated) {
      request
        .post(`${process.env.REACT_APP_USER_DATA_API}/create`)
        .set("Content-Type", "application/json")
        .set("Authorization", token)
        .send({
          userId: profile.id,
        })
        .then(() => {
          if (profile.purpose && profile.purpose !== "") {
            updateUserData({
              userId: profile.id,
              onboardingProjectType: profile.purpose
            });
          }

          dispatch({ type: ONBOARDING_CREATED, payload: true });
        });
    }
  };
}

function updateUserData(data) {
  request
    .post(`${process.env.REACT_APP_USER_DATA_API}/update`)
    .set("Content-Type", "application/json")
    .set("Authorization", token)
    .send(data)
    .then();
}
