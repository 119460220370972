import {
  ONBOARDING_CREATE_PLAYLIST,
  ONBOARDING_STEP,
  ONBOARDING_ERROR,
  ONBOARDING_CREATED
} from "../actions/onboarding";
import { Mixpanel } from "../../components/shared/Mixpanel";
import Analytics from "../../helpers/analytics"

const initialState = {
  onboardingUuid: "",
  onboardingLoading: false,
  onboardingStep: 1,
  onbordingError: false,
  onboardingCreated: false
};

export default function onboarding(state = initialState, action) {
  const setState = obj => ({ ...state, ...obj }); //Object.assign({}, state, obj)
  switch (action.type) {
    case ONBOARDING_CREATE_PLAYLIST: {
      return setState(action.data);
    }

    case ONBOARDING_STEP: {
      Mixpanel.track(`Onboarding Step${action.data.onboardingStep} Completed`, {
        $email: action.data.profile.username,
        $company: action.data.profile.company
      });
      Analytics.track(`Onboarding Step${action.data.onboardingStep} Completed`, {
        category: "LP-Onboarding",
        label: action.data.profile.username,
      });
      return setState(action.data);
    }

    case ONBOARDING_ERROR: {
      return setState(action.data);
    }
    case ONBOARDING_CREATED:
      return setState({ onboardingCreated: action.payload });
    default:
      return state;
  }
}
