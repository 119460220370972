import React from "react";

import {
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useWindow from "hooks/useWindow";
import Analytics from "helpers/analytics";

import DigitalTermsDialogWrapper from "./styles/TermsModal.style";

function DigitalTermsModal(props) {
  const { isMobile } = useWindow(991);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const toggle = () => {
    props.toggleDigitalTermsModal(false);
  };

  return (
    <DigitalTermsDialogWrapper
      open={props.user.digitalTermsModalIsOpen || false}
      className="terms-modal"
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle>
        <Typography className="dialog--title">Digital Publishing License</Typography>
        <IconButton onClick={toggle} className="dialog--close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <h6>For editorial use including YouTube, social media, blogs & educational content.</h6>
        <h6>
          <span>Not</span> for use in advertisements, commercial marketing, sponsored content or
          broadcast TV.
        </h6>
        {!isMobile ? (
          <div>
            <div className="termsModal">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" />
                    <th scope="col">Personal</th>
                    <th scope="col">Basic Pro</th>
                    <th scope="col">Super Pro</th>
                    <th scope="col">Custom</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" />
                    <th scope="row" className="personal" />
                    <th scope="row" className="basic" />
                    <th scope="row" className="super" />
                    <th scope="row" className="custom" />
                  </tr>
                  <tr>
                    <th scope="row" />
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <th scope="row">Price</th>
                    <td>
                      <p>$49 per video</p>
                    </td>
                    <td>
                      <p>$149 per video</p>
                    </td>
                    <td>
                      <p>$299 per video</p>
                    </td>
                    <td>
                      <p>Inquire</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Monetization Allowed</th>
                    <td>
                      <p>No</p>
                    </td>
                    <td>
                      <p>Yes</p>
                    </td>
                    <td>
                      <p>Yes</p>
                    </td>
                    <td>
                      <p>Yes</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Watermark Free</th>
                    <td>
                      <p>No</p>
                    </td>
                    <td>
                      <p>Yes</p>
                    </td>
                    <td>
                      <p>Yes</p>
                    </td>
                    <td>
                      <p>Yes</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"># Pages / Channels</th>
                    <td>
                      <p>1</p>
                    </td>
                    <td>
                      <p>3</p>
                    </td>
                    <td>
                      <p>Unlimited</p>
                    </td>
                    <td>
                      <p>Unlimited</p>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">Maximum Audience Size</th>
                    <td>
                      <p>5 million</p>
                    </td>
                    <td>
                      <p>5 million</p>
                    </td>
                    <td>
                      <p>Unlimited</p>
                    </td>
                    <td>
                      <p>Unlimited</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"># Posts per Channel</th>
                    <td>
                      <p>1</p>
                    </td>
                    <td>
                      <p>1</p>
                    </td>
                    <td>
                      <p>1</p>
                    </td>
                    <td>
                      <p>Unlimited</p>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row"># Brands</th>
                    <td>
                      <p>1</p>
                    </td>
                    <td>
                      <p>1</p>
                    </td>
                    <td>
                      <p>Unlimited</p>
                    </td>
                    <td>
                      <p>Unlimited</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Distribution</th>
                    <td>
                      <p>Social & Digital</p>
                    </td>
                    <td>
                      <p>Social & Digital</p>
                    </td>
                    <td>
                      <p>Social & Digital</p>
                    </td>
                    <td>
                      <p>Broadcast, Social, Digital, OTT & OOH</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Compilation Rights</th>
                    <td>
                      <p>No</p>
                    </td>
                    <td>
                      <p>No</p>
                    </td>
                    <td>
                      <p>No</p>
                    </td>
                    <td>
                      <p>No</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Legal Indemnification</th>
                    <td>
                      <p>No</p>
                    </td>
                    <td>
                      <p>No</p>
                    </td>
                    <td>
                      <p>No</p>
                    </td>
                    <td>
                      <p>Yes</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Custom Legal Terms</th>
                    <td>
                      <p>No</p>
                    </td>
                    <td>
                      <p>No</p>
                    </td>
                    <td>
                      <p>No</p>
                    </td>
                    <td>
                      <p>Yes</p>
                    </td>
                  </tr>
                  <tr className="last">
                    <th scope="row">Dedicated Account Manager</th>
                    <td>
                      <p>No</p>
                    </td>
                    <td>
                      <p>No</p>
                    </td>
                    <td>
                      <p>No</p>
                    </td>
                    <td>
                      <p>Yes</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="terms-mobile">
            <Accordion expanded={expanded === "personal"} onChange={handleChange("personal")}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} className="terms--collapse">
                <Typography className="heading">Personal</Typography>
                <Typography className="secondaryHeading">For personal or education use.</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <table className="table table-sm personal">
                  <thead>
                    <tr>
                      <th scope="row" colSpan="2" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td />
                      <td />
                    </tr>
                    <tr>
                      <td>Price</td>
                      <td>$49 per video</td>
                    </tr>
                    <tr>
                      <td>Monetization Allowed</td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <td>Watermark Free</td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <td># Pages / Channels</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td>Maximum Audience Size</td>
                      <td>5 million</td>
                    </tr>

                    <tr>
                      <td># Posts per Channel</td>
                      <td>1</td>
                    </tr>

                    <tr>
                      <td># Brands</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td>Distribution</td>
                      <td>Social & Digital</td>
                    </tr>
                    <tr>
                      <td>Compilation Rights</td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <td>Legal Indemnification</td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <td>Custom Legal Terms</td>
                      <td>No</td>
                    </tr>
                    <tr className="last">
                      <td>Dedicated Account Manager</td>
                      <td>No</td>
                    </tr>
                  </tbody>
                </table>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === "basic"} onChange={handleChange("basic")}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} className="terms--collapse">
                <Typography className="heading">Basic Pro</Typography>
                <Typography className="secondaryHeading">
                  For content creators with growing audiences.
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <table className="table table-sm basic">
                  <thead>
                    <tr>
                      <th scope="row" colSpan="2" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td />
                      <td />
                    </tr>
                    <tr>
                      <td>Price</td>
                      <td>$99 per video</td>
                    </tr>
                    <tr>
                      <td>Monetization Allowed</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>Watermark Free</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td># Pages / Channels</td>
                      <td>3</td>
                    </tr>
                    <tr>
                      <td>Maximum Audience Size</td>
                      <td>5 million</td>
                    </tr>

                    <tr>
                      <td># Posts per Channel</td>
                      <td>1</td>
                    </tr>

                    <tr>
                      <td># Brands</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td>Distribution</td>
                      <td>Social & Digital</td>
                    </tr>
                    <tr>
                      <td>Compilation Rights</td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <td>Legal Indemnification</td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <td>Custom Legal Terms</td>
                      <td>No</td>
                    </tr>
                    <tr className="last">
                      <td>Dedicated Account Manager</td>
                      <td>No</td>
                    </tr>
                  </tbody>
                </table>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === "super"} onChange={handleChange("super")}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} className="terms--collapse">
                <Typography className="heading">Super Pro</Typography>
                <Typography className="secondaryHeading">
                  For networks or publishers with large followings.
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <table className="table table-sm super">
                  <thead>
                    <tr>
                      <th scope="row" colSpan="2" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td />
                      <td />
                    </tr>
                    <tr>
                      <td>Price</td>
                      <td>$299 per video</td>
                    </tr>
                    <tr>
                      <td>Monetization Allowed</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>Watermark Free</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td># Pages / Channels</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td>Maximum Audience Size</td>
                      <td>Unlimited</td>
                    </tr>

                    <tr>
                      <td># Posts per Channel</td>
                      <td>1</td>
                    </tr>

                    <tr>
                      <td># Brands</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td>Distribution</td>
                      <td>Social & Digital</td>
                    </tr>
                    <tr>
                      <td>Compilation Rights</td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <td>Legal Indemnification</td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <td>Custom Legal Terms</td>
                      <td>No</td>
                    </tr>
                    <tr className="last">
                      <td>Dedicated Account Manager</td>
                      <td>No</td>
                    </tr>
                  </tbody>
                </table>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === "custom"} onChange={handleChange("custom")}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} className="terms--collapse">
                <Typography className="heading">Custom</Typography>
                <Typography className="secondaryHeading">
                  For custom rights packages &/or discovery services.
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <table className="table table-sm custom">
                  <thead>
                    <tr>
                      <th scope="row" colSpan="2" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td />
                      <td />
                    </tr>
                    <tr>
                      <td>Price</td>
                      <td>Inquire</td>
                    </tr>
                    <tr>
                      <td>Monetization Allowed</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>Watermark Free</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td># Pages / Channels</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td>Maximum Audience Size</td>
                      <td>Unlimited</td>
                    </tr>

                    <tr>
                      <td># Posts per Channel</td>
                      <td>Unlimited</td>
                    </tr>

                    <tr>
                      <td># Brands</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td>Distribution</td>
                      <td>Broadcast, Social, Digital, OTT & OOH</td>
                    </tr>
                    <tr>
                      <td>Compilation Rights</td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <td>Legal Indemnification</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>Custom Legal Terms</td>
                      <td>Yes</td>
                    </tr>
                    <tr className="last">
                      <td>Dedicated Account Manager</td>
                      <td>Yes</td>
                    </tr>
                  </tbody>
                </table>
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </DialogContent>
      <DialogActions className="terms-bottom">
        <a
          href="/lp-assets/pdf/v2/JukinMarketplaceTOS6.30.21.pdf"
          target="_blank"
          onClick={() => {
            Analytics.track("Digital Terms Modal", {
              category: "LP-VideoDetail",
              label: "View Full Terms"
            });
          }}
        >
          <Button>Full Terms</Button>
        </a>
        <a
          href="https://jukin-media-faq.groovehq.com/help"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            Analytics.track("Digital Terms Modal", {
              category: "LP-VideoDetail",
              label: "View FAQ"
            });
          }}
        >
          <Button variant="contained" className="faq">
            FAQ
          </Button>
        </a>
      </DialogActions>
    </DigitalTermsDialogWrapper>
  );
}

export default DigitalTermsModal;
