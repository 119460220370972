import { createAction, handleActions } from "redux-actions";

const setShowFilter = createAction("PAGES/SEARCH/SET_SHOW_FILTER");

const defaultState = window.innerWidth >= 767 ? true : false;

const reducer = handleActions(
  {
    [setShowFilter]: (state) => !state
  },
  defaultState
);

export { setShowFilter, reducer };
