export const downloadAllVideosPlaylistsPolling =
  (array, condition, pollTime, pollFunction) => async (dispatch) => {
    if (window.downloadAllVideosPolling) {
      clearTimeout(window.downloadAllVideosPolling);
    }
    window.downloadAllVideosPolling = setTimeout(() => {
      if (
        !array.find((item) => item.message === condition) ||
        window.location.href.indexOf("/lists") === -1
      ) {
        clearTimeout(window.downloadAllVideosPolling);
      } else {
        dispatch(pollFunction());
      }
    }, pollTime);
  };

export const downloadAllVideosPlaylistsDetailsPolling =
  (array, condition, pollTime, pollFunction, uuid) => async (dispatch) => {
    if (window.downloadAllVideosPolling) {
      clearTimeout(window.downloadAllVideosPolling);
    }
    window.downloadAllVideosPolling = setTimeout(() => {
      if (
        !array.find((item) => item.message === condition) ||
        window.location.href.indexOf("/lists") === -1
      ) {
        clearTimeout(window.downloadAllVideosPolling);
      } else {
        dispatch(pollFunction(uuid));
      }
    }, pollTime);
  };

  
  export const downloadAllVideosPollingClean = (condition) => {
    if (window.downloadAllVideosPolling && condition === 0) {
      clearTimeout(window.downloadAllVideosPolling);
    }
  };
