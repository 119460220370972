import {
    VIDEO_LOADING,
    SUBMIT_PROCESSING,
    VIDEOS,
    MONTHS,
    SUBMIT_VIDEOS,
    SAVE_VIDEOS,
    CHANGE_USED_SELECTED,
    CHANGE_VIDEO_SELECTED,
    CHANGE_YEAR,
    CHANGE_MONTH
} from '../actions/usage';

const initialState = {
    years:[],
    currentYear: null,
    currentMonth: null,
    videos: [],
    usedSelected: true,
    loading: true,
    note: "",
    declareDate: null,
    submissionInProcess:false,
    fields: []
};

export default function usage(state = initialState, action) {
    const setState = obj => ({...state, ...obj});
    switch(action.type) {
        case VIDEO_LOADING:
            return setState({loading:true});
        case SUBMIT_PROCESSING:
            return setState({submissionInProcess:action.data.processing});
        case SAVE_VIDEOS:{
            const videos = state.videos.map((video) => {
                return {...video, ...{tempUsed: video.used}};
            });
            return setState({
                videos: videos
            });
        }
        case MONTHS:
            return setState({
                years: action.data.years,
                currentYear: action.data.currentYear,
                currentMonth: action.data.currentMonth
            });
        case VIDEOS:
            return setState({
                videos: action.data.videos,
                usedSelected: action.data.usedSelected,
                note: action.data.note,
                declareDate: action.data.declareDate,
                loading: action.data.loading
            });
        case SUBMIT_VIDEOS:
            return setState({
                note: action.data.note,
                declareDate: action.data.declareDate,
                submissionInProcess:false
            });
        case CHANGE_YEAR:
            return setState({
                currentYear: action.data.currentYear,
                currentMonth: action.data.currentMonth
            });
        case CHANGE_MONTH:
            return setState({
                currentMonth: action.data.currentMonth
            });
        case CHANGE_VIDEO_SELECTED: {
            const videos = state.videos.map((video) => {
                if(video.videoId === action.data.item.videoId){
                    return {...video, ...{used:action.data.value}};
                }
                return video;
            });
            let allSelected = videos.every((item) => { return item.used;});
            return setState({
                usedSelected: allSelected,
                videos: videos
            });
        }
        case CHANGE_USED_SELECTED: {
            const videos = state.videos.map((video) => {
                return {...video, ...{used:action.data.usedSelected}}
            });
            return setState({
                usedSelected: action.data.usedSelected,
                videos: videos
            });
        }
        default:
            return state;
    }
}