import authActions from "../actions/auth";
import Cookies from "js-cookie";
import { Mixpanel } from "../../components/shared/Mixpanel";
import Analytics from "../../helpers/analytics"
const {
  CLEAR_AUTH,
  AUTH_PENDING_UPDATE,
  LOGIN_REJECTED,
  LOGIN_FULFILLED,
  LOGOUT_FULFILLED,
  LOGOUT_REJECTED,
  VALIDATE_FULFILLED,
  VALIDATE_REJECTED,
  GET_USER_PROFILE,
  REGISTER_FULFILLED,
  REGISTER_REJECTED,
  RESET_ERROR,
  SET_LOGGING_OUT
} = authActions;

const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
const currentDomain = isLocalhost ? "localhost" : ".jukinmedia.com";

const initialState = {
  pending: false,
  error: false,
  errorCode: null,
  loggedIn: false,
  user: {},
  userProfile: {
    agreements: [],
    profile: {},
    fetching: true
  },
  validateTokenError: false,
  registered: false,
  loggingOut: false
};

export default function authReducer(state = initialState, action) {
  const setState = obj => ({ ...state, ...obj });

  switch (action.type) {
    case CLEAR_AUTH:
      return setState(initialState);
    case AUTH_PENDING_UPDATE:
      return setState({
        pending: action.payload.pending,
        validateTokenError: false
      });
    case LOGIN_REJECTED:
      return setState({
        error: true,
        loggedIn: false
      });
    case LOGIN_FULFILLED:
      Cookies.set(window._cookie, action.payload.user_session.access_token, {
        expires: 14,
        path: "/",
        domain: currentDomain
      });
      return setState({
        error: false,
        user: action.payload.user_session,
        loggedIn: true
      });
    case LOGOUT_FULFILLED:
      Cookies.remove(window._cookie, { path: "/", domain: currentDomain});
      Mixpanel.track("Sign Out Successfully");
      return setState({
        loggedIn: false,
        user: {},
        userProfile: {},
        registered: false
      });
    case LOGOUT_REJECTED:
      return setState({
        error: true
      });
    case GET_USER_PROFILE:
      let userProfile = action.payload.profile;
      const insider = state.user.roles && state.user.roles.includes("ROLE_INSIDER") ? true : false;
      Mixpanel.identify(userProfile.profile && (userProfile.profile.id));
      Mixpanel.people.set({
        $email: userProfile.profile && (userProfile.profile.email),
        $first_name:
          userProfile.profile && userProfile.profile.firstName !== null
            ? userProfile.profile.firstName
            : "N/A",
        $last_name:
          userProfile.profile && userProfile.profile.lastName !== null
            ? userProfile.profile.lastName
            : "N/A",
        $company:
          userProfile.profile && userProfile.profile.company !== null
            ? userProfile.profile.company
            : "N/A",
        isInsider: insider,
        hasAgreement: userProfile.profile && userProfile.agreements.length > 0 ? true : false
      });
      return setState({
        userProfile: {
          ...action.payload.profile,
          fetching: false
        }
      });
    case VALIDATE_REJECTED:
      return setState({
        validateTokenError: true,
        pending: false
      });
    case VALIDATE_FULFILLED:
      return setState({
        validateTokenError: false
      });
    case REGISTER_FULFILLED:
      Cookies.set(
        window._cookie,
        action.payload.userData.authenticationDetails.access_token,
        {
          expires: 14,
          path: "/"
        }
      );
      Analytics.track("Sign Up Successfully", {
        category: "LP-Register",
        label: action.payload.userData.authenticationDetails.username,
      });
      return setState({
        error: false,
        user: action.payload.userData.authenticationDetails,
        loggedIn: true,
        registered: true
      });
    case REGISTER_REJECTED:
      return setState({
        registered: false,
        error: true,
        errorCode: action.payload.error
      });
    case RESET_ERROR:
      return setState({
        registered: false,
        error: false,
        errorCode: null
      });
    case SET_LOGGING_OUT:
      return setState({ loggingOut: action.payload });
    default:
      return state;
  }
}
