import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "js-cookie";
import * as $ from "jquery";
import Modal from "react-modal";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "react-toastify/dist/ReactToastify.css";
import authActions from "./redux/actions/auth";
import PublicRoutes from "./routes";
import Snackbar from "./components/shared/Snackbar";
import { closeSnackbar } from "./redux/actions/snackbar";
import { getEnvironmentFromUrl } from "helpers/getEnvironmentFromUrl";

const { clearAuth, validateTokenRequest } = authActions;
const isProduction = process.env.REACT_APP_FUNK_URL === "https://www.jukinmedia.com";
window.partnerDashboard = isProduction
  ? "https://partner.jukinmedia.com"
  : `https://partner-${getEnvironmentFromUrl()}.jukinmedia.com`;
window.jQuery = window.$ = $;
window._cookie = isProduction ? "_sid" : "_sid_qa";

class App extends Component {
  componentDidMount() {
    const _sid = Cookies.get(window._cookie);
    const auth = this.props.auth;

    if (_sid) {
      this.props.validateTokenRequest(_sid);
    } else {
      this.props.clearAuth();
    }

    Modal.setAppElement("#app");
    if (this.props.user.cartItems && this.props.user.cartItems.length > 0) {
      if (
        this.props.user.cartItems[0].variantId === "cartInquiry" ||
        this.props.user.cartItems[0].variantId === undefined
      ) {
        this.props.user.shopifyReady = false;
      } else {
        this.props.user.shopifyReady = true;
      }
    }

    if (auth.validateTokenError) {
      this.notifyTokenExpired();
      Cookies.remove(window._cookie, { path: "" });
    }

  }

  notifyTokenExpired = () => {
    const _sid = Cookies.get(window._cookie);
    Cookies.remove(_sid);
    toast.error("Your session has expired. Please login again!", {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  render() {
    const { snackbar, closeSnackbar } = this.props;
    return (
      <div className="App" id="app">
        <PublicRoutes />
        <ToastContainer />
        <Snackbar snackbar={snackbar} onClose={closeSnackbar} />
      </div>
    );
  }
}

App.propTypes = {
  auth: PropTypes.shape().isRequired,
  clearAuth: PropTypes.func.isRequired,
  validateTokenRequest: PropTypes.func.isRequired
};

const mapStateToProps = ({ auth, user, snackbar }) => ({
  auth,
  user,
  snackbar
});

const mapDispatchToProps = (dispatch) => ({
  clearAuth: () => dispatch(clearAuth()),
  validateTokenRequest: (token) => dispatch(validateTokenRequest(token)),
  closeSnackbar: () => dispatch(closeSnackbar())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
