import request from "superagent";

const DEFAULT_MSG = "Not Specified";
export const inquiryEmail = inquiry => {
  const video = inquiry.videos[0];
  let cart = [];
  inquiry.videos.map(item => {
    return cart.push(item.jmId);
  });

  let obj = {
    exclusiveUntil: DEFAULT_MSG,
    mediaUsageAdvertisement: inquiry.mediaUsageAdvertisement,
    mediaUsageDigital: inquiry.mediaUsageDigital,
    mediaUsageNonProfit: inquiry.mediaUsageNonProfit,
    mediaUsageTvFilm: inquiry.mediaUsageTvFilm,
    companyName: inquiry.companyName,
    firstName: inquiry.firstName,
    lastName: inquiry.lastName,
    email: inquiry.email,
    intent: inquiry.intent,
    phoneNumber: inquiry.phoneNumber,
    showTitleName: inquiry.showTitleName,
    country: inquiry.country,
    cart: cart
  };

  if (video) {
    obj = Object.assign(obj, {
      exclusiveUntil: video.exclusiveUntil
    });
  }

  request
    .post("/api/mail/licensing")
    .send(obj)
    .end((err, res) => {
      if (!res.ok) {
      }
    });
};
