import { createAction, handleActions } from "redux-actions";

const setFolderPlaylistCreate = createAction("PAGES/PLAYLIST/SET_FOLDER_PLAYLIST_CREATE");
const setDeletePlaylistDialog = createAction("PAGES/PLAYLIST/SET_DELETE_PLAYLIST_DIALOG");
const setRemovePlaylistDialog = createAction("PAGES/PLAYLIST/SET_REMOVE_PLAYLIST_DIALOG");
const setSharePlaylistDialog = createAction("PAGES/PLAYLIST/SET_SHARE_PLAYLIST_DIALOG");
const setPlaylistDescription = createAction("PAGES/PLAYLIST/SET_PLAYLIST_DESCRIPTION");

const setDeleteFolderDialog = createAction("PAGES/PLAYLIST/SET_DELETE_FOLDER_DIALOG");
const setFolderDescription = createAction("PAGES/PLAYLIST/SET_Folder_DESCRIPTION");

// const setFolderDialog = createAction("PAGES/PLAYLIST/SET_FOLDER_DIALOG");
const setRemoveVideoDialog = createAction("PAGES/PLAYLIST/SET_REMOVE_VIDEO_DIALOG");

const setDialogError = createAction("PAGES/PLAYLIST/SET_DIALOG_ERROR");
const resetDialog = createAction("PAGES/PLAYLIST/RESET_DIALOG");

const defaultState = {
  playlist: {
    create: {
      open: false,
      uuid: null,
      loading: false,
      value: ""
    },
    delete: {
      open: false,
      uuid: null,
      loading: false
    },
    remove: {
      open: false,
      uuid: null,
      loading: false
    },
    share: {
      open: false,
      uuid: null,
      loading: false
    },
    description: {
      open: false,
      uuid: null,
      loading: false,
      value: ""
    }
  },
  folder: {
    delete: {
      open: false,
      uuid: null,
      loading: false
    },
    description: {
      open: false,
      uuid: null,
      loading: false,
      value: ""
    }
  },
  video: {
    delete: false,
    update: false,
    remove: {
      open: false,
      uuid: null,
      item: null
    }
  },
  errorMessage: ""
};

const setState = (state, obj) => Object.assign({}, state, obj);

const reducer = handleActions(
  {
    [setFolderPlaylistCreate]: (state, action) =>
      setState(state, {
        playlist: setState(state.playlist, {
          create: Object.assign({}, state.playlist.create, action.payload)
        })
      }),
    [setDeletePlaylistDialog]: (state, action) => {
      return Object.assign({}, state, {
        playlist: Object.assign({}, state.playlist, {
          delete: Object.assign({}, state.playlist.delete, action.payload)
        })
      });
    },
    [setRemovePlaylistDialog]: (state, action) => {
      return Object.assign({}, state, {
        playlist: Object.assign({}, state.playlist, {
          remove: Object.assign({}, state.playlist.remove, action.payload)
        })
      });
    },
    [setSharePlaylistDialog]: (state, action) =>
      setState(state, {
        playlist: setState(state.playlist, {
          share: Object.assign({}, state.playlist.share, action.payload)
        })
      }),
    [setPlaylistDescription]: (state, action) =>
      setState(state, {
        playlist: setState(state.playlist, {
          description: Object.assign({}, state.playlist.description, action.payload)
        })
      }),
    [setFolderDescription]: (state, action) =>
      setState(state, {
        folder: setState(state.folder, {
          description: Object.assign({}, state.folder.description, action.payload)
        })
      }),
    [setDeleteFolderDialog]: (state, action) => {
      return Object.assign({}, state, {
        folder: Object.assign({}, state.folder, {
          delete: Object.assign({}, state.folder.delete, action.payload)
        })
      });
    },
    [setRemoveVideoDialog]: (state, action) =>
      setState(state, {
        video: setState(state.video, {
          remove: Object.assign({}, state.video.remove, action.payload)
        })
      }),
    [setDialogError]: (state, action) => Object.assign({}, state, { errorMessage: action.payload }),
    [resetDialog]: () => defaultState
  },
  defaultState
);

export {
  setFolderPlaylistCreate,
  setDeletePlaylistDialog,
  setRemovePlaylistDialog,
  setSharePlaylistDialog,
  setPlaylistDescription,
  setFolderDescription,
  setDeleteFolderDialog,
  // setFolderDialog,
  setRemoveVideoDialog,
  setDialogError,
  resetDialog,
  reducer
};
