import {
  RESULTS,
  TRENDING,
  CATEGORIES,
  PARAMS,
  LOADING,
  ERROR,
  CLEAR_RESULT
} from "../actions/search";
import { Mixpanel } from "../../components/shared/Mixpanel";

const initialState = {
  searchParams: {
    categories: [],
    clearanceStatus: [],
    display: "desc",
    footageType: [],
    openFilters: true,
    page: 1,
    perPage: 20,
    q: null,
    sort: "signedDate",
    tone: []
  }
};

export default function search(state = initialState, action) {
  const setState = (obj) => ({ ...state, ...obj }); //Object.assign({}, state, obj)

  switch (action.type) {
    case CLEAR_RESULT: {
      return setState({
        results: []
      });
    }
    case RESULTS: {
      if (!localStorage.getItem("showOnboarding")) {
        Mixpanel.track("View Search Page", {
          "Search Terms": action.data.params.q ? action.data.params.q : "",
          Category: action.data.params.categories ? action.data.params.categories.toString() : "",
          Tone: action.data.params.tone ? action.data.params.tone.toString() : "",
          "Footage Type": action.data.params.footageType
            ? action.data.params.footageType.toString()
            : "",
          "Clearance Status": action.data.params.clearanceStatus
            ? action.data.params.clearanceStatus.toString()
            : "",
          Trending: action.data.params.trending ? "Trending Only" : "All Videos",
          Orientation: action.data.params.isVertical ? action.data.params.isVertical : "All Videos",
          Resolution: action.data.params.resolution ? "HD" : "All Videos"
        });
      }

      return setState({
        results: action.data.hits,
        total: action.data.total,
        searchParams: action.data.params,
        trending: action.data.trending,
        loading: false
      });
    }
    case TRENDING:
      return setState({
        results: action.data,
        loading: false
      });
    case CATEGORIES:
      return setState({
        categoryList: action.data.categoryList
      });
    case PARAMS:
      return setState({
        searchParams: action.data.searchParams
      });
    case LOADING:
      return setState({
        loading: true
      });
    case ERROR:
      return setState({
        loading: false,
        error: true
      });
    default:
      return state;
  }
}
