import styled from "styled-components";

const CancelDownloadPopperContainer = styled.div`
  #cancel-download-popper-description {
    width: 100%;
    cursor: default;
    color: rgba(0, 0, 0, 0.87) !important;
  }
`;

export default CancelDownloadPopperContainer;
