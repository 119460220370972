import styled from "styled-components";
import overlayIcons from "../../../images/video-overlay-icons.png";
const VideoBoxContainer = styled.div`
  .hover-play iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
  }
  button.add-to-cart {
    span {
      background: url(${overlayIcons})-20px -20px no-repeat;
      width: 18px;
      height: 13px;
      color: transparent;
    }
  }
  button.remove-from-cart {
    position: relative;
    top: -1px;
    span {
      background: url(${overlayIcons})-20px -20px no-repeat;
      width: 18px;
      height: 13px;
      color: transparent;
    }
  }
  button.add-to-fav {
    span {
      background: url(${overlayIcons})-20px -74px no-repeat;
      width: 16px;
      height: 16px;
      color: transparent;
      margin-right: -5px;
    }
  }
  button.add-to-fav.unfav {
    span {
      background: url(${overlayIcons})-20px -74px no-repeat;
      width: 16px;
      height: 16px;
      color: transparent;
      margin-right: -5px;
    }
  }
  button.add-to-fav.fav {
    span {
      background: url(${overlayIcons})-78px -75px no-repeat;
      width: 16px;
      height: 16px;
      color: transparent;
      margin-right: -5px;
    }
  }
  button.remove-from-fav.fav {
    span {
      background: url(${overlayIcons})-78px -75px no-repeat;
      width: 16px;
      height: 16px;
      color: transparent;
    }
  }
  .itemBottom {
    background: rgba(0, 0, 0, 0.7);
    span {
      color: #fff;
      font-size: 13px;
      line-height: 26px;
      padding-left: 10px;
      &.jv {
        font-size: 12px;
      }
    }
    p {
      padding: 5px 5px 5px 10px;
      color: #373737 !important;
      font-size: 12px;
      span {
        color: #7e7e7e;
      }
    }
    button {
      float: right;
      width: 30px;
      border: none;
      color: #fff;
      margin: 0;
      font-size: 14px;
      background: rgba(0, 0, 0, 0.7);
      height: 26px;
      cursor: pointer;
      z-index: 50000;
      &:hover {
        background: ${(props) => props.theme.colors.primary.main}
      }
    }
  }
  .hovereffect {
    .itemoverlay {
      width: 260px;
      overflow: hidden;
      position: absolute;
      left: 0;
      bottom: 0;
      -webkit-transition: -webkit-transform 0.35s;
      transition: transform 0.35s;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
      z-index: 1001;
      p {
        color: #fff;
      }
    }
    .video-thumbnail-container {
      display: block;
      position: relative;
      -webkit-transition: -webkit-transform 0.35s;
      transition: transform 0.35s;
      background: #000;
    }
    &:hover {
      .video-thumbnail-container {
        background: #000;
      }
      .itemoverlay {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
      .overlay {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
      p {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-transition-delay: 0.3s;
        transition-delay: 0.3s;
      }
    }
    p {
      -webkit-transition: -webkit-transform 0.35s;
      transition: transform 0.35s;
      -webkit-transform: translate3d(0, 200%, 0);
      transform: translate3d(0, 200%, 0);
    }
  }
  .itemTitle {
    background: #fdfbfb;
    position: relative;
    z-index: 1001;
    overflow: hidden;
    padding: 0;
    width: 100%;
    &:hover {
      h3,
      .date__clearance {
        color: #008183 !important;
        transition: all 0.6s ease 0s;
        span {
          color: #008183 !important;
          transition: all 0.6s ease 0s;
        }
      }
    }
    h3 {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 16px;
      padding-top: 10px;
      line-height: 125%;
      color: #000;
      height: 47px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      word-break: keep-all;
      overflow: hidden;
      div {
      }
    }
    .date__clearance {
      color: #656565;
      font-size: 12px;
      line-height: 100%;
      // margin-bottom: 5px;
      height: 25px;
      span {
        color: #9d9d9d;
      }
    }
    i {
      position: relative;
      z-index: 10;
    }

    i.use-icon {
      margin: 0;
      color: #fff;
    }
    i.use-icon.etp {
      margin-right: 5px;
      // padding: 2px 6px;
    }
  }
  p.acquiredDate {
    color: #656565 !important;
    span {
      color: #9d9d9d !important;
    }
  }
  .image-container {
    width: 100%;
    max-width: 260px;
    background: #000;
    height: 148px;
    cursor: pointer;
    position: relative;
    img {
      height: 100%;
      margin: 0 auto;
      background: #000;
    }
    button.add-to-cart {
      border-right: 1px solid #747474;
    }
  }
  .use-icon {
    background: #8bc34a;
    color: #fff;
    border-radius: 50%;
    margin: 0 5px;
    font-size: 12px;
    padding: 2px 5px;
    font-weight: 700;
  }
  .use-icon.eIcon {
    padding: 2px 6px;
  }
  .use-icon.yellow {
    background: #ea8329;
    color: #000;
  }
  .use-icon.red {
    background: #e14336;
  }
  i {
    font-style: normal;
  }
  i.green {
    color: #038446;
  }
  i.yellow {
    color: #ea8329;
  }
  i.red {
    color: #ff726e;
  }
  a.click-intercept {
    position: absolute;
    z-index: 1000 !important;
    height: 100%;
    width: 100%;
    max-width: 260px;
    max-height: 148px;
    left: 0;
  }
  .imgthumb {
    position: relative;
    &:hover .title-jv {
      display: block;
    }
  }
  .title-jv {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.7);
    p {
      padding: 5px 5px 5px 10px;
      margin-bottom: 0;
      color: #fff;
      font-size: 12px;
    }
  }
  img.thumb-vid {
    position: relative;
    z-index: 9999;
    width: auto;
  }
  img.thumb-bg {
    width: 100%;
    position: absolute;
    z-index: 99;
    filter: blur(1px);
  }
  .thumb-overlay {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    position: relative;
    z-index: 998;
  }
  .hover-play {
    display: none;
  }
  .imgthumb {
    &:hover {
      .video-thumbnail-container {
        display: none;
      }
      .hover-play {
        display: block;
      }
    }
  }
`;

export default VideoBoxContainer;
