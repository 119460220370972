import { GET_HOMEPAGE, SET_FETCHING } from "../actions/publisher";
const isProd =
  process.env.REACT_APP_FUNK_URL === "https://funk-newqa.jukinmedia.com"
    ? false
    : true;
const QAVideos = [
  {
    aci: "YELLOW",
    bo: true,
    brightCoveVideoId: 6132060476001,
    iar: false,
    imgUrl: "https://thumbnails-dev.jukinmedia.com/thumbnail-1581562127933.jpg",
    jmId: "1084113",
    publishingTitle: "Testing 123",
    rsa: false,
    signedDate: "2020-02-13T03:51:26",
    title: "Testing 123",
  },
  {
    aci: "YELLOW",
    bo: false,
    brightCoveVideoId: 6158166637001,
    iar: false,
    imgUrl: "https://thumbnails-dev.jukinmedia.com/thumbnail-1590038911991.jpg",
    jmId: "1086309",
    publishingTitle: "Rotini Salad (Rotini Pasta Salad) Recipe",
    rsa: false,
    signedDate: "2020-05-21T05:15:24",
    title: "Rotini Salad (Rotini Pasta Salad) Recipe",
  },
  {
    aci: "YELLOW",
    bo: false,
    brightCoveVideoId: 6151998711001,
    iar: false,
    imgUrl: "https://thumbnails-dev.jukinmedia.com/thumbnail-1588907300998.jpg",
    jmId: "1084270",
    publishingTitle: "Acrylic painting - Colorful Girl (4k)",
    rsa: true,
    signedDate: "2020-04-25T03:08:02",
    title: "Acrylic painting - Colorful Girl (4k)",
  },
  {
    aci: "YELLOW",
    bo: false,
    brightCoveVideoId: 6146128091001,
    iar: false,
    imgUrl: "https://thumbnails-dev.jukinmedia.com/thumbnail-1585691379966.jpg",
    jmId: "1084177",
    publishingTitle: "https://www.facebook.com/10154916159250901",
    rsa: false,
    signedDate: "2020-03-31T21:48:39",
    title: "https://www.facebook.com/10154916159250901",
  },
];

const prodVideo = [
  {
    aci: "GREEN",
    bo: false,
    brightCoveVideoId: 6160053135001,
    iar: false,
    imgUrl: "https://thumbnails.jukinmedia.com/thumbnail-1590697328642.jpg",
    jmId: "1123375",
    publishingTitle:
      "Fitness Instructor Hosts Workout Training Class With People Via Video Call During Quarantine",
    rsa: false,
    signedDate: "2020-05-28T20:31:57",
    title: "working out via zoom online call",
  },
  {
    aci: "GREEN",
    bo: false,
    brightCoveVideoId: 6160107150001,
    iar: false,
    imgUrl: "https://thumbnails.jukinmedia.com/thumbnail-1590691538345.jpg",
    jmId: "1124052",
    publishingTitle: "Guy Does 360 Spin Flip While Slacklining",
    rsa: false,
    signedDate: "2020-05-28T19:31:37",
    title: "Guy does a dope 360 spin flip around a slackline",
  },
  {
    aci: "GREEN",
    bo: false,
    brightCoveVideoId: 6160029942001,
    iar: false,
    imgUrl: "https://thumbnails.jukinmedia.com/thumbnail-1590674881848.jpg",
    jmId: "1123892",
    publishingTitle: "Dutch Policemen Riding Horses Join Salsa Class in Park",
    rsa: false,
    signedDate: "2020-05-28T17:50:50",
    title:
      "Dutch police joining an outside Salsa lesson by horse in Zuiderpark",
  },
  {
    aci: "YELLOW",
    bo: false,
    brightCoveVideoId: 6159655180001,
    iar: false,
    imgUrl: "https://thumbnails.jukinmedia.com/thumbnail-1590580262083.jpg",
    jmId: "1118458",
    publishingTitle:
      "Father and Son Dressed as Clowns Entertain Passerby Amidst Coronavirus Pandemic",
    rsa: false,
    signedDate: "2020-05-27T19:06:29",
    title: "Father and son dressed as clown bring smiles",
  },
];

const initialState = {
  homepage: {
    heroTitle: "Authentic videos, extraordinary social performance",
    promoBlock: {
      content:
        "It's tough finding new content that's going to inspire engagement and get views. Our content team sources the newest viral videos on the web and gets them into the video library every day, fully ready for licensing and immediate download.\n\nWe make it easy to find the best videos for your next project. \nSign up for a free account and get access to exclusive features and tools.",
      cta: "Sign Up",
      link: "signUp",
      title: "Real Moments. Real Life.",
    },
    promoTicker: [
      {
        copy:
          "Dad jokes, Dad fails, and Dad humor. These are our best videos of fatherhood.",
        cta: "Explore Now",
        link: "https://bit.ly/35B7lxl",
      },
      {
        copy:
          "Browse our Quarantine Life collection to see how people are spending their time stuck at home.",
        cta: "Explore Now",
        link: "/licensing/collections/quarantine-life",
      },
      {
        copy: "Sign up today - Create your account and get exclusive features.",
        cta: "Sign Up",
        link: "signUp",
      },
      {
        copy:
          "Get $10 off! Sign up for email updates and trending content alerts.",
        cta: "Subscribe",
        link: "https://campaign.jukinmedia.com/newsletter",
      },
      {
        copy:
          "Special request? Ask about our custom discovery & clearance solutions.",
        cta: "Learn More",
        link: "/licensing/inquiry",
      },
      {
        copy:
          "Questions? Visit our FAQ or chat live with a licensing professional.",
        cta: "Learn More",
        link: "/licensing/faq",
      },
    ],
    contentBlock: [
      {
        left: {
          title: "Current Events",
          copy:
            "See how people around the world are spending their time during the global pandemic. From inside activities to social distancing, these unique times are no match for the human spirit.",
          link: "/licensing/search?q=coronavirus",
          cta: "Explore",
        },
        right: {
          title: "My Playlists",
          copy:
            "Keep track of all videos that inspire you. Use our entire library to create, organize and share your own curated playlists.",
          link: "/licensing/account/lists",
          cta: "Create",
        },
      },
    ],
    testimonials: [
      {
        company: "Jungle Creations",
        name: "Olly Underwood",
        testimonial:
          "We've been working with Jukin for a number of years and they have consistently provided us with strong content that is thoroughly enjoyed by our audience. They're a pleasure to work with and are open to new ideas and ways of working together which has seen fantastic results for both parties.",
        title: "Video Permissions and Partnerships Manager",
      },
      {
        company: "Associated Press",
        name: "Paul Shanley",
        testimonial:
          "Jukin Media has proven to be a great partner for AP. Our customers, some of the world’s largest news organizations, use Jukin’s content regularly and know that the Jukin brand stands for high quality content.",
        title: "Director of Development and Partnerships",
      },
      {
        company: "VMLY&R New Zealand",
        name: "Phoebe Joiner",
        testimonial:
          "Using Jukin to license social media creative for our clients has always been an easy, friendly and quick process.  The platform is easy to use, and if we’re ever having difficulty with a specific need the team are \talways on board with helping us find the right content that meets our creative brief.",
        title: "Social Media Specialist",
      },
    ],
    trendingTags: ["bike", "cat", "fail", "outdor", "technology", "home"],
    viralVideos: isProd ? prodVideo : QAVideos,
  },

  fetching: true,
};

export default function homepage(state = initialState, action) {
  const setState = (obj) => ({ ...state, ...obj });
  switch (action.type) {
    case GET_HOMEPAGE:
      return setState({ homepage: action.payload });
    case SET_FETCHING:
      return setState({ fetching: action.payload });
    default:
      return state;
  }
}
