import { createAction, handleActions } from "redux-actions";

const setGridOrder = createAction("PAGES/PLAYLIST/SET_GRID_ORDER");

const defaultState = {
  playlist: "desc",
  folder: "desc",
  playlistVideos: "desc"
};

const reducer = handleActions(
  {
    [setGridOrder]: (state, action) => Object.assign({}, state, action.payload)
  },
  defaultState
);

export { setGridOrder, reducer };
