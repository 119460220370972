import {
  GET_COLLECTION,
  SET_FETCHING,
  SET_SNACKBAR,
  SET_COPYING,
  SET_ALL_PUBLISH_TENTPOLES
} from "../actions/merchandising";

const initialState = {
  collections: [],
  fetching: true,
  snackbar: {
    open: false,
    message: "",
    severity: "success"
  },
  copying: false,
  publishedCollection: {
    tentpoles: [],
    lastEvaluatedKey: "",
    totalPage: 0,
  }
};

export default function merchandising(state = initialState, action) {
  const setState = obj => ({ ...state, ...obj }); //Object.assign({}, state, obj)
  switch (action.type) {
    case GET_COLLECTION:
      return setState({ collections: [action.data] });
    case SET_FETCHING:
      return setState({ fetching: action.payload });
    case SET_SNACKBAR:
      return setState({ snackbar: Object.assign({}, state.snackbar, action.payload) })
    case SET_COPYING:
      return setState({ copying: action.payload });
    case SET_ALL_PUBLISH_TENTPOLES:
      return setState({ publishedCollection: Object.assign({}, state.publishedCollection, action.payload) })
    default:
      return state;
  }
}
