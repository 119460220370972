import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import { Snackbar as MaterialSnackbar, SnackbarContent, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";

const Snackbar = (props) => {
  const dispatch = useDispatch();

  const { onClose, snackbar } = props;

  const onClickCloseSnackbar = (click) => {
    if (snackbar.reload) {
      window.location.reload();
    }
    if (snackbar?.showCartView && click) {
      dispatch(push("/licensing/checkout"));
    }
    onClose();
  };

  return (
    <MaterialSnackbar
      open={snackbar.open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={5000}
      onClose={() => onClickCloseSnackbar(false)}
      disableWindowBlurListener
    >
      {snackbar.severity === "default" ? (
        <SnackbarContent
          message={snackbar.message}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => onClickCloseSnackbar(true)}
            >
              {snackbar?.showCartView ? (
                <span className="snackbar--cart--view">View</span>
              ) : (
                <CloseIcon fontSize="small" />
              )}
            </IconButton>
          }
        />
      ) : (
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => onClickCloseSnackbar(false)}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </MuiAlert>
      )}
    </MaterialSnackbar>
  );
};

Snackbar.propTypes = {
  onClose: PropTypes.func.isRequired,
  snackbar: PropTypes.object.isRequired
};

export default Snackbar;
